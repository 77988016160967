<!--
 * @Description:客户全生命周期数字化管理
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-06 17:29:33
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-12 09:15:05
-->
<template>
  <div class="benefit container-fluid">
    <div class="container benefit-info">
      <div class="benefit-info-title hidden-xs-only">{{ info.title }}</div>
      <div class="benefit-info-box">
        <template v-for="(item, index) in info.dataList" :key="item.title">
          <div class="benefit-info-box-line hidden-xs-only" v-if="(index != 0)"></div>
          <div class="benefit-info-box-item">
            <JzLabel :itemSet="item" :index="index"></JzLabel>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import JzLabel from '@/components/JzLabel/JzLabel.vue'
export default {
  components: {
    JzLabel
  },
  name: 'benefit',
  props: {
    info: {
      type: [Array, Object]
    }
  },
  setup (props, context) {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.benefit {
  position: relative;
  margin-top: -120px;
  margin-bottom: 24px;
  z-index: 4px;

  &-info {
    background-color: #ffffff;
    box-shadow: 0px 8px 20px rgba(95, 123, 178, 0.1);
    padding: 24px 48px;
    border-radius: 4px;

    &-title {
      width: 100%;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
    }

    &-box {
      height: 159px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-line {
        width: 1px;
        height: 40px;
        background-color: #efefef;
      }

      &-item {
        width: 280px;
        height: 118px;
        padding: 20px;
      }
    }
  }
}

// 媒体查询
@media(max-width:767px) {
  .benefit {
    margin-top: 24px;

    &-info {
      padding: 0px;
      box-shadow: none;

      &-box {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        &-item {
          width: 48%;
          margin-bottom: 16px;
          box-shadow: 0px 8px 20px rgba(95, 123, 178, 0.1);
          border-radius: 8px;
          height: 100%;
        }
      }
    }
  }
}
</style>
