<!--
 * @Description:免费试用
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 17:42:37
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-08-23 21:01:58
-->
<template>
  <div class="trial container-fluid">
    <div class="trial-content container">
      <div class="trial-content-box">
        <div class="trial-content-box-left hidden-xs-only">
          <el-image :lazy="true" style="width:100%;height:100%" src="/image/trial/login.png"></el-image>
        </div>
        <div class="trial-content-box-right">
          <div class="trial-content-box-right-title">
            领取免费试用 <el-icon>
            </el-icon>
          </div>
          <!-- <div class="trial-content-box-right-subtitle">
            我们会尽快核实信息，为您开通免费账户
          </div> -->
          <div class="trial-content-box-right-input">
            <el-input :prefix-icon="OfficeBuilding" style="width:100%;height: 44px;" v-model="forms.formValue.enterprise" placeholder="请输入完整企业名称"></el-input>
          </div>
          <div class="trial-content-box-right-input">
            <el-input :prefix-icon="User" style="width:100%;height: 44px;" v-model="forms.formValue.name" placeholder="怎么称呼您" maxlength="11"></el-input>
          </div>
          <div class="trial-content-box-right-input">
            <el-input controls-position="right" :controls="false" :prefix-icon="Iphone" type="number" style="width:100%;height: 44px;" v-model="forms.formValue.number" placeholder="请输入手机号" maxlength="11"></el-input>
          </div>
          <div class="trial-content-box-right-input code">
            <el-input :prefix-icon="Key" style="width:100%;height: 44px;" v-model="forms.formValue.code" placeholder="请输入验证码"></el-input>
            <el-button class="code-button" type="primary" :disabled="showInfo.disabled" @click="getCode">{{
                showInfo.btnTitle
            }}</el-button>
          </div>
          <div class="trial-content-box-right-check">
            <el-checkbox v-model="forms.read" />
            <div class="trial-content-box-right-check-title">我已阅读并同意 <span class="jz-hv" style="color:var(--el-color-primary)" @click="goView('protocols')">《服务协议》</span>及<span class="jz-hv" style="color:var(--el-color-primary)" @click="goView('secure')">《隐私政策》</span></div>
          </div>
          <div class="trial-content-box-right-footer">
            <el-button style="width:100%;height:44px;" type="primary" :loading="loading" @click="submit">立即领取</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="showVisible" width="400px" :modal="true" :append-to-body="true" :close-on-click-modal="false" :before-close="handleClose">
      <template #header>
        <div style="font-weight:600">
          感谢使用金智CRM
        </div>
      </template>
      <div class="box">
        <div class="box-content">
          <div style="display:flex;margin-bottom:16px">
            <div style="font-size:14px;font-weight:600;width:50%;text-align:right;">登录名：</div>
            <div style="width:50%;text-align:left;">{{ showLoginInfo.entno }}</div>
          </div>
          <div style="display:flex;margin-bottom:16px">
            <div style="font-size:14px;font-weight:600;width:50%;text-align:right;">用户名：</div>
            <div style="width:50%;text-align:left;">{{ showLoginInfo.user }}</div>
          </div>
          <div style="display:flex;margin-bottom:16px">
            <div style="font-size:14px;font-weight:600;width:50%;text-align:right;display: flex;justify-content: flex-end;">密 <div style="width: 14px;"></div>码：</div>
            <div style="width:50%;text-align:left;">{{ showLoginInfo.password }}</div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="">
          <el-button @click="handleClose">关闭</el-button>
          <el-button type="primary" @click="goLogin">去登录</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { Iphone, OfficeBuilding, Key, User } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { Requests } from '@/api/index.js'
export default {
  name: 'trial',
  props: {},
  setup (props, context) {
    const $router = useRouter()
    const forms = reactive({
      formValue: {
        enterprise: '',
        number: null,
        code: '',
        name: ''
      },
      read: true
    })
    const showVisible = ref(false)
    const showLoginInfo = ref({
      entno: '*****',
      password: '*****',
      user: '*****'
    })
    // 定时器
    const Times = ref(-1)
    // 按钮加载中
    const loading = ref(false)
    const showInfo = computed(() => {
      return Times.value > 0 ? {
        btnTitle: `${Times.value}s`,
        disabled: true
      } : {
        btnTitle: '获取验证码',
        disabled: false
      }
    })
    const goView = (type) => {
      $router.push({
        path: '/view',
        query: {
          type: type
        }
      })
    }

    // 立即领取按钮
    const submit = async () => {
      if (forms.read) {
        if (forms.formValue.enterprise) {
          if (forms.formValue.name) {
            if (forms.formValue.number && /^1[3456789][0-9]\d{8}$/.test(forms.formValue.number)) {
              if (forms.formValue.code) {
                loading.value = true
                const params = {
                  url: 'https://crm.jzsoft.cn/pro/api/user/RegisterCompany',
                  method: 'GET',
                  data: {
                    company: forms.formValue.enterprise,
                    linkman: forms.formValue.name,
                    tel: forms.formValue.number,
                    code: forms.formValue.code,
                    codeType: 'register'
                  },
                  params: {}
                }
                // 来源于推广拉新注册
                if (window.location.href.indexOf('para') > -1) {
                  const tempUrl = window.location.href.substring(window.location.href.indexOf('?'))
                  const theRequest = {}
                  if (tempUrl.indexOf('?') != -1) {
                    const str = tempUrl.substring(tempUrl.indexOf('?') + 1)
                    const strs = str.split('&')
                    for (let i = 0; i < strs.length; i++) {
                      theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
                    }
                  }
                  params.data = { ...params.data, ...theRequest }
                }

                const res = await Requests(params)
                loading.value = false
                if (res.errcode === 0) {
                  ElMessage.success('感谢使用金智CRM，账户密码下发到您的手机，如果您有需要，请与我们联系')
                  if (typeof (res.result) === 'object') {
                    showLoginInfo.value = res.result
                    showVisible.value = true
                  } else {
                    setTimeout(() => {
                      window.open('https://crm.jzsoft.cn/pro')
                    }, 1000)
                  }
                }
              } else {
                ElMessage.error('请输入验证码')
              }
            } else {
              ElMessage.error('请输入正确手机号')
            }
          } else {
            ElMessage.error('请输入您的姓名')
          }
        } else {
          ElMessage.error('请输入完整企业名称')
        }
      } else {
        ElMessage.error('请勾选"我已阅读并同意 《服务协议》及《隐私政策》"')
      }
    }
    // 获取验证码
    const getCode = async () => {
      if (forms.formValue.number && forms.formValue.number.length == 11) {
        const params = {
          url: 'https://crm.jzsoft.cn/pro/api/MobileSms/GetCode',
          method: 'get',
          data: {
            phone: forms.formValue.number,
            codeType: 'register'
          }
        }
        const res = await Requests(params)
        if (res.errcode === 0) {
          ElMessage.success('发送成功')
          Times.value = 60
          const times = setInterval(() => {
            if (Times.value == 0) {
              clearInterval(times)
            }
            Times.value -= 1
          }, 1000)
        } else {
        }
      } else {
        ElMessage.error('请输入正确手机号')
      }
    }
    //
    const goLogin = () => {
      window.open('https://crm.jzsoft.cn/pro')
    }
    // 弹出框关闭之前
    const handleClose = () => {
      showVisible.value = false
    }
    return {
      forms,
      Iphone,
      OfficeBuilding,
      Key,
      User,
      goView,
      showInfo,
      loading,
      submit,
      getCode,
      showVisible,
      showLoginInfo,
      goLogin,
      handleClose
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.trial {
  min-height: calc(100vh - 446px);
  ::v-deep .el-input__inner {
    text-align: left;
  }
  &-content {
    padding: 120px 0px 20px 0px;
    display: flex;
    justify-content: center;

    &-box {
      width: 90%;
      max-width: 1000px;
      height: 508px;
      margin-left: 5%;
      display: flex;
      border-radius: 20px;
      overflow: hidden;
      background-color: rgba(247, 250, 255, 0.6);

      &-left {
        width: 370px;
        height: 100%;
        flex-shrink: 0;
      }

      &-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0px 30px 0px;

        &-title {
          font-size: 32px;
          font-weight: 400;
          width: 386px;
          margin: 0px 0px 6px 0px;
        }

        &-subtitle {
          width: 386px;
          font-size: 14px;
          color: #333333bf;
          margin: 0px 0px 16px 0px;
        }

        &-input {
          width: 386px;
          margin: 8px 0px;
          position: relative;
        }

        &-check {
          width: 386px;
          display: flex;
          align-items: center;

          &-title {
            margin-left: 8px;
            font-size: 12px;
            color: #333333bf;
          }
        }

        &-footer {
          width: 386px;
          margin-top: 16px;
        }

        .code {
          ::v-deep(.el-input__inner) {
            padding-right: 96px;
            overflow: hidden;
          }

          &-button {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 94px;
            height: 44px;
          }
        }
      }
    }
  }
}

//媒体查询
@media (max-width: 767px) {
  .trial {
    &-content {
      padding-top: 50px;

      &-box {
        width: 100%;
        margin: 0px;
        background-color: transparent;

        &-right {
          padding: 0px;

          &-check {
            width: fit-content;
          }

          &-title {
            width: 100%;
            text-align: center;
          }

          &-subtitle {
            width: 100%;
            text-align: center;
          }

          &-input {
            width: 100%;

            ::v-deep(.el-input__wrapper) {
              background-color: rgba(247, 250, 255, 0.6);
            }
          }
        }
      }
    }
  }
}

::v-deep(.footer) {
  display: none;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type="number"] {
  line-height: 1;
  -moz-appearance: textfield !important;
}
.box {
  &-content {
    width: 100%;
  }
}
</style>
