<!--
 * @Description:服务页面
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 20:32:21
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 16:24:36
-->
<template>
  <div class="service">
    <!-- 背景图 -->
    <div class="service-banner container-fluid">
      <div class="imageBack">
        <el-image :lazy="true" style="width:100%;height:100%" src="/image/service/serviceBack.png"></el-image>
      </div>
      <div class="service-banner-box container">
        <div class="service-banner-box-left hidden-xs-only">
          <el-image :lazy="true" fit="contain" class="image" src="/image/service/left.svg"></el-image>
        </div>
        <div class="service-banner-box-right">
          <el-image :lazy="true" fit="contain" class="image" src="/image/service/right.svg"></el-image>
        </div>
        <div class="service-banner-box-content">
          <div class="service-banner-box-content-title">{{ serviceInfo.title }}</div>
          <div class="service-banner-box-content-subtitle">
            <span class=" hidden-xs-only">客户尊享 </span>
            <span>金智CRM全心全意为您服务 值得信赖</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="service-video container-fluid">
      <div class="service-video-box container">
        <div class="service-video-box-title">全心为您 服务PLUS <span class=" hidden-xs-only">——</span><br
            class="hidden-sm-and-up"> 视频分享</div>
        <div class="service-video-box-tab hidden-sm-and-up">
          <div class="service-video-box-tab-item" @click="videoChange(index)"
            :class="index == videoActive ? 'tabActive' : ''" v-for="(item, index) in serviceInfo.video.dataList">
            {{ item.itemSet.label }}</div>
        </div>
        <div class="service-video-box-content">
          <div class="service-video-box-content-left">
            <video style="width:100%;height:100%;border-radius: 14px;"
              :src="serviceInfo.video.dataList[videoActive]['video']" controls :autoplay="true"></video>
          </div>
          <div class="service-video-box-content-right hidden-xs-only">
            <div class="service-video-box-content-right-item jz-hv" @click="videoChange(index)"
              :class="index == videoActive ? 'videoActive' : ''" v-for="(item, index) in serviceInfo.video.dataList">
              <el-image :lazy="true" :src="item.image" style="width:180px;height:104px;" />
              <div class="playIcon">
                <JzIcon icon="icon-play" :iStyle="{ 'font-size': '30px', 'color': '#cdcdcd' }">
                </JzIcon>
              </div>
              <!-- 先屏蔽左上角的文字 -->
              <div class="tag" v-if="false">
                <JzLabel :itemSet="item.itemSet"></JzLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 帮助解答 -->
    <div class="service-help container-fluid" v-if="serviceInfo.help">
      <div class="service-help-box container">
        <div class="service-help-box-title">全心为您 服务PLUS <span class=" hidden-xs-only">——</span><br
            class="hidden-sm-and-up"> 帮助解答</div>
        <div class="service-help-box-content">
          <div class="service-help-box-content-item" v-for="item in serviceInfo.help.dataList" :key="item.title">
            <div class="service-help-box-content-item-icon">
              <JzIcon :icon="item.icon" :iStyle="{}" style="width:20px;height:20px">
              </JzIcon>
            </div>
            <span class="titles">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 品质服务 -->
    <div class="service-pinzhi container-fluid" v-if="serviceInfo.pinzhi">
      <div class="service-pinzhi-box container">
        <div class="service-pinzhi-box-title">{{ serviceInfo.pinzhi.title }}</div>
        <div class="service-pinzhi-box-content">
          <div class="service-pinzhi-box-content-item" v-for="item in serviceInfo.pinzhi.dataList" :key="item.title">
            <div class="service-pinzhi-box-content-item-title">{{ item.title }}</div>
            <div class="service-pinzhi-box-content-item-content">
              <div class="service-pinzhi-box-content-item-content-item" v-for="it in item.dataList" :key="it.label">
                <JzIcon :icon="it.icon" :iStyle="{ color: '#13bc83' }" style="width:20px;height:20px">
                </JzIcon>
                <span style="margin-left:16px;font-size:16px;color:#1f2228d8;">{{ it.label }}</span>
              </div>
            </div>
            <div class="service-pinzhi-box-content-item-footer jz-hv" @click="freeTrial">
              立即体验
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 服务支持 -->
    <div class="service-suppoer container-fluid" v-if="serviceInfo.suppoer">
      <div class="service-suppoer-box container">
        <div class="service-suppoer-box-title">{{ serviceInfo.suppoer.title }}</div>
        <div class="service-suppoer-box-subtitle" v-html="serviceInfo.suppoer.subtitle"></div>
        <div class="service-suppoer-box-content">
          <div class="service-suppoer-box-content-item" v-for="item in serviceInfo.suppoer.dataList" :key="item.title">
            <div class="service-suppoer-box-content-item-image">
              <el-image :lazy="true" style="width:70px;height:70px" :src="item.image"></el-image>
            </div>
            <span class="service-suppoer-box-content-item-title">{{ item.title }}</span>
            <span class="service-suppoer-box-content-item-subtitle">{{ item.subtitle }}</span>
            <span class="service-suppoer-box-content-item-subtitle">{{ item.other }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JzLabel from '@/components/JzLabel/JzLabel.vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  components: { JzLabel, JzIcon },
  props: {},
  setup (props, context) {
    const $store = useStore()
    const serviceInfo = computed(() => {
      return $store.state.service
    })
    const videoActive = ref(0)
    // 视频的切换
    const videoChange = (keys) => {
      videoActive.value = keys
    }
    return {
      serviceInfo,
      videoActive,
      videoChange
    }
  },
  methods: {
    // 免费试用
    freeTrial () {
      this.$router.push({
        path: '/trial'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.service {
  &-banner {
    height: 470px;
    position: relative;

    .imageBack {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &-box {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;

      &-left {
        position: absolute;
        z-index: 5;
        width: 80px;
        opacity: 0.2;
        left: 10%;
        transform: rotate(-30deg);
        bottom: 30%;
      }

      &-right {
        position: absolute;
        z-index: 5;
        width: 80px;
        opacity: 0.2;
        right: 20%;
        top: 140px;
        transform: rotate(30deg);
      }

      &-content {
        text-align: center;

        &-title {
          font-size: 48px;
          color: #1f2228FE;
          font-weight: 700;
          padding: 8px 16px;
        }

        &-subtitle {
          margin-top: 18px;
          padding: 8px 16px;
          font-size: 18px;
          // color: #ffffff;
          // background-color: var(--el-color-primary);
          border-radius: 50px;
        }
      }
    }
  }

  &-video {
    background-color: rgba(247, 250, 255, 1);
    height: 670px;

    &-box {
      padding: 60px 0px 40px 0px;

      &-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #1f2228fe;
        font-weight: 560;
      }

      &-tab {
        width: 80%;
        margin-left: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &-item {
          position: relative;
        }

        .tabActive::after {
          content: "";
          position: absolute;
          left: 0px;
          bottom: -20px;
          width: 100%;
          height: 4px;
          background-color: var(--el-color-primary);
        }
      }

      &-content {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        &-left {
          height: 448px;
          width: 800px;
        }

        &-right {
          width: 180px;
          flex-shrink: 0;
          margin-left: 8px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .playIcon {
            position: absolute;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
          }

          &-item {
            width: 180px;
            height: 104px;
            border-radius: 8px;
            position: relative;
            // padding: 1px;
            overflow:hidden;
            ::v-deep(.el-image > img){
              width: 102%;
              margin-left: -0.5%;
              height: 102%;
              margin-top: -0.5%;
            }
            .tag {
              position: absolute;
              z-index: 2;
              top: 4px;
              left: 8px;
            }
          }

          .videoActive {
            // border: 1px solid var(--el-color-primary);
            box-shadow: 0px 0px 6px 0px #035dff;
          }
        }
      }
    }
  }

  &-help {
    height: 400px;
    background-color: rgba(249, 255, 253, 1);

    &-box {
      padding: 60px 0px 40px 0px;

      &-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #1f2228fe;
        font-weight: 560;
      }

      &-content {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding: 0px 10%;

        &-item {
          width: 124px;
          height: 37px;
          margin: 24px 50px;
          display: flex;
          align-items: center;
          justify-content: left;
          padding: 16px 8px;
          border-radius: 8px;

          &-icon {
            display: flex;
            align-items: center;
          }

          .titles {
            font-size: 16px;
            // color: #1f2228d8;
            margin-left: 8px;
          }
        }

        &-item:hover {
          background-color: var(--el-color-primary);
          color: #ffffff !important;
        }
      }
    }
  }

  &-pinzhi {

    &-box {
      padding: 60px 0px 0px 0px;

      &-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #1f2228fe;
        font-weight: 560;
      }

      &-content {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        margin-left: 5%;

        &-item {
          // width: 30%;
          width: 308px;
          box-shadow: 0px 8px 20px rgba(95, 123, 178, 0.1);
          padding: 40px 0px 40px 40px;
          margin-bottom: 40px;

          &-title {
            font-size: 24px;
            color: #1f2228d8;
            font-weight: 700;
            text-align: center;
          }

          &-content {
            margin-top: 20px;

            &-item {
              margin-bottom: 20px;
            }
          }

          &-footer {
            width: 80%;
            text-align: center;
            background-color: #ffffff;
            color: var(--el-color-primary);
            border: 1px solid var(--el-color-primary);
            line-height: 48px;
            border-radius: 4px;
          }

          &-footer:hover {
            background-color: var(--el-color-primary);
            color: #ffffff;
          }
        }

      }
    }
  }

  &-suppoer {

    &-box {
      padding: 100px 0px 40px 0px;

      &-title {
        width: 100%;
        text-align: center;
        font-size: 32px;
        color: #1f2228fe;
        font-weight: 560;
      }

      &-subtitle {
        padding: 8px 16px;
        font-size: 18px;
        color: #1f2228d8;
        text-align: center;
      }

      &-content {
        padding: 40px 0px;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-left: 5%;

        &-item {
          display: flex;
          flex-direction: column;
          padding-left: 40px;

          &-image {
            margin-left: 16px;
          }

          &-title {
            padding: 8px 16px;
            font-size: 24px;
            color: #1f2228FE;
            font-weight: 700;
          }

          &-subtitle {
            padding: 2px 16px;
            font-size: 18px;
            color: #1f2228D8;
          }
        }
      }
    }
  }
}

//媒体查询
@media(max-width:767px) {
  .service {
    &-banner {
      margin-top: 60px;
      height: 200px;

      .imageBack {
        width: 95%;
        margin-left: 2.5%;
        border-radius: 16px;
        overflow: hidden;
      }

      &-box {
        &-right {
          width: 50px;
          right: 10%;
          top: 30px;
        }

        &-content {
          &-title {
            font-size: 24px;
          }

          &-subtitle {
            font-size: 14px;
          }
        }
      }
    }

    &-video {
      height: 100%;
      background-color: transparent;

      &-box {
        padding: 40px 0px 0px 0px;

        &-title {
          width: 80%;
          margin-left: 10%;
          font-size: 24px;
        }

        &-content {
          &-left {
            height: 192px;
          }
        }
      }
    }

    &-help {
      height: 100%;
      background-color: transparent;

      &-box {
        padding: 40px 0px 0px 0px;

        &-title {
          width: 80%;
          margin-left: 10%;
          font-size: 24px;
        }

        &-content {
          width: 100%;
          margin-top: 0px;
          padding: 0px;

          &-item {
            width: 21%;
            padding: 0px;
            margin: 24px 2px;
            flex-direction: column;

            &-icon {
              width: 56px;
              height: 56px;
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              box-shadow: 0px 8px 20px rgba(95, 123, 178, 0.1);
              border-radius: 16px;
            }

            .titles {
              font-size: 14px;
              margin-top: 8px;
            }
          }

          &-item:hover {
            background-color: transparent;
            color: var(--bs-body-color) !important;
          }
        }
      }
    }

    &-pinzhi {
      &-box {
        padding: 48px 0px 24px 0px;

        &-title {
          font-size: 24px;
          padding: 8px 16px;
        }

        &-content {
          width: 100%;
          margin: 0px;

          &-item {
            padding: 24px;
            width: 100%;

            &-title {
              font-size: 20px;
              text-align: left;
            }

            &-footer {
              width: 100%;
              background-color: var(--el-color-primary);
              color: #ffffff;
            }
          }
        }
      }
    }

    &-suppoer {
      &-box {
        padding: 0px;

        &-content {
          padding: 0px;
          flex-direction: column;

          &-item {
            padding: 0px;
            margin-bottom: 24px;

            &-image,
            &-title,
            &-subtitle {
              margin-left: 0px;
              text-align: center;
              padding-left: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
