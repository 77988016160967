<template>
  <div class="index">
    <div class="hidden-sm-and-up" style="height:43px;width:100%"></div>
    <!-- 顶部 轮播图 -->
    <Banner v-if="indexInfo.banner" :info="indexInfo.banner"></Banner>
    <!-- 客户全生命周期数字化管理 -->
    <Benefit v-if="indexInfo.benefit" :info="indexInfo.benefit"></Benefit>
    <!-- 快速定制个性化专属应用 -->
    <Info v-if="indexInfo.info" :info="indexInfo.info" :type="'info'"></Info>
    <!-- 助力 -->
    <Info v-if="indexInfo.assist" :info="indexInfo.assist" :type="'assist'"></Info>
    <!-- 好评 -->
    <Info v-if="indexInfo.good" :info="indexInfo.good" :type="'good'"></Info>
    <!-- 感谢信 -->
    <Info v-if="indexInfo.thanks" :info="indexInfo.thanks" :type="'thanks'"></Info>
    <!-- 数百家 -->
    <Info v-if="indexInfo.soft" :info="indexInfo.soft" :type="'soft'"></Info>
    <!-- 伙伴 -->
    <Info v-if="indexInfo.partner" :info="indexInfo.partner" :type="'partner'"></Info>
    <div class="footerBox">
      <div class="jiejue">
        <div class="container-fluid jiejue-box">
          <div class="container  jiejue-box-head">
            <div class="jiejue-box-head-title hidden-xs-only" style="">立即扫码咨询，领取您的专属解决方案</div>
            <div class="jiejue-box-head-title hidden-sm-and-up" style="text-align: center;">
              <div>立即扫码咨询</div>
              <div>领取您的专属解决方案</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid usBox">
        <div class="container usBox-content">
          <el-image :lazy="true" src="/image/index/us.svg"></el-image>
        </div>
        <div class="usBox-title">
          钉钉生态合作伙伴 <span class="hidden-xs-only">，助力企业提升业绩</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from './components/banner.vue'
import Benefit from './components/benefit.vue'
import Info from './components/info.vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  components: {
    Banner,
    Benefit,
    Info,
    JzIcon
  },
  setup (props, context) {
    const $store = useStore()
    const indexInfo = computed(() => {
      return $store.state.index
    })
    return {
      indexInfo
    }
  }
}
</script>

<style scoped lang="scss">
.index {
  .box {
    padding-top: 60px;

    .titles {
      font-size: 32px;
      color: #ffffff;
      padding: 8px 16px;
      font-weight: 500;
      text-align: center;
    }
  }

}

.footerBox {
  position: relative;
  padding-bottom: 260px;
  width: 100%;
  overflow: hidden;

  .jiejue {
    position: relative;
    background-color: var(--el-color-primary);
    height: 367px;

    &-box {
      position: relative;
      z-index: 4;
      top: 100px;
      text-align: center;
      width: 80%;
      margin-left: 10%;

      &-head {
        &-title {
          color: #ffffff;
          font-size: 32px;
          font-weight: 500;
        }
      }
    }
  }

  .usBox {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 170px;

    &-title {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 66%;
      font-size: 16px;
    }
  }

}

// 媒体查询
@media(max-width:767px) {
  .footerBox {
    padding-bottom: 130px;
    max-width: 100vw;
    overflow: hidden;

    .jiejue {
      height: 235px !important;

      &-box {
        top: 44px;
        width: 80%;

        &-head {
          &-title {
            font-size: 24px;
          }
        }
      }
    }

    .usBox {
      top: 140px;
      &-title{
        width: 97%;
        font-size: 12px;
      }
    }
  }
}
</style>
