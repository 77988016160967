/*
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 08:44:40
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-07-25 09:30:15
 */
import { createStore } from 'vuex'

export default createStore({
  state: {
    soft: {
      // 公司名称
      name: '金智CRM',
      // 服务热线
      hotline: '0532-85971028'
    },
    // 登录跳转链接
    loginUrl: 'https://crm.jzsoft.cn/pro',
    // 导航栏左侧logo
    logoIcon: {
      // 去色
      icon: 'icon-logo',
      // 有颜色
      colorIcon: 'icon-logo-color'
    },
    // 导航数据
    nav: [
      {
        title: '首页',
        icon: 'icon-shouye',
        path: '/'
      },
      {
        title: '产品',
        icon: 'icon-chanpin',
        path: '/info',
        children: [{
          icon: 'icon-crm',
          title: '智能CRM',
          subtitle: '业务周期全面赋能，提升企业盈利能力',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 111
        }, {
          icon: 'icon-wfh',
          title: '进销存',
          subtitle: '智能推荐采购与生产计划，精准库存预测，提高交付效率',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 112
        }, {
          icon: 'icon-caiwuguanli',
          title: '财务管理',
          subtitle: '统一资金账户管理，预收预付，应收应付',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 113
        }, {
          icon: 'icon-gongdan',
          title: '工单管理',
          subtitle: '智能化自动化工单流转，提高服务效率与客户满意度',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 114
        }, {
          icon: 'icon-pingtai',
          title: 'PaaS平台',
          subtitle: '无需代码开发，灵活设计在线表单与智能工作流',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 115
        }, {
          icon: 'icon-api',
          title: 'API开放平台',
          subtitle: '连接企业内外部IT系统，让业务和数据畅通无阻',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 116
        }, {
          icon: 'icon-huobi',
          title: '多币种',
          subtitle: '出口外销与进口采购，跨币种结算管理。',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 117
        }, {
          icon: 'icon-qiye',
          title: '生产制造',
          subtitle: '物料清单、生产申请、生产单、生产用料',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 118
        }, {
          icon: 'icon-hujiao',
          title: '呼叫中心',
          subtitle: '来去电弹屏、一键拨号、通话录音、通话统计',
          viewType: 'icon',
          iconStyle: {
            'font-size': '20px'
          },
          position: 'both',
          viewClass: 'navShow',
          linkType: 'replace',
          path: '/info',
          key: 119
        }]
      },
      {
        title: '方案',
        icon: 'icon-fang',
        path: '/programme'
      },
      {
        title: '服务',
        icon: 'icon-zhichi',
        path: '/service'
      },
      {
        title: '关于',
        icon: 'icon-guanyu',
        path: '/about'
      }
    ],
    // 首页弹出框的次数
    alertShowNum: 1,
    // 首页
    index: {
      // 首页 banner 图
      banner: [
        // {
        //   title: '618数字普惠节企业年中福利',
        //   subtitle: '新用户满减优惠金额  满2000元减800元</br>新老用户满赠产品时间  满1年送3个月 满2年送10个月 ',
        //   backimg: '/image/banner/banner0.png',
        //   // path: '/info',
        //   // img: '/image/index/b1.png'
        //   // 整合客户
        // },
        {
          title: '钉钉生态销售全过程管理CRM',
          subtitle: '金智CRM以数据驱动助企业提升盈利能力，无缝支撑销售全过程，著企业</br>完成移动化、数据化、智能化管理。',
          backimg: '/image/banner/banner1.png',
          path: '/info',
          img: '/image/index/b1.png'
          // 整合客户
        },
        {
          title: '金智CRM·智能版',
          subtitle: '适用于中大型企业，多业生态场景，表单Paas搭建丰富应用规则与</br>智能统计报表。',
          backimg: '/image/banner/banner2.png',
          path: '/info',
          img: '/image/index/b2.png'
          //
        }
      ],
      // 客户全生命周期数字化管理一一提升企业效益
      benefit: {
        title: '客户全生命周期数字化管理一一提升企业效益',
        dataList: [{
          viewClass: 'benefitClsaa',
          viewType: 'icon',
          icon: 'icon-dianhua',
          iconStyle: {
            fontSize: '40px'
          },
          title: '移动CRM',
          subtitle: '精细化客户管理，提升客户转化率与复购率'
        }, {
          viewClass: 'benefitClsaa',
          viewType: 'icon',
          icon: 'icon-a-01',
          iconStyle: {
            fontSize: '40px'
          },
          title: '进销存',
          subtitle: '智能预测缺货与采购，精准库存管理'
        }, {
          viewClass: 'benefitClsaa',
          viewType: 'icon',
          icon: 'icon-caiwuguanli',
          iconStyle: {
            fontSize: '40px'
          },
          title: '财务管理',
          subtitle: '统一自建账户管理，资金管控更轻松'
        }, {
          viewClass: 'benefitClsaa',
          viewType: 'icon',
          icon: 'icon-renwu',
          iconStyle: {
            fontSize: '40px'
          },
          title: '工单管理',
          subtitle: '智能化自动化工单，提高客户满意度'
        }]
      },
      info: {
        title: '快速灵活定制个性化专属应用——让业务随需而动',
        titleStyle: {
          'font-weight': 500
        },
        itemSet: {
          viewType: 'Level',
          image: '/image/ziyuan/index1.png',
          dataList: [
            {
              title: 'PaaS零代码开发平台',
              subtitle: '无需代码开发，灵活设计在线表单与智能工作流，快速定制应用，满足个性化需求'
            },
            {
              title: 'BI智能数据分析平台',
              subtitle: '多维度交叉计算、分析，灵活查询，高效洞察业务数据，助企业快速决策'
            },
            {
              title: 'API开放平台',
              subtitle: '连接企业内外部系统，让业务和数据畅通无阻'
            }
          ]
        }
      },
      assist: {
        title: '<p style="font-weight:700">助力企业实现数智化未来</p>',
        subtitle: '金智CRM10年技术沉淀，助力钉钉700万家企业用户，实现数智化未来！',
        style: {
          'background-color': 'var(--el-color-primary)',
          color: '#ffffff'
        },
        dataList: [{
          viewType: 'vertical',
          label: '+72%',
          value: '拜访量',
          style: {
            color: '#ffffff'
          }
        }, {
          viewType: 'vertical',
          label: '+32%',
          value: '转换率',
          style: {
            color: '#ffffff'
          }
        }, {
          viewType: 'vertical',
          label: '+10%',
          value: '毛利率',
          style: {
            color: '#ffffff'
          }
        }, {
          viewType: 'vertical',
          label: '+50%',
          value: '销售额',
          style: {
            color: '#ffffff'
          }
        }]
      },
      good: {
        title: '<p style="font-weight:700">真实企业数千家温情好评</p>',
        subtitle: '软件付费用户+团队管理员+用户自评',
        itemSet: {
          viewType: 'banner',
          left: [{
            title: '目前正在使用该系统实现数据化管理，刘芬团队售后服务很好，希望后续不断升级优化应用，提供更好的服务',
            start: 5,
            name: '广州青鹿教育科技有限公司'
            // time: '2022年4月11日'
          }, {
            title: '挺好的，数据方面同级很细致，不懂的地方客服都在第一时间帮忙解决，很不错',
            start: 5,
            name: '温岭市田建商贸有限公司'
            // time: '2022年4月11日'
          }, {
            title: '系统后台稳定，客户服务态度好',
            start: 5,
            name: '浙江金铄建筑材料有限公司'
            // time: '2022年4月11日'
          }, {
            title: '撞单这种东西，谁都不想，但哪个公司没撞过单？怎么避免撞单是一个永恒的话题。这个软件可以很好的规避这个问题，从客户名字、公司关键词、联系电话，三方面去防止这种问题的产生。非常棒',
            start: 5,
            name: '巴南区时光婚礼服务部'
            // time: '2022年4月11日'
          }],
          right: [{
            title: '我们行业里对于客户资料是有特殊要求的，一个已经签约的客户还可以做多次深挖，对于普通的CRM软件根本美白办法满足我们的需求，只能在备注里。而金智是可以设置新的个性化字段的，这样对于我们来说，就方便了很多，而且可以根据个性化字段进行搜索，想找哪类客户，直接找字段就行，SO EASY',
            start: 5,
            name: '深圳市禾美教育科技有限公司'
            // time: '2022年4月11日'
          }, {
            title: '做销售最怕的是说好了联系客户的情况，记录下来，贴在重要的地方，然后重要的地方贴太多，就完全没有发现。而金智CRM的提醒功能太好了，在录入和跟单的时候吧重要的事件和时间录入好，到时自动推送到手机里和CRM里，俨然是智能小秘书的状态，GOOD',
            start: 5,
            name: '成都华纳丽尊酒店有限公司'
            // time: '2022年4月11日'
          }, {
            title: '我司是商贸公司，有了金智帮我们解决了很多事情，虽然也有些小问题，最终解决了。希望以后的合作更加顺畅。',
            start: 5,
            name: '北京盛龙达商贸有限公司'
            // time: '2022年4月11日'
          }, {
            title: '感谢售后覃钰婕，刘建平，每次有问题总能即使为我解决！给个大大的赞！！',
            start: 5,
            name: '驻马店恒兴钢结构有限公司'
            // time: '2022年4月11日'
          }]
        }
      },
      thanks: {
        title: '<p style="font-weight:700">超<span style="color:var(--el-color-primary)">500家企业</span>发来感谢信分享使用感受</p>',
        subtitle: '软件付费用户+企业盖章认可+同意公示',
        itemSetStyle: {
          'box-shadow': '0px 8px 20px rgba(95,123,178,0.35)',
          'margin-top': '24px',
          'border-radius': '16px'
        },
        itemSet: [{
          viewType: 'image',
          dataList: [
            '/image/ziyuan/thank1.png',
            '/image/ziyuan/thank2.png',
            '/image/ziyuan/thank3.png',
            '/image/ziyuan/thank4.png',
            '/image/ziyuan/thank5.png',
            '/image/ziyuan/thank6.png',
            '/image/ziyuan/thank7.png',
            '/image/ziyuan/thank8.png',
            '/image/ziyuan/thank9.png',
            '/image/ziyuan/thank10.png'
          ],
          style: {
            width: '200px',
            height: '286px'
          }
        }, {
          viewType: 'image',
          dataList: [
            '/image/ziyuan/thank11.png',
            '/image/ziyuan/thank12.png',
            '/image/ziyuan/thank13.png',
            '/image/ziyuan/thank14.png',
            '/image/ziyuan/thank15.png',
            '/image/ziyuan/thank16.png',
            '/image/ziyuan/thank17.png',
            '/image/ziyuan/thank18.png',
            '/image/ziyuan/thank19.png',
            '/image/ziyuan/thank20.png'
          ],
          style: {
            width: '200px',
            height: '286px'
          }
        }]
      },
      soft: {
        title: '<p style="font-weight:700"><span style="color:var(--el-color-primary)">数百家上市公司</span>&中大型企业使用</p>',
        subtitle: '软件付费用户',
        style: {
          'background-color': 'rgba(247,250,255,1)',
          padding: '80px 0px 40px 0px'
        },
        itemSet: {
          viewType: 'image',
          value: '/image/ziyuan/soft.png'
        }
      },
      partner: {
        title: '多平台战略生态合作伙伴，便捷高效服务',
        itemSetStyle: {
          margin: '40px 0px 0px 0px'
        },
        dataList: [{
          viewType: 'iconHtml',
          image: '/image/index/dd.svg',
          title: '钉钉',
          subtitle: '<p style="line-height:29px;text-align:center"">阿里钉钉业财联盟首批成员，PC/移动</br>高效协同业务流程透明化</p>'
          // type: 'dingding',
        }, {
          viewType: 'iconHtml',
          image: '/image/index/wx.svg',
          title: '企业微信',
          subtitle: '<p style="line-height:29px;text-align:center"">多维度引流获客，帮助企业更好地运营</br>客户搭建私域流量池</p>',
          type: 'wechat'
        }, {
          viewType: 'iconHtml',
          image: '/image/index/fs.png',
          title: '飞书',
          subtitle: '<p style="line-height:29px;text-align:center">先进的团队协同平台理念</br>帮助构建健康高效的运营环境</p>'
          // type: 'feishu',
        }]
      }
    },
    // 产品
    info: [
      {
        key: 111,
        header: {
          title: '从获客、跟单到合约执行……业务周期全面赋能，提升企业盈利能力！',
          dataList: [{
            image: '/image/info/多渠道获客集成.png',
            title: '多渠道获客集成',
            subtitle: '工商获客、抖音获客、百度获客、微信公众号获客，系统对接，数据同步。'
          }, {
            image: '/image/info/全方位跟单管理.png',
            title: '全方位跟单管理',
            subtitle: '跟单记录、拜访签到、呼叫中心、集成短信、集成邮件、SFA自动营销……科学跟单，遗忘提醒！'
          }, {
            image: '/image/info/钱货票精准管理.png',
            title: '钱货票精准管理',
            subtitle: '合同、应收款、回款、退款、充值、坏账、开票、提成、毛利全面管理。'
          }, {
            image: '/image/info/可视化分析报表.png',
            title: '可视化分析报表',
            subtitle: '数据简报推送、同环比分析、数据排名、数据构成报表、数据导出。'
          }]
        },

        dataList: [{
          viewType: 'info',
          title: '整合客户资源，优化客户分配',
          image: '/image/info/智能版-1.png',
          dataList: [{
            title: '多渠道获客集成',
            subtitle: '工商获客对接、抖音获客对接、百度获客对接、微信公众号获客对接，结合客户新建，批量导入客户等，多渠道集成获客，丰富企业客户资料库。'
          }, {
            title: '客户分配、保有量、回收',
            subtitle: '客户共享、转移、放入客户池，自定义客户回收方案，客户保有量方案，客户撞单管理，客户唯一性规则设置等，优化企业客户资源配置。'
          }, {
            title: '360°客户视图',
            subtitle: '客户详情、数据摘要、跟单动态、销售机会、合同回款等所有客户相关数据一个页面集成汇总。'
          }]
        }, {
          viewType: 'info',
          title: '规范跟单行为，提升销售业绩',
          image: '/image/info/智能版-2.png',
          dataList: [{
            title: '多维度跟单管理',
            subtitle: '跟单记录与待办提醒，实现跟单组合拳。集成邮件、短信、通话，满足跟单痕迹自动获取到系统中。定位签到拜访，员工轨迹查询，实现精准跟单。'
          }, {
            title: '商机推进与过程管理',
            subtitle: '销售机会与销售阶段管理，销售阶段推进，满足按商机质量与预期签单时间，合理安排业务跟单。'
          }, {
            title: '商机预测管理',
            subtitle: '销售漏斗自动汇总不同类型的销售机会，实现商机预测管理，方便参考制定销售目标。'
          }]
        }, {
          viewType: 'info',
          title: '合约精细执行，提高履约效率',
          image: '/image/info/智能版-3.png',
          dataList: [{
            title: '钱：精打细算不出错',
            subtitle: '应收款自动计算，回款计划到期自动提醒。单笔回款与集中回款，回款金额与冲销金额。预估毛利与实际毛利，成本利润微调管理。订单回款提成管理等。自动生成对账单，支持多规则对账。定期结算对账更轻松！'
          }, {
            title: '货：按期交付不逾期',
            subtitle: '可设置最晚发货时间，到期未发货自动提醒。发货支持物流查询，物流签收自动更新发货单为已签收。支持批量打印发货单。'
          }, {
            title: '票：税款分离更智能',
            subtitle: '包含单笔开票与集中开票，支持先款后票或先票后款。开票记录支持税价分离，进销项税金抵扣成本更清晰。'
          }]
        }, {
          viewType: 'info',
          title: '多维度数据分析报表',
          image: '/image/info/智能版-4.png',
          dataList: [{
            title: '销售简报',
            subtitle: '销售日简报、周简报、月简报，可设置推送简报对象与推送内容，系统自动推送销售简报。'
          }, {
            title: '统计报表',
            subtitle: '包含摘要数据、同比数据分析，数据构成图形图表，员工数据排名等多维度数据。支持多条件组合查询统计数据，数据支持导出到excel。'
          }, {
            title: '绩效考核',
            subtitle: '可设置销售目标，实际完成数据，目标完成比例，销售数据排行榜等，均可自动生成。'
          }]
        }]
      },
      {
        key: 112,
        header: {
          title: '围绕产品数据联动流转、助力企业轻松管好进销存',
          dataList: [{
            image: '/image/info/产品信息管理.png',
            title: '产品信息管理',
            subtitle: '产品分类、产品信息管理，产品视图数据集成。'
          }, {
            image: '/image/info/产品采购管理.png',
            title: '产品采购管理',
            subtitle: '供应商、采购申请、采购单、付款、收票、入库等全流程管理。'
          }, {
            image: '/image/info/产品库存管理.png',
            title: '产品库存管理',
            subtitle: '分仓管理，出入库、借还货、调拨、盘点、台账、交付对比分析。'
          }, {
            image: '/image/info/码管理.png',
            title: 'SN / BN码管理',
            subtitle: '贵重物品唯一性编码溯源追踪；批次产品有效期、批次成本管理。'
          }]
        },

        dataList: [{
          viewType: 'info',
          title: '产品信息管理',
          image: '/image/info/进销存-1.png',
          dataList: [{
            title: '产品信息增加',
            subtitle: '包含产品分类，产品信息添加。支持产品信息批量导入。支持产品缩略图查看大图。支持添加主规格产品与子规格产品，支持单位换算。'
          }, {
            title: '产品信息视图',
            subtitle: '包含产品基本信息、产品库存信息、产品采购与销售信息、产品汇总数据等信息。可查看具体产品的所有出入库历史单据。'
          }, {
            title: '产品信息维护',
            subtitle: '包含编辑、删除、打印，支持批量编辑。支持按多条件查询。'
          }]
        }, {
          viewType: 'info',
          title: '产品采购管理',
          image: '/image/info/进销存-2.png',
          dataList: [{
            title: '供应商信息管理',
            subtitle: '供应商整理管理、供应商撞单提醒、供应商兼做客户、供应商联系人，批量导入供应商，供应商转为客户，供应商视图等。'
          }, {
            title: '采购过程管理',
            subtitle: '合同订单转采购申请单或采购单，采购申请单转采购单，采购申请单转供应商报价。采购单形成后，自动形成应付款，支持付款计划，付款记录，付款预充值消费。可设置到货提醒，采购在途物资自动汇总。'
          }, {
            title: '采购退货管理',
            subtitle: '采购退货退款管理，未付款前退货自动按照剩余货值计算未付款金额。'
          }]
        }, {
          viewType: 'info',
          title: '产品库存管理',
          image: '/image/info/进销存-3.png',
          dataList: [{
            title: '出入库管理',
            subtitle: '入库包含：采购入库、常规入库、调拨入库、生产入库、销售退货入库等。出库包含：销售出库、常规出库、调拨出库、领料出库、采购退货出库等。单据独立成本提取规则设计，数据联动流转判定。库存不足自动预警推送消息。'
          }, {
            title: '分仓库与库位管理',
            subtitle: '支持设置多个仓库，不同仓库可设置不同的库管与查看人员。仓库可选不计算总库存的瑕疵品库与普通计算总库存的仓库。产品信息可选仓库库位功能，也可支持条码枪、PDA设备查询产品。'
          }, {
            title: '库存台账与数据对比',
            subtitle: '包含库存台账，可按仓库或产品，汇总查询产品出入库数量、剩余数量，货值金额；设计库存交付对比表，合同、采购、库存以及在途产品数据联动判定，自动计算缺口库存。'
          }]
        }, {
          viewType: 'info',
          title: 'SN / BN码管理',
          image: '/image/info/进销存-4.png',
          dataList: [{
            title: 'SN / BN码入库增加',
            subtitle: '序列号（即SN码）和批次号（即BN码）通过入库性质的单据可以增加，包括单条新增，批量生成，批量复制粘贴，扫描录入等多种方式。'
          }, {
            title: 'SN / BN码出库使用',
            subtitle: '序列号（即SN码）和批次号（即BN码）通过出库性质的单据可以调用，满足出货产品SN码或BN码信息登记。'
          }, {
            title: 'SN / BN码信息查询',
            subtitle: '通过序列号列表或批次号列表，可查询SN码信息与BN码信息，进行编号修改替换等操作。批次号有效期到期时系统将自动推送批次号有效期到期提醒。'
          }]
        }]
      },
      {
        key: 113,
        header: {
          title: '企业精细化内账管理，收支流水账财务系统！',
          dataList: [{
            image: '/image/info/财务首页管理.png',
            title: '财务首页管理',
            subtitle: '财务摘要数据、收支余额、账户余额、待处理记账、发票汇总等。'
          }, {
            image: '/image/info/财务记账管理.png',
            title: '财务记账管理',
            subtitle: '销售回款、采购付款、费用报销、账户间转账、常规收支、应收应付、工资管理等。'
          }, {
            image: '/image/info/财务看账管理.png',
            title: '财务看账管理',
            subtitle: '流水账审账封账、周期数据同环比、分账户查看收支盈亏等。'
          }, {
            image: '/image/info/财务分析账管理.png',
            title: '财务分析账管理',
            subtitle: '企业经营报表、收支分类统计、客户价值表、供应商价值表等。'
          }]
        },

        dataList: [{
          viewType: 'info',
          title: '一个页面“装满”企业核心财务数据',
          image: '/image/info/财务-1.png',
          dataList: [{
            title: '财务简报',
            subtitle: '总收入、合同收入、非合同收入、销售毛利；总支出、采购支出、费用支出、工资支出等。'
          }, {
            title: '待确认记账',
            subtitle: '待确认收入、待处理支出、待支付报销、待支付预支款等。处理记账完毕取消工作台显示，未审核通过前不可记账。'
          }, {
            title: '其他数据看板',
            subtitle: '回款计划、账户资金余额、应收应付、收支盈亏对比图、发票汇总数据等。支持更多维度快速了解企业核心财务数据。'
          }]
        }, {
          viewType: 'info',
          title: '无需专业财会知识，轻松处理收支流水',
          image: '/image/info/财务-2.png',
          dataList: [{
            title: '关联CRM记账',
            subtitle: '合同回款记录、充值记录，财务确认记账形成收入。财务直接记账收入，自动生成合同回款记录，并自动通知合同所属员工。采购付款同理。'
          }, {
            title: '应收应付',
            subtitle: '合同未回款金额自动形成为应收款金额，可设置回款计划，到计划时间自动提醒催收回款，回款记账为收入后，应收款金额减少。采购应付款同理。'
          }, {
            title: '工资管理',
            subtitle: '工资管理联动绩效工资、销售提成，工资发放前支持审批。可复制上月工资基数作为本月工资基础数据后快速调整形成本月工资。'
          }]
        }, {
          viewType: 'info',
          title: '多维度财务分析数据，支撑企业科学经营决策',
          image: '/image/info/财务-3.png',
          dataList: [{
            title: '看账本审核账',
            subtitle: '可分财务账户查看具体账户收支流水、账户余额。可以切换审核状态查看收支记录。可以对已产生的收支记录，选中进行财务封账锁账。'
          }, {
            title: '财务同环比数据分析',
            subtitle: '可以按年度、季度、月份对比，包含同比与环比数据走势。按照表格+走势构成图结构提取数据。'
          }, {
            title: '企业经营报表',
            subtitle: '按自然会计年度汇总企业收支盈亏各类数据，按月汇总数据，提取数据增减幅度与增减百分比。'
          }]
        }]
      },
      {
        key: 114,
        header: {
          title: '售后派单、任务协同，打通CRM的工单系统！',
          dataList: [{
            image: '/image/info/工单工作台.png',
            title: '工单工作台',
            subtitle: '待处理工单、快捷接单与转派、工单简报汇总、工单分阶段统计等。'
          }, {
            image: '/image/info/工单视图.png',
            title: '工单视图',
            subtitle: '工单执行进展、工单接单操作、工单详情等。'
          }, {
            image: '/image/info/工单地图.png',
            title: '工单地图',
            subtitle: '按地图显示工单分布、附近服务人员，就近派单等。'
          }, {
            image: '/image/info/工单模板.png',
            title: '工单模板',
            subtitle: '不同类型工单类型设置不同的工单模板，工单可自定义阶段流程。'
          }]
        },

        dataList: [{
          viewType: 'info',
          title: '工单工作台',
          image: '/image/info/工单-1.png',
          dataList: [{
            title: '待接单工单',
            subtitle: '显示未开始接单的工单，可切换工单模板查看，可快速接单与转派工单。'
          }, {
            title: '进行中工单',
            subtitle: '可快速了解工单下一阶段的执行人，快速完成工单或转派工单。'
          }, {
            title: '工单简报与分阶段统计',
            subtitle: '不同状态工单数量、不同阶段工单执行交付数量等数据汇总。'
          }]
        }, {
          viewType: 'info',
          title: '工单视图',
          image: '/image/info/工单-2.png',
          dataList: [{
            title: '工单进展详情',
            subtitle: '按照时间线结构显示工单执行动态，包含执行阶段与执行结果。'
          }, {
            title: '工单执行操作',
            subtitle: '工单接单、开始工作、完成工作、转派、关闭、重启等操作。可跳阶段执行或重启工单。'
          }, {
            title: '工单协同管理',
            subtitle: '没有参与工单执行或接单的人，可通过设置协同人实现信息共享，参与工单过程管理与执行。'
          }]
        }, {
          viewType: 'info',
          title: '工单地图',
          image: '/image/info/工单-3.png',
          dataList: [{
            title: '工单地址地图分布',
            subtitle: '可按照工单地址在地图上的分布，形成工单分布地图，可筛选查看工单。'
          }, {
            title: '工单附近服务人员分布',
            subtitle: '可根据工单外勤人员最近位置了解工单人员定位分布。'
          }, {
            title: '就近筛选工单与地图派单',
            subtitle: '可在移动端查询不同范围内待执行工单，进行按工单地址接单。'
          }]
        }]
      },
      {
        key: 115,
        header: {
          title: '自建表单与DIY规则，快速搭建专属系统！',
          dataList: [{
            image: '/image/info/自建新表单.png',
            title: '自建新表单',
            subtitle: 'DIY新单据，满足信息增删改查打印等诉求。'
          }, {
            image: '/image/info/单据多模板.png',
            title: '单据多模板',
            subtitle: '一个单据多个模板，不同模板不同字段与权限人员。'
          }, {
            image: '/image/info/自定义单据规则.png',
            title: '自定义单据规则',
            subtitle: '数据联动、公式编辑、自定义产品明细、单据关联、选项联动等规则配置。'
          }, {
            image: '/image/info/自定义审批流程.png',
            title: '自定义审批流程',
            subtitle: '自定义单据审批流程与审核后可修改的字段人员等规则。'
          }]
        },

        dataList: [{
          viewType: 'info',
          title: '自建新表单',
          image: '/image/info/PaaS平台-1.png',
          dataList: [{
            title: 'PC与手机同步',
            subtitle: '自建表单搭建完毕后，PC端与手机端同步规则与数据。'
          }, {
            title: '可关联内置表单',
            subtitle: '可关联内置表单，关联上级单据后，上级单据视图内也可新建本单据。'
          }, {
            title: '自定义菜单与图标',
            subtitle: '可自定义单据归属菜单、图标、权限、字段等规则。'
          }]
        }, {
          viewType: 'info',
          title: '单据多模板',
          image: '/image/info/PaaS平台-2.png',
          dataList: [{
            title: '内置多模板',
            subtitle: '内置销售机会、客户、合同等单据多模板，可修改规则或停用多模板。'
          }, {
            title: '自建多模板',
            subtitle: '自建单据可新建多模板，不限模板表单数量。'
          }, {
            title: '统一列表与视图结构',
            subtitle: '多模板表单列表结构、视图结构一致，共享高级查询、统计分析等功能。'
          }]
        }, {
          viewType: 'info',
          title: '自定义单据规则',
          image: '/image/info/PaaS平台-3.png',
          dataList: [{
            title: '数据联动、选项联动',
            subtitle: '可跨表单联动数据，选项级联字段等，满足数据夸表单数据联动。'
          }, {
            title: '公式编辑、字段属性控制',
            subtitle: '数据公式计算、字段默认属性设置等，满足快速获取数据结果。'
          }, {
            title: '自定义产品明细、子表单明细',
            subtitle: '自定义明细规则与流转规则，满足分行业特性拓展管理各类信息。'
          }]
        }]
      },
      {
        key: 116,
        dataList: [{
          viewType: 'api',
          type: 'api1',
          title: '软件<span style="">集成</span>已如此简单',
          subtitle: '无需代码开发，自动化连接企业内部与外部数据，实现数据互通。',
          bacImage: '/image/info/api/集成.svg',
          bothImage: '/image/info/api/both.png'
        }, {
          viewType: 'api',
          type: 'api2',
          title: '<span style="color:#1e266d">连接创造价值</span>',
          subtitle: '连接企业内外应用，构建数据驱动业务价值链，提升业务流程效率，带给企业新增长',
          images: [],
          dataList: [{
            title: '支持非合同币种回款',
            subtitle: '默认外销合同选择外币种为回款、开票币种，支持跨币种回款，非外销合同币种回款的，按汇率转换为对应外销合同外币相应金额。'
          }, {
            title: '外币合同转本币采购单',
            subtitle: '外销合同订单支持流转采购单，采购单币种默认为人民币，可选其他外币采购。'
          }, {
            title: '金额类单据关联可独立币种',
            subtitle: '外币合同单、外币采购单等，下属子单据，如费用报销，支持其他币种，如人民币金额类型报销。'
          }]
        }, {
          viewType: 'api',
          type: 'api3',
          title: '<span style="color:rgb(17, 22, 64)">云智连如何帮助您实现自动化</span>',
          subtitle: '例如：A客户在抖音有企业账号引流数据要自动转化为金智CRM系统中的客户数据，并且自动分配业务人员等'
        }]
      },
      {
        key: 117,
        header: {
          title: '外贸出口、进口采购，整合CRM系统的多币种',
          dataList: [{
            image: '/image/info/外销订单管理.png',
            title: '外销订单管理',
            subtitle: '出口外销订单、外销订单国内采购订单利润核算、产品外币售价管理'
          }, {
            image: '/image/info/支持跨币种.png',
            title: '支持跨币种',
            subtitle: '默认一单一币一汇率，支持跨币种结算，单笔单据修改结算汇率。'
          }, {
            image: '/image/info/分币种统计.png',
            title: '分币种统计',
            subtitle: '金额类统计支持外币与本币计算、多币种金额构成等悬浮窗显示。'
          }, {
            image: '/image/info/汇率管理.png',
            title: '汇率管理',
            subtitle: '支持自动汇率与手工汇率，汇率更新1小时更新一次。'
          }]
        },

        dataList: [{
          viewType: 'info',
          type: 'img',
          title: '外销订单',
          image: '/image/info/外销订单.png',
          dataList: [{
            title: '按币种提取产品售价',
            subtitle: '若合同订单选择外币，产品明细默认提取对应外币的售价信息，若切换其他币种的，下属产品售价信息自动切换相应币种。'
          }, {
            title: '订单币种定位下属单据币种',
            subtitle: '订单币种确定后，下属回款、开票、发货等单据币种默认保持与订单币种一致，同时，支持与主单不同币种回款、开票、发货等。'
          }, {
            title: '订单利润核算支持跨币种结算',
            subtitle: '出口外销合同，国内人民币采购的，或有下属非合同币种的支付费用等。统一转化为合同币种金额模式，计算合同外币金额利润。'
          }]
        }, {
          viewType: 'info',
          type: 'img',
          title: '跨币种',
          image: '/image/info/跨币种.png',
          dataList: [{
            title: '支持非合同币种回款',
            subtitle: '默认外销合同选择外币种为回款、开票币种，支持跨币种回款，非外销合同币种回款的，按汇率转换为对应外销合同外币相应金额。'
          }, {
            title: '外币合同转本币采购单',
            subtitle: '外销合同订单支持流转采购单，采购单币种默认为人民币，可选其他外币采购。'
          }, {
            title: '金额类单据关联可独立币种',
            subtitle: '外币合同单、外币采购单等，下属子单据，如费用报销，支持其他币种，如人民币金额类型报销。'
          }]
        }]
      },
      {
        key: 118,
        header: {
          title: 'CRM升级为ERP，制造业轻MES系统！',
          dataList: [{
            image: '/image/info/物料清单.png',
            title: '物料清单',
            subtitle: '支持多级物料，支持批量导入，按产品或产品分类查询物料清单。'
          }, {
            image: '/image/info/生产计划.png',
            title: '生产计划',
            subtitle: '合同订单转生产申请或直接新建生产申请，按产品汇总申请量流转生产数量对比。'
          }, {
            image: '/image/info/生产单.png',
            title: '生产单',
            subtitle: '生产产品信息管理，包含领料/退料/入库/质检/派工/完工/加工费/成本核算。'
          }, {
            image: '/image/info/生产统计.png',
            title: '生产统计',
            subtitle: '生产相关统计包括生产产品统计、生产用料统计、生产成本统计等。'
          }]
        },

        dataList: [{
          viewType: 'info',
          type: 'img',
          title: '物料清单',
          image: '/image/info/物料清单.svg',
          dataList: [{
            title: '支持多层级物料',
            subtitle: '不限物料层级，体现成品、半成品、零部件、物料明细构成关系。'
          }, {
            title: '支持批量导入物料清单',
            subtitle: '支持excel导入形成物料清单。'
          }, {
            title: '物料清单列表',
            subtitle: '采用左侧产品分类与右侧物料清单结构布局，支持快捷筛选查询。'
          }]
        }, {
          viewType: 'info',
          type: 'img',
          title: '生产计划',
          image: '/image/info/生产计划.svg',
          dataList: [{
            title: '合同转生产申请',
            subtitle: '合同订单流转形成生产申请，支持一个合同流转为多个生产申请。'
          }, {
            title: '直接新建生产申请',
            subtitle: '可直接新建生产申请，与合同流转形成的生产申请汇总在一起。'
          }, {
            title: '按产品汇总生产申请',
            subtitle: '按产品明细汇总申请申请数量、已生成数量、未生产数量等信息。'
          }]
        }, {
          viewType: 'info',
          type: 'img',
          title: '生产单',
          image: '/image/info/生产单.svg',
          dataList: [{
            title: '生产单视图',
            subtitle: '包含物料信息、用料明细、生产成本等相关数据。'
          }, {
            title: '生产派工、完工',
            subtitle: '对生产加工进行派工、完工等过程管理。'
          }, {
            title: '生产领料、退料',
            subtitle: '生产领料、退料管理，支持超额领料，按实际用料成本计算物料成本。'
          }]
        }, {
          viewType: 'info',
          type: 'img',
          title: '生产统计',
          image: '/image/info/生产统计.svg',
          dataList: [{
            title: '生产用料统计',
            subtitle: '按产品汇总生产用料明细和物料成本，支持设置物料明细成本的提取顺序。'
          }, {
            title: '生产产品统计',
            subtitle: '按实际用料+加工费计算产品生产成本，按生产成品统计生产数据。'
          }, {
            title: '质检产品统计',
            subtitle: '包含产品质检合格、不合格信息统计等。'
          }]
        }]
      },
      {
        key: 119,
        header: {
          title: '来去电话管理，通话集成CRM的呼叫中心！',
          dataList: [{
            image: '/image/info/固话座机呼叫.png',
            title: '固话座机呼叫',
            subtitle: '固定座机外呼与接听，借助固话语音盒+客户端打通CRM系统。'
          }, {
            image: '/image/info/手机卡呼叫.png',
            title: '手机卡呼叫',
            subtitle: '手机卡语音盒（非手机呼叫）接入后，在CRM系统中外呼与接听。'
          }, {
            image: '/image/info/网络电话阿里云呼.png',
            title: '网络电话阿里云呼',
            subtitle: '绑定阿里云呼参数，缴纳云呼资费后，在CRM系统中外呼与接听。'
          }, {
            image: '/image/info/号码隐藏控制.png',
            title: '号码隐藏控制',
            subtitle: '可控制号码分岗位人员隐藏，查看不到完整号码依然可以对外呼叫与接听。'
          }]
        },

        dataList: [{
          viewType: 'info',
          title: '来去电管理',
          image: '/image/info/呼叫中心-1.png',
          dataList: [{
            title: '来去电弹屏',
            subtitle: '来去电话自动弹屏，包含客户名称、来去电号码、归属地、客户所有者，客户核心信息、最后跟单记录等'
          }, {
            title: '自动识别客户与提取核心信息',
            subtitle: '根据客户号码归属客户情况，自动识别客户信息，提取最近数据供参考。'
          }, {
            title: '快速跟单与查看待办任务',
            subtitle: '接听电话同时可快速新建跟进记录，查看未完成待办任务。'
          }]
        }, {
          viewType: 'info',
          title: '通话记录管理',
          image: '/image/info/呼叫中心-2.png',
          dataList: [{
            title: '通话记录列表',
            subtitle: '来去电话通话自动形成通话记录，显示是否接通，通话时长，对应客户等。'
          }, {
            title: '通话自动录音',
            subtitle: '通话自动录音，录音云端存储，手机端与PC端均可收听通话录音。'
          }, {
            title: '通话记录备注',
            subtitle: '通话记录可选填写备注，方便通过备注快速了解通话内容概况。'
          }]
        }, {
          viewType: 'info',
          title: '通话统计管理',
          image: '/image/info/呼叫中心-3.png',
          dataList: [{
            title: '呼出时长统计',
            subtitle: '可统计不同人员外呼电话数量、通话合计时长等信息。'
          }, {
            title: '不同月份通话量统计',
            subtitle: '按月份统计不同月份通话量与数据同比上年同期数据。'
          }, {
            title: '具体客户通话次数统计',
            subtitle: '具体客户视图中，合计当前客户往来通话次数。'
          }]
        }]
      }
    ],
    // 公司简介
    about: {
      banner: {
        title: '数据驱动助企业提升盈利能力',
        subtitle: '助企业完成移动化、数据化、智能化管理',
        image: '/image/about/back.svg'
      },
      synopsis: {
        title: '公司简介',
        itemSet: {
          viewType: 'html',
          value: `<div style="line-height:38px;text-indent:32px;font-size:16px">
          金智软件（www.jzsoft.cn）正式创建于2004年8月，总部设于美丽的滨海城市青岛，是一家专注于CRM平台开发和运营的创新型互联网科技企业。<br>“数据驱动助企业提升盈利能力”是金智的企业使命，金智用全新的互联网软件架构和友好的用户体验设计打造金智CRM平台，至2018年，金智CRM已服务56个行业，企业客户4万+，软件使用人数60万+，安全运行数据70亿+，15年磨一剑，基于数万企业管理经验，专注打造金智CRM系统，给企业带来一体化销售管理解决方案。无缝支撑销售全过程，助企业完成移动化、数据化、智能化管理。金智自创办以来，保持着快速稳步的增长，目前与阿里钉钉、飞书、企业微信深度融合，提供多行业解决方案。
        </div>`
        }

      },
      course: {
        title: '发展历程',
        itemSet: {
          viewType: 'timeLine',
          value: [{
            time: '2019',
            value: '入驻钉空间深度共创，联合钉钉共同打造开工节计划'
          }, {
            time: '2018',
            value: '加入阿里云天池计划，深圳卫视阿里钉钉2018春夏新品发布会明星产品'
          }, {
            time: '2017',
            value: '签约阿里钉钉永续合作伙伴'
          }, {
            time: '2016',
            value: '正式成为阿里钉钉战略生态合作伙伴'
          }, {
            time: '2012',
            value: '受邀入驻阿里云应用市场'
          }, {
            time: '2010',
            value: '金智CRM云计算SaaS版正式推出'
          }, {
            time: '2006',
            value: '金智CRM历时三年与企业共创研发'
          }, {
            time: '2003',
            value: '金智CRM获国家版权局认证并开始销售'
          }]
        }
      },
      story: {
        title: '品牌故事',
        itemSet: {
          viewType: 'html',
          value: `<div style="line-height:38px;text-indent:32px;font-size:16px">
          金牌服务智者之选衍生金智软件服务理念：客户第一，服务至上。从早期为客户提供客户关系系统，形成了以CRM软件为核心，综合电子账本、来电CTI和销售自动化为辅助的企业管理软件群，2010年金智CRM云计算SAAS版正式推出，形成“应用+云服务”的整体CRM解决方案。同时，我们向中小企业发布“以数据驱动提升企业盈利能力”理念，并通过金智CRM平台，让企业能够真正感受到科学管理思想带来的销售提升。帮助更多企业从根本上解决管理难题，提升企业运作效率。金智CRM坚信科技的进步会颠覆传统的管理模式。因此，在匠心打磨产品的同时，竭力地去探索互联网时代下的最佳管理实践。通过不断试错、总结与共创，金智CRM团队内部成功培育了扁平开放、透明沟通、目标导向和自我驱动的企业文化，使团队的创新和凝聚力不断提升。同时我们以开放拥抱变化，从2012年受邀入驻阿里云应用市场到2018年正式加入阿里云天池计划，同年成为深圳卫视阿里钉钉2018春夏新品发布会明星产品，金智CRM不停的在协作及管理领域持续探索。为用户提供更好的产品与服务是我们不断的追求，2019年入驻钉空间与钉钉深度共创，并联合钉钉共同打造开工节计划，开启了以业务在线管理为主题的新篇章。道同为谋，合作共赢，与金智一路相伴，感谢有你。
        </div>`
        }
      },
      card: [{
        itemSet: {
          title: '战略定位',
          subtitle: '以数据驱动赋能企业智能化管理',
          content: '金智CRM以搭建数字化架构平台，数据驱动企业管理，让数据真正成为生产力',
          viewType: 'cardInfo',
          istyle: {
            'background-color': 'aliceblue'
          }
        }
      }, {
        itemSet: {
          title: '使命和愿景',
          subtitle: '以数据驱动助企业提升盈利能力',
          content: '金智CRM提供以数据为驱动的平台，助力企业节本增效',
          viewType: 'cardInfo',
          istyle: {
            'background-color': 'rgba(3,288,255,0.06)'
          }
        }
      }, {
        itemSet: {
          title: '价值观',
          subtitle: '客户第一  服务至上',
          content: '金智CRM以客户利益为初心，服务创造价值、专业赢得信任',
          viewType: 'cardInfo',
          istyle: {
            'background-color': 'rgba(255,87,3,0.06)'
          }
        }
      }]
    },
    footer: [{
      title: '产品',
      children: [{
        title: '智能CRM',
        path: '/info',
        query: {
          msgid: 111
        }
      }, {
        title: '进销存',
        path: '/info',
        query: {
          msgid: 112
        }
      }, {
        title: '财务管理',
        path: '/info',
        query: {
          msgid: 113
        }
      }, {
        title: '工单管理',
        path: '/info',
        query: {
          msgid: 114
        }
      }, {
        title: 'PaaS平台',
        path: '/info',
        query: {
          msgid: 115
        }
      }, {
        title: 'API开放平台',
        path: '/info',
        query: {
          msgid: 116
        }
      }, {
        title: '多币种',
        path: '/info',
        query: {
          msgid: 117
        }
      }, {
        title: '生产制造',
        path: '/info',
        query: {
          msgid: 118
        }
      }, {
        title: '呼叫中心',
        path: '/info',
        query: {
          msgid: 119
        }
      }]
    }, {
      title: '方案',
      children: [{
        title: '互联网',
        path: '/programme',
        query: {
          tabid: 1
        }
      }, {
        title: '机械设备',
        path: '/programme',
        query: {
          tabid: 2
        }
      }, {
        title: '制造业',
        path: '/programme',
        query: {
          tabid: 3
        }
      }, {
        title: '医疗行业',
        path: '/programme',
        query: {
          tabid: 4
        }
      }, {
        title: '企业服务',
        path: '/programme',
        query: {
          tabid: 5
        }
      }, {
        title: '批发零售',
        path: '/programme',
        query: {
          tabid: 6
        }
      }, {
        title: '建材行业',
        path: '/programme',
        query: {
          tabid: 7
        }
      }, {
        title: '教育培训',
        path: '/programme',
        query: {
          tabid: 8
        }
      }]
    }, {
      title: '支持',
      children: [{
        title: '服务支持',
        path: '/service'
      }
      // 先注掉线上再放开
      // {
      //   title: '推广大使',
      //   path: '/promotionLogin'
      // }
      ]
    }, {
      title: '关于',
      children: [{
        title: '关于我们',
        path: '/about',
        extra: true,
        query: {
          info: '关于我们'
        }
      }, {
        title: '公司简介',
        path: '/about',
        extra: true,
        query: {
          info: '公司简介'
        }
      }, {
        title: '发展历程',
        path: '/about',
        extra: true,
        query: {
          info: '发展历程'
        }
      }, {
        title: '品牌故事',
        path: '/about',
        extra: true,
        query: {
          info: '品牌故事'
        }
      }]
    }],
    // 方案
    programme: {
      tabList: [{
        label: '全部',
        value: 0
      }, {
        label: '互联网',
        value: 1
      }, {
        label: '机械设备',
        value: 2
      }, {
        label: '制造业',
        value: 3
      }, {
        label: '医疗行业',
        value: 4
      }, {
        label: '企业服务',
        value: 5
      }, {
        label: '批发零售',
        value: 6
      }, {
        label: '建材行业',
        value: 7
      }, {
        label: '教育培训',
        value: 8
      }],
      list: [
        {
          key: 1,
          tag: {
            viewType: 'tag',
            value: 1,
            label: '互联网',
            effect: 'dark',
            color: '#8ca7fe'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/hlw-1.svg'
          },
          title: '互联网/IT行业解决方案',
          sub: {
            ind: '互联网',
            softName: '北京易华录信息技术有限公司'
          },
          soft: {
            name: '行业简介',
            content: '互联网/IT行业：属信息产业。选购金智CRM软件的计算机行业用户多集中在To B行业。常见的有软件行业、网站建设、IDC业务、网络营销服务等基于互联网为平台开展业务或提供产品的行业。'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户销售',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '项目协同',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '订单执行',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: '售后服务',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/jfjh.svg',
              label: '交付计划',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/tscl.svg',
              label: '投诉处理',
              type: 'info'
            }
          ],
          other: {
            title: '行业特性',
            data: [{
              title: '产品特性',
              content: '产品借助互联网为媒介，多数为在线交付；不同于实物产品，属于智力成果交付，产品交付通常需要伴随服务支持。'
            }, {
              title: '销售特性',
              content: '客户挖掘成本高，跟单特性明显；销售方式多元化，信息扩散与客户长线跟单特征明显。'
            }, {
              title: '客户特性',
              content: '企、事业单位客户多，协同服务频率高；从意向客户开始，到产品推荐，交付实施，售后服务维护等，客户服务沟通周期较长。'
            }, {
              title: '合同特性',
              content: '产品交付后，服务维护工作量大；一般不牵扯硬件交付，退换货少，回款特性相对简单，产品交付后的服务实施工作量多。'
            }]
          }
        },
        {
          key: 2,
          tag: {
            viewType: 'tag',
            value: 2,
            label: '机械设备',
            effect: 'dark',
            color: '#2db8c5'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/jx-1.svg'
          },
          title: '机械设备行业解决方案',
          sub: {
            ind: '机械设备',
            softName: '天津市瑞静智能控制系统集成有限公司'
          },
          soft: {
            name: '行业简介',
            content: '机械设备主要是针对业务共性而言，产品表现存在较大差异，例如系统集成、安防监控、弱电工程、人防设备、中央空调、电梯设备等，业务共性特点主要集中在：产品多数为机械设备模式，需要多人配合，协同跟进，存在设备交付安装调试时，后续维保等特点，产品销售多数为经销商销售模式。'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户管理',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '销售跟单',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '采购库存',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: '售后服务',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/cgkc.svg',
              label: '采购库存',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/tscl.svg',
              label: '投诉处理',
              type: 'info'
            }
          ],
          other: {
            title: '行业特性',
            data: [{
              title: '产品特性',
              content: '产品多数为耐用品，产品专业性强；产品参数多，专业性强，需要专业技术人员提供产品安装调试，交付使用，售后维保等。'
            }, {
              title: '销售特性',
              content: '销售人员技能要求高；销售周期长，细节多，外勤频率高，销售人员经常需要与工程、财务、采购、库存等人员进行协同配合等。'
            }, {
              title: '客户特性',
              content: '大客户多，客情关系尤为重要；产品附加值高，客户不同项目重复合作的几率大，客户维护至关重要。'
            }, {
              title: '合同特性',
              content: '合同分期回款特性强、业务费用多；合同标的额大，产品交付周期长，通常合同是分期回款，回款周期长，各项业务开支费用较多。'
            }]
          }
        },
        {
          key: 3,
          tag: {
            viewType: 'tag',
            value: 3,
            label: '制造业',
            effect: 'dark',
            color: '#28c2b0'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/zzy-1.svg'
          },
          title: '制造业解决方案',
          sub: {
            ind: '制造业',
            softName: '重庆大唐科技股份有限公司'
          },
          soft: {
            name: '行业简介',
            content: '金智CRM制造业客户主要集中在标准件可规模化生产销售，且客户多数面向企业、工厂等方向。常见的制造业细分行业：机械设备行业、仪器仪表、工业材料、金属制品、通讯产品等。'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户销售',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '采购库存',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '项目协同',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: 'SFA营销',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/cgkc.svg',
              label: '采购库存',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/tscl.svg',
              label: '投诉处理',
              type: 'info'
            }
          ],
          other: {
            title: '',
            data: [{
              title: '产品特性',
              content: '多数为标准件产品，长期批量重复生产居多，区别于完全定制化产品；产品工业化程度高，多数为耐用品，后续维保需求信息量大。'
            }, {
              title: '销售特性',
              content: '销售人员多数为外勤销售，出差频率高，需要移动办公；销售周期长，细节杂，牵扯各部门配合多，处理信息量大。'
            }, {
              title: '客户特性',
              content: '大型设备多数为项目招投标，厂家直接面向终端客户（企业用户），材料制品多数为渠道分销商分销。'
            }, {
              title: '合同特性',
              content: '合同标的额大，产品交付周期长，通常合同是分期回款，回款周期长，各项业务开支费用较多。'
            }]
          }
        },
        {
          key: 4,
          tag: {
            viewType: 'tag',
            value: 4,
            label: '医疗行业',
            effect: 'dark',
            color: '#31c4f6'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/yl-1.svg'
          },
          title: '医疗行业解决方案',
          sub: {
            ind: '医疗',
            softName: '深圳市道恩医疗科技发展有限公司'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          soft: {
            name: '行业简介',
            content: '金智CRM医疗行业客户主要集中在医疗仪器、保健用品、医药分销、连锁药店等医疗产品经销贸易型企业。'
          },
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户管理',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '销售报价',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '订单执行',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: '售后服务',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/cgkc.svg',
              label: '采购库存',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/sfayx.svg',
              label: 'SFA营销',
              type: 'info'
            }
          ],
          other: {
            title: '',
            data: [{
              title: '产品特性',
              content: '产品多，产品专业性强，需要专业资质，市场准入门槛高，药品牵扯批次号，医疗器械牵扯序列号管理特性。'
            }, {
              title: '销售特性',
              content: '销售人员技能要求高，公关能力与人脉资源尤为重要。销售周期长，细节多，外勤频率高，销售人员长期驻外驻点销售，按片划区推广。'
            }, {
              title: '客户特性',
              content: '客户细分行业精准，长期合作客户多，客情关系尤为重要；产品附加值高，利润空间大，业务费用多，同行业排他性强。'
            }, {
              title: '合同特性',
              content: '通常为分期回款，药品批次号与退换货、样品赠品，以及医疗仪器设备产品牵扯安装调试，服务维保等。'
            }]
          }
        },
        {
          key: 5,
          tag: {
            viewType: 'tag',
            value: 5,
            label: '企业服务',
            effect: 'dark',
            color: '#6497ff'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/qy-1.svg'
          },
          title: 'To B服务行业解决方案',
          sub: {
            ind: '企业服务',
            softName: '阳光奥美财务顾问集团有限公司'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          soft: {
            name: '行业简介',
            content: '金智CRM To B 服务业客户主要集中在针对企业提供智力产品交付、服务代办、咨询策划等行业客户，常见的有：企业咨询、财会服务、人资服务、知识产权代理、劳务派遣等。'
          },
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户管理',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '销售跟单',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '采购库存',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: '售后服务',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/jfjh.svg',
              label: '交付计划',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/tscl.svg',
              label: '投诉处理',
              type: 'info'
            }
          ],
          other: {
            title: '',
            data: [{
              title: '产品特性',
              content: '以专业技能提供服务，产品表现形式为咨询解决方案、智力成果展示、提供服务代办等。'
            }, {
              title: '销售特性',
              content: '客户挖掘成本高，跟单特性明显；传统广告覆盖、网络营销营销方式较为常见，销售方式多元化，信息扩散与客户长线跟单特征明显。'
            }, {
              title: '客户特性',
              content: '企、事业单位客户多，协同服务频率高。客户服务沟通周期较长，牵扯多人对客户协同提供服务。'
            }, {
              title: '合同特性',
              content: '产品交付时效性要求高，一般为分期付款；回款多为分期，服务合同确定后，服务成果交付内容牵扯的工作量较多。'
            }]
          }
        },
        {
          key: 6,
          tag: {
            viewType: 'tag',
            value: 6,
            label: '批发零售',
            effect: 'dark',
            color: '#ffbf61'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/pf-1.svg'
          },
          title: '批发零售解决方案',
          sub: {
            ind: '批发零售行业',
            softName: '北京盛龙达商贸有限公司'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          soft: {
            name: '行业简介',
            content: '批发贸易行业主要包括商贸经销企业、批发零售企业，多数企业的客户对象不直接面向个人终端消费者，面向企业与个体工商户，长期合作，实现销售。常见的行业包括：烟酒、茶叶、酒店用品、五金用品、汽配耗材等消耗品商贸企业。'
          },
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户销售',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '跟单记录',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '采购库存',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: 'SFA营销',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/cgkc.svg',
              label: '采购库存',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdgl.svg',
              label: '工单管理',
              type: 'info'
            }
          ],
          other: {
            title: '',
            data: [{
              title: '产品特性',
              content: '产品多，产品为消耗品，量大价低；多数单价不高，主要靠走量批发，销售产品的数量与价格成反比，不同客户不同售价体系。'
            }, {
              title: '销售特性',
              content: '销售过程短，快速交易多。通常销售人员兼具销售、仓管、财务等多个职能，常见上门铺货推广销售。'
            }, {
              title: '客户特性',
              content: '长期合作客户多，产品质量与价格优势影响合作长久程度；多数属于老客户合作，一旦建立合作关系，轻易不会改变合作伙伴。'
            }, {
              title: '合同特性',
              content: '合同定期对账，先货后款与分期回款特点多；单品售价低，但一次销售产品品类或数量多；定期对账结算，赊欠款，预付款等特点明显。'
            }]
          }
        },
        {
          key: 7,
          tag: {
            viewType: 'tag',
            value: 7,
            label: '建材行业',
            effect: 'dark',
            color: '#8fde73'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/jc-1.svg'
          },
          title: '建材行业解决方案',
          sub: {
            ind: '建材行业',
            softName: '武汉兴铁新型建材有限公司'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          soft: {
            name: '行业简介',
            content: '建材贸易行业主要围绕“房”字展开，包括建筑建材、家居建材、装饰材料、电线辅材等，常见的为建筑建材面向是房地产企业为客户对象，而家居建材客户则以房屋装修用户为主。'
          },
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户管理',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '项目管理',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '合同订单',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: '售后服务',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/cgkc.svg',
              label: '采购库存',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/sfayx.svg',
              label: 'SFA营销',
              type: 'info'
            }
          ],
          other: {
            title: '',
            data: [{
              title: '产品特性',
              content: '根据房屋面积量评估建材用量；部分建材存在多单位换算，或需要通过切割、焊接、组装等再加工交付。'
            }, {
              title: '销售特性',
              content: '建筑建材业务方面销售人员经常需要项目外勤跟单；销售牵扯细节杂，过程长，而家居建材通常过程短当日能确定是否签约。'
            }, {
              title: '客户特性',
              content: '建筑建材多数为房产企业，工程单位等大企业。家居建材更多为个人用户；家居建材重复消费购买可能性低。'
            }, {
              title: '合同特性',
              content: '家居建材通常一次回款；建筑建材通常存在账期管理，分期交付发货等。家居建材通常一次发货完毕。'
            }]
          }
        },
        {
          key: 8,
          tag: {
            viewType: 'tag',
            value: 8,
            label: '教育培训',
            effect: 'dark',
            color: '#ff9a57'
          },
          image: {
            viewType: 'image',
            value: '/image/programme/jy-1.svg'
          },
          title: '教育培训解决方案',
          sub: {
            ind: '教育培训',
            softName: '武汉兴铁新型建材有限公司'
          },
          subtitle: '售前售中售后全流程多维度管理，实现业务在线',
          soft: {
            name: '行业简介',
            content: 'To C服务行业主要是针对个人为客户，提供服务的行业，常见的有：教育培训、房屋中介、旅行社、酒店住宿等。'
          },
          showTag: [{
            viewType: 'tag',
            value: '',
            image: '',
            label: '客户管理',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '销售跟单',
            type: 'info'
          },
          {
            viewType: 'tag',
            value: '',
            label: '采购库存',
            type: 'info'
          }, {
            viewType: 'tag',
            value: '',
            label: '售后服务',
            type: 'info'
          }],
          tagList: [
            {
              viewType: 'tag',
              value: '/image/programme/funtion/khzl.svg',
              image: '',
              label: '客户资料',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdjl.svg',
              label: '跟单记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/htdd.svg',
              label: '合同订单',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/cgkc.svg',
              label: '交付计划',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/gdtx.svg',
              label: '跟单提醒',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/xsbj.svg',
              label: '销售报价',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/hkjl.svg',
              label: '回款记录',
              type: 'info'
            },
            {
              viewType: 'tag',
              value: '/image/programme/funtion/tscl.svg',
              label: '投诉处理',
              type: 'info'
            }
          ],
          other: {
            title: '',
            data: [{
              title: '产品特性',
              content: '主要为提供服务或场所，收费为服务收费或交易产品佣金；一般无实物产品，市场销售人员与服务提供人员通常分工协作。'
            }, {
              title: '销售特性',
              content: '客户多数为自我挖掘或门店获客，以及借助网络营销获客等；销售方式通常多元化，信息扩散与客户阶段性长线跟单特征明显。'
            }, {
              title: '客户特性',
              content: '多数为个人客户，且存在家庭属性的多人共同参与的特性；联系方式作为唯一性判定是否为撞单客户较为常见，老带新客户较为常见。'
            }, {
              title: '合同特性',
              content: '产品交付较为特殊，时效性较强，通常提前约定交付时间，过期未交付，一般作退款处理；不开发票的场景较多，一般无需对账。'
            }]
          }
        }
      ]
    },
    // 服务
    service: {
      title: '优质服务',
      subtitle: '客户尊享 金智CRM全心全意为您服务 值得信赖',
      video: {
        title: '全心为您 服务PLUS —— 视频分享',
        dataList: [{
          video: 'https://jzcrm.eapps.dingtalkcloud.com/pro/video/v1.mp4',
          image: '/image/service/v1.png',
          itemSet: {
            viewType: 'tag',
            value: '',
            label: '宣传片'
          }
        }, {
          video: 'https://jzcrm.eapps.dingtalkcloud.com/pro/video/v2.mp4',
          image: '/image/service/v2.png',
          itemSet: {
            viewType: 'tag',
            value: '',
            label: '功能概括'
          }
        }, {
          video: 'https://jzcrm.eapps.dingtalkcloud.com/pro/video/v3.mp4',
          image: '/image/service/v3.png',
          itemSet: {
            viewType: 'tag',
            value: '',
            label: '系统实操'
          }
        }, {
          video: 'https://jzcrm.eapps.dingtalkcloud.com/pro/video/v4.mp4',
          image: '/image/service/v4.png',
          itemSet: {
            viewType: 'tag',
            value: '',
            label: '功能解读'
          }
        }]
      },
      // 帮助
      help: {
        title: '全心为您 服务PLUS —— 帮助解答',
        dataList: [{
          icon: 'icon-gouwuche',
          title: '远程培训'
        }, {
          icon: 'icon-xiaoxi',
          title: '视频教程'
        }, {
          icon: 'icon-shu',
          title: '帮助文档'
        }, {
          icon: 'icon-shu',
          title: '协助实施'
        }, {
          icon: 'icon-yonghuguanli',
          title: '回访服务'
        }, {
          icon: 'icon-fapiao',
          title: '升级互动'
        }, {
          icon: 'icon-book',
          title: '需求共创'
        }, {
          icon: 'icon-book',
          title: '其他服务'
        }]
      },
      // 品质
      pinzhi: {
        title: '品质服务·始终如一',
        dataList: [
          {
            title: '免费服务支持',
            dataList: [{
              icon: 'icon-correct',
              label: '新手入门视频教程'
            }, {
              icon: 'icon-correct',
              label: '热门行业解决方案'
            }, {
              icon: 'icon-correct',
              label: '基础操作文档'
            }, {
              icon: 'icon-correct',
              label: '用户常见问题解答'
            }, {
              icon: 'icon-correct',
              label: '帮助中心进阶教程'
            }, {
              icon: 'icon-correct',
              label: '企业数字化落地精品课程'
            }]
          },
          {
            title: '至尊服务',
            dataList: [{
              icon: 'icon-correct',
              label: '一对一专属服务群'
            }, {
              icon: 'icon-correct',
              label: '一对一专属线上指导'
            }, {
              icon: 'icon-correct',
              label: '基础操作文档'
            }, {
              icon: 'icon-correct',
              label: '企业流程与产品功能匹配梳理'
            }, {
              icon: 'icon-correct',
              label: '梳理过程视频录制'
            }, {
              icon: 'icon-correct',
              label: '专家配置专属流程和表单'
            }]
          },
          {
            title: '超级VIP服务',
            dataList: [{
              icon: 'icon-correct',
              label: '一对一专属服务群'
            }, {
              icon: 'icon-correct',
              label: '一对一专属线上指导'
            }, {
              icon: 'icon-correct',
              label: '企业流程与产品功能匹配梳理'
            }, {
              icon: 'icon-correct',
              label: '梳理过程视频录制'
            }, {
              icon: 'icon-correct',
              label: '专家配置专属流程和表单'
            }, {
              icon: 'icon-correct',
              label: '资深顾问上门服务实施'
            }]
          }
        ]
      },
      suppoer: {
        title: '服务支持',
        subtitle: '及时响应·耐心解答<span class="hidden-xs-only">·</span><span class="hidden-sm-and-up"><br /></span>专业服务·值得信赖',
        dataList: [{
          image: '/image/service/人工.png',
          title: '人工服务时间',
          subtitle: '周一至周六 08:00 - 18:00',
          other: '专属顾问系统内可见'
        }, {
          image: '/image/service/热线.png',
          title: '服务热线',
          subtitle: '0532-85971028',
          other: '0532-85971038'
        }, {
          image: '/image/service/方式.png',
          title: '服务方式',
          subtitle: '在线咨询',
          other: '钉钉VIP群服务'
        }]
      }
    },
    view: {
      // 服务协议
      protocols: `
        <div style="padding:18px 24px">
          <div style="width:100%;text-align:center;font-weight: 560;padding: 24px 0px;font-size:24px">服务协议</div>
          <div>
            <p><span
                style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">感谢您使用金智CRM产品，开通体验软件试用前，请您仔细阅读本服务协议，特别是有关权责义务条款，请重点阅读。当您勾选同意本协议，即表示您已经阅读并理解了协议内容。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">本协议不同于软件购买正式合同，正式合作签约时，双方将另行签约，本协议将作为正式协议的补充协议，本服务协议具有法律约束力。您不能以微阅读或不清晰条款含义为理由，主张本协议无效或要求撤销本协议。正式协议与本协议约定不一致的，以正式协议为准，未做约定的，本协议约定持续有效。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">1、协议双方</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">甲方是客户方（即贵公司），为金智CRM系统产品的用户。以下简称用户或您。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您确认您是具备完全民事权利能力和行为能力与所从事的民事行为相适应的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">乙方是金智公司（即青岛金智电子科技有限公司），为金智CRM产品提供方，以下简称“金智“、或”金智CRM“、或“我们”</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">2、协议内容</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您自愿体验试用金智CRM产品及服务，体验的产品包括金智CRM电脑端、移动端产品。我们将根据您提供的有效信息或其他能通过您所预留的信息联系到您及您公司的联系方式，与您联系，为您提供服务、推介产品。
              </span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您同意并理解，您所体验的产品仅只是试用期体验金智CRM产品，金智CRM产品所有权归属依然属于我们。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">我们与您联系的方式包括且不限于以下一种或多种形式：</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">电话：您预留的电话、您的公司对外宣传的电话等有效联系方式。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">群消息：系统配置的群消息，包括群内机器人消息和人工客服联系。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">邮箱或其他：通过其他方式与您取得联系，发送产品相关资料等。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">3、账号注册与管理</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您所体验试用的软件是包含有效期的产品，个别功能（如短信）需付费后才可试用。您理解并同意试用期满后，系统将自动限制您的登录访问，个别付费功能需要您付费后才可以试用。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您同意体验试用账号不进行转让，不做其他非法用途。</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">我们提醒您保管好您的账号和密码，当您使用完毕系统后，请进行安全退出。若因密码保管不善造成账号被盗问题，责任后果由您自行承担。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您理解并同意，您承诺注册的帐号名称、头像信息中不得出现违法或不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册帐号，不得使用可能侵犯他人权益的用户名（包括但不限于涉嫌商标权、名誉权侵权等），否则金智CRM公司有权不予注册或停止服务并收回帐号，因此产生的损失由您自行承担。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">4、软件变更或停服</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您所试用的金智CRM产品，不排除存在功能瑕疵或设备端兼容性问题，我们不能承诺所有功能所有设备任何时间不出现任何问题。我们将不断进行优化、迭代更新等方式进行产品改善。我们有权自行决定对系统功能进行优化、升级或修改，最终实现提升并优化产品体验，修正系统瑕疵错误等。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">5、信息录入或分享规范</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您承诺使用金智CRM产品与服务过程中，不会进行任何违法或不当活动，包括但不限于下列行为：您在使用金智CRM产品期间，向系统中录入的信息、传送分享的信息不得包含任何违法信息。包括但不限于：违法违宪信息、危害国家安全信息、散步谣言信息、色情暴力恐怖信息等，以及不含侮辱诽谤他人，侵害他人合法权益等任何信息。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">不侵犯他人权利，包括但不限于著作权、专利权、商标权、商业秘密等知识产权等。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">若您使用系统违法录入或分享传播不当信息，任何责任后果均由您承担。若存在以上违法违规行为时，我们有权依照相关法律法规的规定对相关举报内容核实、转通知以及删除、屏蔽等措施，以及采取包括但不限于收回帐号，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">6、禁止行为</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您承诺合法使用体验金智CRM产品与服务。不含以下行为：</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">不得干扰或破坏金智CRM系统正常运行，包括通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您承诺不会通过非金智CRM公司开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具；</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">7、关于费用</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">金智CRM试用期免费体验的产品或服务，不视为我们放弃后续收费权利，您继续使用我们的产品或体验服务的，需要向我们支付相应费用。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">8、数据安全</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">我们将采用各种安全策略保障您的账号与数据存储安全，包括各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在金智CRM中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于信息加密存储、数据中心的访问控制。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">金智CRM会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，金智CRM将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">9、知识产权</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您了解及同意，除非金智CRM公司另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归金智CRM公司所有。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">10、有限责任</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">不论在何种情况下，金智CRM均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">11、争议解决及其他 </span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">1)
                本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以青岛市市南区人民法院为第一审管辖法院。</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">2)
                如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部分则仍具有法律效力。</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">3)
                您理解并同意，金智CRM公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</span></p>
          </div>
        </div>`,
      // 隐私政策
      secure: `
        <div style="padding:18px 24px">
          <div style="width:100%;text-align:center;font-weight: 560;padding: 24px 0px;font-size:24px">金智CRM隐私政策</div>
          <div >
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">本政策不同于软件购买正式合同，正式合作签约时，双方将另行签约，本政策将作为正式协议的补充协议，本服务协议具有法律约束力。您不能以微阅读或不清晰条款含义为理由，主张本政策无效。正式协议与本政策条款约定不一致的，以正式协议为准，未做约定的，本政策约定持续有效。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">本政策适用于金智CRM产品以及服务，本政策不适用于第三方通过金智CRM产品直接向您提供的服务（统称“第三方服务”），您向第三方提供的信息不适用于本政策。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">在使用金智CRM产品或服务前，请您务必仔细阅读并透彻理解本政策，如您对本隐私政策有任何疑问，您可以通过我们公布的联系方式与我们联系</span><span
                style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">。一旦您点击同意《金智CRM隐私政策》或开始使用金智产品或服务，即表示您已充分理解本政策，并同意我们按照本隐私政策来收集、处理、存储、使用和保护您的个人信息。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">1、权益主体</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">甲方是客户方（即贵公司），为金智CRM系统产品的用户。以下简称用户或您。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您确认您是具备完全民事权利能力和行为能力与所从事的民事行为相适应的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">乙方是金智公司（即青岛金智电子科技有限公司），为金智CRM产品提供方，以下简称“金智“、或”金智CRM“、或“我们”。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">2、信息获取与使用</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">在您使用我们的产品及或服务时，我们可能需要收集和使用的您的个人信息包括如下两种:
              </span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">1)
                为实现向您提供我们产品及或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及或服务；</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">2)
                为实现向您提供我们产品及或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及或服务的基本功能。</span>
            </p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您理解并同意: </span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">为给您带来更好的产品和服务体验，我们会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">3、获取信息范围</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">注册软件试用时您所预留给我们的信息，以及您的组织cropid、企业组织代码等基于您所预留信息通过公开或其他合法渠道可以获知的信息。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您或其他您授权我们联系的人在与我们联系时所预留的其他联系方式。我们将按照以上信息与您联系，如果您不再希望我们与您联系，请向我们说明，后续我们将不再与您联系。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">我们系统部分功能正常合理使用，需要调用设备端信息，将获取到您设备相关基础信息，</span><span
                style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">例如设备型号、设备名称、GPS位置信息、通话权限、录音权限、访问系统文件、上网权限、调用相机、调用图册、调用通讯录等。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">您使用系统录入的信息存储在您所使用的系统中，我们不会查看、处理或改变这部分信息（除您特定合理要求并授权给我们处理的情况外），您开启权限后也可以关闭任一权限，关闭权限后，我们将不再提供基于该权限的服务，相应功能将可能无法获取到有关信息。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">如您停止使用金智CRM产品或服务，我们系统将自动停止继续收集以上信息，您所录入的数据也将由您自行决定如何处理。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">4、日志信息</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">当您使用我们的客户端、网站提供的产品或服务时，我们系统会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的IP地址、浏览器的类型、电信运营商、访问日期和时间等操作日志信息，这类信息我们将予以保密，除非您的授权要求或法律另有规定。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">5、其他信息或授权要求说明</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">在您需要我们正式授权系统时，我们需要确定提单的组织团队，我们需要您的组织cropid、管理员手机号，届时我们将安排人员与您联系。</span>
            </p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">如果您需要我们为您导出数据、批量执行数据的删除或变更、或停止特定人员登录系统，修改账号密码，跨系统对接系统等，为确保您的信息安全，我们将要求得到您公司充分授权同意，我们将与您联系。可能还需管理员及法定代表人姓名、身份信息、手机号码。您理解并同意您在向我们提供该企业组织用户信息及实际相关人员前述个人信息之前，已经取得充分授权同意。</span>
            </p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">6、其他说明</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">1)
                若您提供的信息中含有其他用户的个人信息，在向金智提供这些个人信息之前，您需确保您已经取得合法的授权。</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">2)
                若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。</span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">3)
                如果您认为金智存在违反法律的规定收集、使用您个人信息的情形，您可以通过金智客服与我们取得联系。</span></p>
            <p><span style="font-family:'苹方-简 中粗体', '苹方-简 常规体', '苹方-简', sans-serif;font-weight:600;">7、管辖与法律适用 </span></p>
            <p><span style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">本隐私政策适用于中华人民共和国大陆地区法律。</span></p>
            <p><span
                style="font-family:'苹方-简 常规体', '苹方-简', sans-serif;font-weight:400;">如果您和我们发生任何纠纷或争议，均应依照中华人民共和国法律予以处理，并以青岛市市南区人民法院为第一审管辖法院。</span>
            </p>
          </div>
        </div>
      `
    },
    // 企微、飞书、钉钉
    other: {
      wechat: {
        mainImg: '/image/other/wechat.png',
        btnList: [
          {
            lg: '/image/other/login_white_small_2x.png',
            sm: '/image/other/login_white_middle.png',
            type: 'login'
          },
          {
            lg: '/image/other/install_white_small_2x.png',
            sm: '/image/other/install_white_middle.png',
            type: 'install'
          },
          {
            lg: '/image/other/register_white_small_2x.png',
            sm: '/image/other/register_white_middle.png',
            type: 'register'
          }
        ]
      },
      ding: {
        mainImg: '/image/other/dingding.png'
      },
      feishu: {
        mainImg: '/image/other/wechat.png'
      },
      banner: {
        title: '全流程一站式解决方案<br />数据驱动企业提升盈利能力',
        backImg: '/image/other/otherBack.png',
        backRightImg: '/image/other/backRight.png'
      }
    },
    appConfig: {
      SUITE_ID: 'wxadd370345b9522f6',
      login: {
        wechat: 'https://weixin.jzsoft.cn/pro/user/login?redirect=https%3A%2F%2Fweixin.jzsoft.cn%2Fpro%2Fdashboard%2Fanalysis',
        dingding: 'https://crm.jzsoft.cn/pro/user/login?redirect=https%3A%2F%2Fcrm.jzsoft.cn%2Fpro%2Fdashboard%2Fanalysis',
        feishu: 'https://feishu.jzsoft.cn/pro/user/login?redirect=https%3A%2F%2Ffeishu.jzsoft.cn%2Fpro%2Fuser%2Flogin%3Fredirect%3Dhttps%253A%252F%252Ffeishu.jzsoft.cn%252Fpro%252Fdashboard%252Fanalysis'
      },
      openOther: {
        wechat: 'https://open.work.weixin.qq.com/appStore/app?appId=MTk3MDMyNDk1MzAxNzI5NV8xMDExMTk5XzE%3D&source=search&delivery_from=search&authFrom=Market'
      }
    }
  },
  mutations: {
    userInfo (state, context) {
      state.alertShowNum -= 1
    }
  },
  actions: {
  },
  modules: {
  }
})
