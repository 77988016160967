<!--
 * @Description:html
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 10:45:31
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-07 11:03:13
-->
<template>
  <div v-html="itemSet.value">
  </div>
</template>

<script>
export default {
  props: {
    itemSet: {
      type: Object
    }
  },
  setup (props, context) {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
