/*
 * @Description:api
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-10 15:25:20
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-10 15:28:46
 */

import requests from './request'

export const Requests = (data) => {
  let url = data.url
  let ii = 0
  for (const i in data.data) {
    if (ii == 0) {
      url = `${url}?${i}=${data.data[i]}`
    } else {
      url = `${url}&${i}=${data.data[i]}`
    }
    ii = ii + 1
  }
  return requests({
    url: url,
    method: data.method,
    data: data.params
  })
}
