<!--
 * @Description: 首页弹出框
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-06 13:56:33
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-07-28 15:46:42
-->
<!-- 柳姐提供 弹出框是不显示右侧和底部按钮 -->
<template>
  <div class="hidden-xs-only">
    <div class="showModel" v-if="(visible && navActive == 0)">
      <div class="mask" @click="handMask"></div>
      <div class="content">
        <div class="content-head">
          <div class="content-head-icon">
            <JzIcon class="nav-info-logo jz-hv" icon="icon-jzicon" :iStyle="{ 'font-size': '112px' }"></JzIcon>
          </div>
          <div class="content-head-close  jz-hv" @click="handMask">
            <JzIcon class="nav-info-logo" icon="icon-close" :iStyle="{ 'font-size': '20px' }">
            </JzIcon>
          </div>
        </div>
        <div class="content-info">
          <div class="content-info-title">{{ soft.name }}欢迎您</div>
          <div class="content-info-subtitle">客户热线：{{ soft.hotline }}</div>
        </div>
        <div class="content-footer">
          <JzButton style="height:40px;width: 88px;" :itemSet="{ 'title': '免费试用' }" :btnProps="{ 'type': 'primary' }" :onCallBack="{ 'handClick': freeTrial }">
          </JzButton>
        </div>
      </div>
    </div>
    <div class="options" v-else>
      <!-- 右侧按钮 -->
      <div class="options-right">
        <div class="options-right-btns">
          <div class="options-right-btns-item" style="cursor: pointer;" @click="onLine">
            <!-- <JzIcon class="nav-info-logo jz-hv" icon="icon-kehu" :iStyle="{ 'font-size': '30px' }"></JzIcon> -->
            <el-image :lazy="true" style="width:24px;height:23px" src="/svg/zaixian.svg"></el-image>
            <span class="options-right-btns-item-title">在线客服</span>
          </div>
          <el-popover :width="143" placement="left" trigger="hover">
            <template #reference>
              <div class="options-right-btns-item">
                <!-- <JzIcon class="nav-info-logo jz-hv" icon="icon-kehu" :iStyle="{ 'font-size': '30px' }"></JzIcon> -->
                <el-image :lazy="true" style="width:24px;height:23px" src="/svg/客服热线.svg"></el-image>
                <span class="options-right-btns-item-title">客服热线</span>
              </div>
            </template>
            <template #default>
              <div class="call">
                <div class="call-title">0532-85971028</div>
                <div class="call-title">0532-85971038</div>
              </div>
            </template>
          </el-popover>
          <!-- 扫码咨询 -->
          <el-popover :width="300" placement="left" trigger="hover">
            <template #reference>
              <div class="options-right-btns-item">
                <!-- <JzIcon class="nav-info-logo jz-hv" icon="icon-sao" :iStyle="{ 'font-size': '30px' }"></JzIcon> -->
                <el-image :lazy="true" style="width:24px;height:23px" src="/svg/扫码咨询.svg"></el-image>
                <span class="options-right-btns-item-title">扫码咨询</span>
              </div>
            </template>
            <template #default>
              <div style="display:flex;justify-content: space-between;">
                <div class="scand">
                  <div class="scand-img">
                    <el-image class="image" src="/image/wxCode.png" fit="fill"></el-image>
                    <!-- <ima class="image" src="/image/image.jpg"></ima> -->
                  </div>
                  <div class="scand-title">微信扫码咨询</div>
                </div>
                <div class="scand">
                  <div class="scand-img">
                    <el-image class="image" src="/image/ddCode.jpg" fit="fill"></el-image>
                    <!-- <ima class="image" src="/image/image.jpg"></ima> -->
                  </div>
                  <div class="scand-title">钉钉扫码咨询</div>
                </div>
              </div>
            </template>
          </el-popover>
          <div class="options-right-btns-item" v-if="toTop" @click="hindToTop" style="padding-bottom: 6px;height: 60px;">
            <JzIcon class="nav-info-logo jz-hv" icon="icon-toTop" :iStyle="{ 'font-size': '30px' }"></JzIcon>
            <span class="options-right-btns-item-title"></span>
          </div>
        </div>
      </div>
      <div class="options-footer" id="jz-soft-footer-reg" v-if="forms.visible">
        <div class="container-fluid options-footer-line">
          <!-- <div class="container options-footer-line"> -->
          <div class="options-footer-line-left">
            <JzIcon icon="icon-jzicon" :iStyle="{ 'font-size': '36px' }"></JzIcon>
            <span class="options-footer-line-left-title">金智CRM</span>
          </div>
          <div class="options-footer-line-tip">
            0元领取使用资格
          </div>
          <div class="options-footer-line-form">
            <el-input class="options-footer-line-form-input form-input-user" v-model="forms.formValue.enterprise" type="text" placeholder="请输入完整企业名称"></el-input>
            <el-input class="options-footer-line-form-input form-input-user" v-model="forms.formValue.name" type="text" placeholder="怎么称呼您"></el-input>
            <el-input class="options-footer-line-form-input form-input-user" maxlength="11" v-model="forms.formValue.number" type="text" placeholder="请输入手机号"></el-input>
            <div class="options-footer-line-form-code">
              <el-input class="options-footer-line-form-input" v-model="forms.formValue.code" type="text" placeholder="请输入验证码"></el-input>
              <el-button class="options-footer-line-form-code-button" type="primary" @click="getCode" :disabled="showInfo.disabled">{{ showInfo.btnTitle }}</el-button>
            </div>
            <JzButton :itemSet="{ 'title': '立即领取' }" style="width:112px;height:40px;margin-left: 6px;" :btnProps="{ 'type': 'primary', 'loading': loading }" :onCallBack="{ 'handClick': submit }">
            </JzButton>
            <JzIcon class="jz-hv options-footer-line-form-close" icon="icon-close" @click="handCloseForm" :iStyle="{ 'font-size': '26px' }">
            </JzIcon>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <el-dialog v-model="showVisible" width="400px" :modal="true" :append-to-body="true" :close-on-click-modal="false" :before-close="handleClose">
      <template #header>
        <div style="font-weight:600">
          感谢使用金智CRM
        </div>
      </template>
      <div class="box">
        <div class="box-content">
          <div style="display:flex;margin-bottom:16px">
            <div style="font-size:14px;font-weight:600;width:50%;text-align:right;">登录名：</div>
            <div style="width:50%;text-align:left;">{{ showLoginInfo.entno }}</div>
          </div>
          <div style="display:flex;margin-bottom:16px">
            <div style="font-size:14px;font-weight:600;width:50%;text-align:right;">用户名：</div>
            <div style="width:50%;text-align:left;">{{ showLoginInfo.user }}</div>
          </div>
          <div style="display:flex;margin-bottom:16px">
            <div style="font-size:14px;font-weight:600;width:50%;text-align:right;display: flex;justify-content: flex-end;">密 <div style="width: 14px;"></div>码：</div>
            <div style="width:50%;text-align:left;">{{ showLoginInfo.password }}</div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="">
          <el-button @click="handleClose">关闭</el-button>
          <el-button type="primary" @click="goLogin">去登录</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import JzButton from '@/components/JzButton/JzButton.vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { computed, onMounted, reactive, ref } from 'vue-demi'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { Requests } from '@/api/index.js'
export default {
  components: { JzIcon, JzButton },
  name: 'JzShowModel',
  props: {
    navActive: [Number, String]
  },
  setup(props, context) {
    const $router = useRouter()
    const forms = reactive({
      visible: true,
      formValue: {
        // 企业名称
        enterprise: '',
        name: '',
        // 手机号
        number: '',
        // 验证码
        code: ''
      }
    })
    const $store = useStore()
    const visible = ref(false)
    // 回到顶部
    const toTop = ref(false)
    // 定时器
    const Times = ref(-1)
    // 按钮加载中
    const loading = ref(false)
    const showInfo = computed(() => {
      return Times.value > 0 ? {
        btnTitle: `${Times.value}s`,
        disabled: true
      } : {
        btnTitle: '获取验证码',
        disabled: false
      }
    })
    const handMask = () => {
      $store.commit('userInfo')
      visible.value = false
    }
    // 公司信息
    const soft = computed(() => {
      return $store.state.soft
    })
    // 监听是否滚动
    const toTopScrollx = () => {
      if (window.pageYOffset == 0) {
        toTop.value = false
      } else {
        toTop.value = true
      }
    }
    // 回到顶部
    const hindToTop = () => {
      window.scrollTo(0, 0)
    }
    // 立即领取按钮
    const submit = async () => {
      if (forms.formValue.enterprise) {
        if (forms.formValue.name) {
          if (forms.formValue.number && /^1[3456789][0-9]\d{8}$/.test(forms.formValue.number)) {
            if (forms.formValue.code) {
              loading.value = true
              const params = {
                url: 'https://crm.jzsoft.cn/pro/api/user/RegisterCompany',
                method: 'GET',
                data: {
                  company: forms.formValue.enterprise,
                  linkman: forms.formValue.name,
                  tel: forms.formValue.number,
                  code: forms.formValue.code,
                  codeType: 'register',
                },
                params: {},
              }
              // 来源于百度推广
              if (window.location.href.indexOf('baiduMarket') > -1) {
                params.data.registerFrom = 'baidu';
              }

              // 来源于推广拉新注册
              if (window.location.href.indexOf('para') > -1) {
                const tempUrl = window.location.href.substring(window.location.href.indexOf('?'))
                const theRequest = {}
                if (tempUrl.indexOf('?') != -1) {
                  const str = tempUrl.substring(tempUrl.indexOf('?') + 1)
                  const strs = str.split('&')
                  for (let i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
                  }
                }
                params.data = { ...params.data, ...theRequest }
              }

              const res = await Requests(params)
              if (res.errcode === 0) {
                ElMessage.success('感谢使用金智CRM，账户密码下发到您的手机，如果您有需要，请与我们联系')
                if (typeof (res.result) == 'object') {
                  showLoginInfo.value = res.result;
                  showVisible.value = true;
                } else {
                  setTimeout(() => {
                    window.open('https://crm.jzsoft.cn/pro')
                  }, 1000)
                }
              }
              loading.value = false
            } else {
              ElMessage.error('请输入验证码')
            }
          } else {
            ElMessage.error('请输入正确手机号')
          }
        } else {
          ElMessage.error('请输入您的姓名')
        }
      } else {
        ElMessage.error('请输入完整企业名称')
      }
    }
    // 关闭底部输入框
    const handCloseForm = () => {
      forms.visible = false
    }
    // 获取验证码
    const getCode = async () => {
      if (forms.formValue.number && forms.formValue.number.length == 11) {
        const params = {
          url: 'https://crm.jzsoft.cn/pro/api/MobileSms/GetCode',
          method: 'get',
          data: {
            phone: forms.formValue.number,
            codeType: 'register',
          }
        }
        const res = await Requests(params)
        if (res.errcode === 0) {
          ElMessage.success('发送成功');
          Times.value = 60
          const times = setInterval(() => {
            if (Times.value == 0) {
              clearInterval(times)
            }
            Times.value -= 1
          }, 1000)
        } else {
        }
      } else {
        ElMessage.error('请输入正确手机号')
      }
    }
    // 在线客服
    const onLine = () => {
      openZoosUrl('chatwin');
    }

    const showVisible = ref(false);
    const showLoginInfo = ref({
      entno: '*****',
      password: '*****',
      user: '*****',
    })

    //
    const goLogin = () => {
      window.open('https://crm.jzsoft.cn/pro')
    }
    // 弹出框关闭之前
    const handleClose = () => {
      showVisible.value = false;
    }
    onMounted(() => {
      // 首页弹窗  刘工说影响用户体验 先屏蔽
      // if ($store.state.alertShowNum > 0) {
      //   visible.value = true
      // } else {
      //   visible.value = false
      // }
      // 绑定监听事件 监听页面滚动
      window.addEventListener('scroll', toTopScrollx, true)
    })
    return {
      handMask,
      visible,
      soft,
      toTop,
      hindToTop,
      forms,
      submit,
      handCloseForm,
      getCode,
      showInfo,
      loading,
      onLine,
      showVisible,
      goLogin,
      showLoginInfo,
      handleClose,
    }
  },
  methods: {
    freeTrial() {
      this.handMask()
      this.$router.push({
        path: '/trial'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.showModel {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .mask {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.24);
  }

  .content {
    position: fixed;
    width: 418px;
    height: 249px;
    background-color: #ffffff;
    border-radius: 8px;

    &-head {
      width: 100%;
      height: 56px;
      position: relative;

      &-icon {
        position: absolute;
        margin-left: calc(50% - 56px);
        border-radius: 50%;
        overflow: hidden;
        top: -56px;
      }

      &-close {
        width: 56px;
        height: 56px;
        position: absolute;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    // 内容
    &-info {
      width: 100%;
      text-align: center;
      font-size: 24px;
      color: #1f2228d8;
      margin-top: 16px;
      padding: 8px 16px;

      &-title {
        font-weight: 600;
      }
    }

    // 按钮
    &-footer {
      margin-top: 24px;
      width: 100%;
      text-align: center;
    }
  }
}

// 操作按钮 以及底部信息
.options {
  &-right {
    position: fixed;
    z-index: 999;
    right: 0px;
    top: calc(50vh - 108px);

    &-btns {
      display: flex;
      flex-direction: column;
      width: 60px;
      border-radius: 8px;
      overflow: hidden;
      background-color: #ffffff;
      box-shadow: rgba(95, 123, 178, 0.3) -2px 0px 0px 0px;

      &-item {
        width: 60px;
        height: 72px;
        border-bottom: 1px solid #efefef;
        display: flex;
        justify-content: flex-end;
        padding-bottom: 6px;
        align-items: center;
        flex-direction: column;

        &-title {
          margin-top: 8px;
          font-size: 12px;
          color: #1f222899;
        }
      }
    }
  }

  &-footer {
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    left: 0px;
    background-color: rgba(46, 49, 55, 0.99);

    &-line {
      color: #ffffff;
      display: flex;
      align-items: center;
      height: 60px;

      &-left {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        &-title {
          margin-left: 16px;
          font-weight: 400;
          font-size: 22px;
        }
      }

      // 提示
      &-tip {
        margin: 0px 36px;
        color: 14px;
        flex-shrink: 0;
      }

      // 表单
      &-form {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &-input {
          margin: 0px 6px;
          width: 210px;
          height: 40px;

          ::v-deep(.el-input__wrapper) {
            background-color: rgba(46, 49, 55, 0.99);
          }
          ::v-deep(.el-input__inner) {
            color: #ffffff !important;
          }
        }

        // 获取验证码
        &-code {
          position: relative;

          ::v-deep(.el-input__inner) {
            padding-right: 96px;
            overflow: hidden;
          }

          &-button {
            position: absolute;
            top: 1px;
            right: 7px;
            width: 94px;
            height: 38px;
          }
        }

        // 关闭按钮
        &-close {
          position: absolute;
          right: 10px;
        }
      }
    }
  }
}

.call {
  height: 40px;
}

// 扫码咨询样式
.scand {
  height: 143px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &-img {
    width: 120px;
    height: 120px;
  }

  &-title {
    font-size: 12px;
    margin-top: 6px;
    color: #1f222877;
  }
}

.call {
  height: 36px;

  &-title {
    font-size: 14px;
    color: #1f2228ee;
    font-weight: 600;
  }
}

//媒体查询
@media (max-width: 767px) {
}
@media (min-width: 768px) and (max-width: 992px) {
  .options-footer-line-tip {
    display: none;
  }
  .options-footer-line-left-title {
    display: none;
  }
  .form-input-user {
    width: 150px;
  }
  .options-footer-line-form {
    width: 90%;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .options-footer-line-tip {
    display: none;
  }
  .form-input-user {
    width: 150px;
  }

  .options-footer-line-form {
    width: 84%;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .form-input-user {
    width: 160px;
  }
}
@media (min-width: 1301px) and (max-width: 1400px) {
  .form-input-user {
    width: 180px;
  }
  .options-footer-line-form {
    width: 68%;
  }
}
// @media (min-width: 1400px)  {
//   .form-input-user {
//     width: 180px;
//   }
//   .options-footer-line{
//     width: 50%;
//   }
// }
</style>
