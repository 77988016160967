<!--
 * @Description:按钮组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 17:50:59
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-02 16:41:45
-->
<template>
  <el-button class="buttons" :type="btnProps.type" :size="btnProps.size" :pain="btnProps.pain" :text="btnProps.text" :bg="btnProps.bg" :link="btnProps.link" :round="btnProps.round" :circle="btnProps.circle" :plain="btnProps.plain" :loading="btnProps.loading" :disabled="btnProps.disabled" @click="handClick">
  <template v-if="loginUrl">
    <a href="https://crm.jzsoft.cn/pro" target="_blank">登录</a>
  </template>
  <template v-else>
    {{ itemSet.title}}
  </template>
  </el-button>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: 'JzButton',
  props: {
    itemSet: {
      type: Object,
      default: {}
    },
    btnProps: {
      type: Object,
      default: {}
    },
    onCallBack: {
      type: Object,
      default: {}
    },
    backInfo: {
      type: Object,
      default: {}
    },
    loginUrl: {
      type: String,
    }
  },
  setup(props, context) {
    const handClick = (event) => {
      const target = event.target
      if (target.nodeName === 'SPAN') {
        target.parentNode.blur()
      } else {
        target.blur()
      }
      console.log('loginUrl--',props.loginUrl);
      if (typeof (props.onCallBack.handClick) === 'function') {
        props.onCallBack.handClick(event, { ...props.backInfo })
      } else if(props.loginUrl){
        location.href = 'https://crm.jzsoft.cn/pro'
      }else{
        ElMessage({
          message: '没有传递方法',
          type: 'error',
          duration: 1000
        })
      }
    }
    return {
      handClick
    }
  }
}
</script>

<style scoped lang="scss">
.buttons {
  width: 100%;
  height: 100%;
}
a{text-decoration:none;color:#ffffff;}
</style>
