<!--
 * @Description:推广大使
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 17:42:37
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-08-29 13:57:18
-->
<template>
  <div class="promotion">
    <div class="promotion-head">
      <div class="promotion-head-logo jz-flex">
        <el-image :lazy="true" style="height:28px;width:28px;" src="/image/jz_app_logo.png"></el-image>
        <span style="font-size: 18px;margin-left: 6px;font-weight: bold;">金智CRM</span>
      </div>
      <div class="promotion-head-tab">
        <div class="promotion-head-tab-item jz-flex jz-hs"
          :class="[listType == 'setting' ? 'promotion-head-tab-item-act' : '']" @click="toggleList('setting')">邀请好友</div>
        <div class="promotion-head-tab-item jz-flex jz-hs"
          :class="[listType == 'table' ? 'promotion-head-tab-item-act' : '']" @click="toggleList('table')">推广详情</div>
      </div>
      <div class="promotion-head-info jz-flex">{{ userInfo.userName || '' }}</div>
    </div>
    <!-- 推广页面 -->
    <div class="promotion-setting" v-if="listType == 'setting'">
      <div style="width: 100%;">
        <el-image :lazy="true" style="width:100%;border-radius: 6px;" src="/image/promotion_banner1.png"></el-image>
        <div style="position: relative; width:100%;">
          <div style="width:100px; height: 44px; position: absolute; bottom: 26px; left: calc(50% - 50px);" class="jz-hs"
            @click="showRule()">
          </div>
        </div>
        <div style="width: 100%; padding: 0px 46px; display: flex; margin-top: 40px; flex-wrap: wrap;">
          <div style="width: calc(33.3333% - 22px); min-width: 380px; margin-right: 22px; margin-bottom: 22px;">
            <div class="jz-flex" style="font-size: 20px; margin-bottom: 8px;">二维码邀请</div>
            <div class="jz-flex"
              style="width: 100%; height: 385px; padding: 26px 16px; box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px; flex-wrap: wrap;">
              <div class="jz-flex" id="jz-link-qrcode" style="width: 100%; height: 210px;">
                <canvas style="width:210px;height:210px;" id="qrcode_canvas"></canvas>
              </div>
              <div class="jz-flex" style="width: 100%;justify-content: flex-start;">
                <el-tooltip :content="`专属链接：${settingData.promotionUrl || ''}`">
                  <div class="font-over" style="width: calc(100% - 40px);">
                    {{ `专属链接：${settingData.promotionUrl || ''}` }}
                  </div>
                </el-tooltip>
                <el-tooltip :content="`点击复制`">
                  <CopyDocument v-if="settingData.promotionUrl"
                    style="width:16px;height:16px;color:#035dff;cursor: pointer;" @click="jzCopy('jz-qrcode-text')" />
                </el-tooltip>
                <span id="jz-qrcode-text" style="display:none;">{{ settingData.promotionUrl }}</span>
              </div>
              <div style="width: 100%;" class="jz-flex">
                <el-button type="primary" :disabled="!settingData.promotionUrl"
                  @click="downloadQRCode">下载专属二维码</el-button>
              </div>
            </div>
          </div>
          <div style="width: calc(33.3333% - 22px); min-width: 380px; margin-right: 22px; margin-bottom: 22px;">
            <div class="jz-flex" style="font-size: 20px; margin-bottom: 8px;">海报邀请</div>
            <div class="jz-flex"
              style="width: 100%; height: 385px; padding: 26px 16px; box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px; flex-wrap: wrap;">
              <div style="width:338px; height:246px;">
                <el-popover trigger="hover" width="400px">
                  <template #reference>
                    <el-image :lazy="true" style="width:338px; height:246px;"
                      :src="settingData.posterUrl || ''"></el-image>
                  </template>
                  <el-image style="width:375px; height:567px;" :src="settingData.posterDownLoadUrl || ''"></el-image>
                </el-popover>
              </div>
              <div style="width: 100%;" class="jz-flex">
                <el-button type="primary" :disabled="!settingData.posterDownLoadUrl"
                  @click="downloadImage">下载专属海报</el-button>
              </div>
            </div>
          </div>
          <div style="width: calc(33.3333% - 22px); min-width: 380px; margin-bottom: 22px;">
            <div class="jz-flex" style="font-size: 20px; margin-bottom: 8px;">您推我联</div>
            <div class="jz-flex"
              style="width: 100%; height: 385px; padding: 26px 16px; box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px; flex-wrap: wrap;">
              <div class="jz-flex"
                style="width: 100%; flex-wrap: wrap; height: 246px; align-items: end; padding-top: 60px;">
                <div style="width: 100%; height: 33.33%; text-align: center;">1：您提供客户的联系方式。</div>
                <div style="width: 100%; height: 33.33%; text-align: center;">2：金智CRM客服联系客户。</div>
                <div style="width: 100%; height: 33.33%; text-align: center;">3：客户成交后给与您奖励。</div>
              </div>
              <div style="width: 100%;" class="jz-flex">
                <el-button type="primary" @click="showAdd()">推荐用户</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表页面 -->
    <div class="promotion-table" v-else>

      <div class="promotion-table-stat">

        <div class="jz-flex" style="justify-content: space-between;">
          <div style="padding: 16px 0px; display: flex; align-items: center;"><span class="jz-left-blue-line"></span>
            <span style="display: inline-block; font-weight: 600; color: rgba(0, 0, 0, 0.88);font-size: 16px;">收益详情</span>
          </div>
          <div style="gap: 6px;display: flex; align-items: center;">
            <div class="jz-flex"
              style="width: 20px; height: 20px; border-radius: 100%; color: rgb(255, 255, 255); background-color: rgb(250, 173, 20);">
              ★</div>
            <span>当前积分:</span>
            <span>{{ listData.jifen || 0 }}</span>
            <span>积分规则</span>
            <el-popover width="400" trigger="hover">
              <template #reference>
                <div
                  style="width:14px;height:14px;border-radius: 100px;color: #ccc;border: solid 1px #ccc; font-size: 10px;"
                  class="jz-flex">?</div>
              </template>
              <div style="width: 370px; gap: 8px; font-size: 14px;">
                <div style="">
                  <div>推荐意向客户1个，金智回访有效，不论推荐客户是否选购金智CRM系统， 推荐大使奖励积分10分，奖励积分只可用于本组织金智CRM系统续费、
                    增值充值或版本升级时，可进行金额抵扣，积分不可提现，不可转赠，
                    100积分=续费优惠100元。</div>
                </div>
                <div>
                  <div> 一个组织内多人推荐意向客户的，积分可叠加使用。</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
      <!-- 统计数据 -->
      <div style="width: 100%; display: flex; justify-content: space-between; margin-bottom: 18px;">
        <div class="pStatDataSettled">
          <div style="width: 100%; padding-left: 4px;">已结算</div>
          <div class="jz-flex" style="width: 100%;"><span class="ant-typography css-dev-only-do-not-override-10qtpcf"
              style="font-size: 30px; color: rgb(245, 79, 20); font-weight: bold;">{{ listData?.settled?.value || '¥0'
              }}</span>
              <span class="">{{ listData?.settled?.label || ''}}</span>
          </div>
          <div class="jz-flex" style="width: 100%;"><span class="jz-secondary">可联系客服提现或兑换精美礼品</span>
          </div>
        </div>
        <div class="pStatDataUnSettled">
          <div style="width: 100%; padding-left: 4px;">未结算</div>
          <div class="jz-flex" style="width: 100%;"><span class="ant-typography css-dev-only-do-not-override-10qtpcf"
              style="font-size: 30px; font-weight: bold;">{{ listData?.unSettled?.value || '¥0' }}</span>
          <span class="">{{ listData?.unSettled?.label || '' }}</span>
          </div>
          <div class="jz-flex" style="width: 100%;"><span class="jz-secondary">每七天进行一次结算</span></div>
        </div>
        <div class="pStatDataNotSettled">
          <div style="width: 100%; padding-left: 4px;">不予结算</div>
          <div class="jz-flex" style="width: 100%;">
            <span class="ant-typography css-dev-only-do-not-override-10qtpcf"
              style="font-size: 30px; font-weight: bold;">{{ listData?.notSettled?.value || '¥0' }}</span>
              <span class="">{{ listData?.notSettled?.label || '' }}</span>
          </div>
          <div class="jz-flex" style="width: 100%;"><span class="jz-secondary">订单关闭无收益
              ，订单按实际成交金额返利</span></div>
        </div>
      </div>
      <!-- 查询 -->
      <div class="promotion-table-stat jz-flex" style="justify-content: space-between;">
        <div style="padding: 16px 0px; display: flex; align-items: center;"><span class="jz-left-blue-line"></span>
          <span style="display: inline-block; font-weight: 600; color: rgba(0, 0, 0, 0.88);font-size: 16px;">收益记录</span>
        </div>
        <el-input v-model="keyword"
          :placeholder="`输入关键字搜索${listData?.headList?.filter?.(val => val.canSearch)?.map?.(val => val.title)?.join?.('、') || ''}`"
          style="width: 400px;"></el-input>
      </div>
      <div class="promotion-table-query jz-flex">
        <template v-if="Array.isArray(listData.queryList)" v-for="(item, index) in listData.queryList"
          :key="item.dataIndex">
          <div class="jz-nowrap">{{ item.title || '' }}</div>
          <el-select v-model="queryObj[item.dataIndex]" clearable placeholder="">
            <el-option v-for="oitem in item.option" :key="oitem.value" :label="oitem.label" :value="oitem.value" />
          </el-select>
        </template>
        <div style="width:300px;">
          <el-date-picker v-model="queryObj.date" type="daterange" style="width:300px;" />
        </div>
        <el-button type="primary" @click="onSearch()">筛选</el-button>
      </div>
      <!-- 表格 -->
      <div class="promotion-table-table">
        <el-table :data="computedDataList" style="width: 100%">
          <template v-for="(item, index) in (listData?.headList || [])" :key="item.dataIndex">
            <el-table-column :label="item.title" :width="item.width || 200" v-if="!item.isTitle">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <span :style="{ color: scope.row[item.dataIndex]?.textProps?.type == 'success' > 0 ? '#52c41a' : ''}">{{ (scope.row[item.dataIndex] || scope.row[item.dataIndex] === 0)
                    ? scope.row[item.dataIndex] : '' }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="item.title" :width="item.width || 200" v-else>
              <template #default="scope">
                <div @click="showDesc(scope.$index, scope.row)" :style="{ color: scope.row?.description?.length > 0 ? '#035dff' : ''}">
                  {{ (scope.row[item.dataIndex] || scope.row[item.dataIndex] === 0)
                    ? scope.row[item.dataIndex] : '' }}
                </div>
              </template>
            </el-table-column>
          </template>
          <!-- <el-table-column label="Name" width="180">
            <template #default="scope">
              <el-popover effect="light" trigger="hover" placement="top" width="auto">
                <template #default>
                  <div>name: {{ scope.row.name }}</div>
                  <div>address: {{ scope.row.address }}</div>
                </template>
                <template #reference>
                  <el-tag>{{ scope.row.name }}</el-tag>
                </template>
              </el-popover>
            </template>
          </el-table-column> -->
        </el-table>
        <div style="width: 100%; display: flex; justify-content: flex-end;padding:12px;">
          <el-pagination v-model:current-page="current" v-model:page-size="pageSize" :page-sizes="[10, 20, 30, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper" :total="computedDataListCount"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>

    <!-- 详细规则弹窗 -->
    <el-dialog v-model="ruleVisible" width="580px" align-center>
      <template #header>
        <div style="font-weight:600;width:100%;text-align: left;">
          奖励规则
        </div>
      </template>
      <div style="width: 100%; line-height: 2;">
        <div style="width:100%;">分享二维码或链接为您本人特有的金智CRM试用二维码，任何渠道通过该二维码或链接注册试用用户，均视为您成功推荐的用户。</div>
        <div style="width:100%;">通过手工添加推荐的信息，推荐用户开通金智CRM试用后即视为推荐成功。</div>
        <div style="width:100%;font-weight:bold;">推荐人权益：</div>
        <div style="width:100%;">最高10%签约额现金奖励，未能签约也可获积分，可兑换礼品或享受续费金额减免优惠。</div>
        <div style="width:100%;font-weight:bold;">被推荐人权益：</div>
        <div style="width:100%;">被推荐者将获得金智CRM推荐折扣券与一对一专属服务包。</div>
        <div style="width:100%;">更多详细规则可咨询您的服务顾问。</div>
      </div>
      <template #footer>
        <div style="justify-content: flex-end;" class="jz-flex">
          <el-button type="primary" @click="ruleVisible = false">我知道了</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 新增用户 -->
    <el-drawer v-model="addVisible" :show-close="false" custom-class="jz-drawer-body-diy" :size="800">
      <template #header>
        <div style="width:100%; justify-content: space-between;" class="jz-flex">
          <div style="font-size:16px ;font-weight: 600; color: rgba(0, 0, 0, 0.85);">诚邀推荐</div>
          <div style="gap:8px;" class="jz-flex">
            <el-button type="primary" @click="onSave">保存</el-button>
            <el-button @click="addVisible = false">取消</el-button>
          </div>
        </div>
      </template>

      <div style="width:100%;">
        <div
          style="width: 100%; padding: 16px; margin-bottom: 10px; line-height: 2; background-color: rgb(255, 255, 255);font-size: 14px;">
          <div style="width: 100%;color:#666;">尊敬的金智CRM用户：</div>
          <div style="width: 100%;color: rgba(0, 0, 0, 0.45);"><span class="">如果您觉得产品不错,请您分享推荐给您的朋友,成为金智CRM推荐达人。</span>
          </div>
          <div style="width: 100%;color:#666;">推荐权益：</div>
          <div style="width: 100%;color: rgba(0, 0, 0, 0.45);"><span
              class="">1.推荐达人获得最高10%签约额现金奖励,被推荐者未能签约也可获得推荐积分,可兑换礼品或折抵续优惠</span>
          </div>
          <div style="width: 100%;color: rgba(0, 0, 0, 0.45);"><span class="">2.被推荐者将获得金智CRM推荐折扣券与一对一专属服务包。</span>
          </div>
        </div>
        <!-- form区域 -->
        <div style="width:100%; margin-top: 10px;background-color: #fff; padding:0 16px 16px 16px; font-size: 14px;">
          <div style="padding: 16px 0px; display: flex; align-items: center;"><span class="jz-left-blue-line"></span>
            <span style="display: inline-block; font-weight: 600; color: rgba(0, 0, 0, 0.88);font-size: 16px;">
              推荐大使信息</span>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;"><span
                style="color:#ff4d4f;">*</span>联系方式</div>
            <el-input v-model="formValue.userPhone" placeholder="请输入"></el-input>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;"><span
                style="color:#ff4d4f;">*</span>怎么称呼</div>
            <el-input v-model="formValue.userName" placeholder="请输入"></el-input>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;">专属顾问</div>
            <el-input v-model="formValue.serviceName" placeholder="请输入"></el-input>
          </div>
          <div style="padding: 16px 0px; display: flex; align-items: center;"><span class="jz-left-blue-line"></span>
            <span
              style="display: inline-block; font-weight: 600; color: rgba(0, 0, 0, 0.88);font-size: 16px;">意向需求用户</span>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;"><span
                style="color:#ff4d4f;">*</span>公司名称</div>
            <el-input v-model="formValue.company" placeholder="请输入"></el-input>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;"><span
                style="color:#ff4d4f;">*</span>联系人</div>
            <el-input v-model="formValue.customerName" placeholder="请输入"></el-input>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;"><span
                style="color:#ff4d4f;">*</span>联系方式</div>
            <el-input v-model="formValue.customerPhone" placeholder="请输入"></el-input>
          </div>
          <div class="jz-flex" style="width:100%; margin-bottom: 32px;">
            <div style="width:120px; display: flex; align-items: center; gap: 4px;">备注<el-tooltip content="请输入软件需求或其他说明"
                placement="">
                <div
                  style="width:14px;height:14px;border-radius: 100px;color: #ccc;border: solid 1px #ccc; font-size: 10px;"
                  class="jz-flex">?</div>
              </el-tooltip></div>
            <el-input v-model="formValue.remark" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>

      <template #footer>
        <div style="width: 100%; justify-content: flex-start; margin-top: 10px;" class="jz-flex">
          <span style="color:rgba(0, 0, 0, 0.45);font-size: 14px;">推荐成功后，服务顾问将与您联系，为您发放推荐奖励</span>
        </div>
      </template>
    </el-drawer>

    <!-- 点击推荐用户的弹窗 -->
    <el-dialog v-model="descVisible" width="580px" align-center>
      <template #header>
        <div style="font-weight:600;width:100%;text-align: left;">
          客户状态
        </div>
      </template>
      <div style="width: 100%;">
        <template v-for="(item, index) in modalDesc" :key="index">
          <div style="width:100%;" class="jz-flex">
            <div style="width: 80px;">{{ item.label || '' }}</div>
            <div style="width: calc(100% - 80px);">{{ (item.value === 0 || item.value) ? item.value : '' }}</div>
          </div>
        </template>
      </div>
      <template #footer>
        <div style="justify-content: flex-end;" class="jz-flex">
          <el-button type="primary" @click="descVisible = false">确定</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { CopyDocument } from '@element-plus/icons-vue'
import { ElMessage, dayjs } from 'element-plus'
import { Requests } from '@/api/index.js'
import QRCode from 'qrcode'

export default {
  name: 'promotion',
  props: {},
  components: { CopyDocument },
  data() {
    return {
      listType: 'setting', // setting table
      userInfo: {},
      ruleVisible: false,
      settingData: {},
      addVisible: false,
      formValue: { userPhone: '', userName: '', serviceName: '', company: '', customerName: '', customerPhone: '', remark: '' },
      queryObj: {
        date: ''
      },
      listData: {},
      keyword: '',
      current: 1,
      pageSize: 10,
      apiBefore: window.location.href.indexOf('10.10.8.37') > -1 ? '' : 'https://crm.jzsoft.cn/pro',
      modalDesc: [],
      descVisible: false
    }
  },
  methods: {
    getData() {
      const that = this
      const params = {
        url: that.apiBefore + '/api/Promotion/GetSeting',
        method: 'GET',
        data: {},
        params: {}
      }
      const result = Requests(params)
      result.then(res => {
        if (res.errcode === 0) {
          that.settingData = res.result
          that.generateQRCode(res.result.promotionUrl)
          that.getUserData()
        } else if (res.errcode === 102) {
          that.$router.push({
            path: '/promotionLogin',
            query: {}
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getTableData() {
      const that = this
      window.axiosHeader = 'application/json'
      const params = {
        url: that.apiBefore + '/api/Promotion/GetListData',
        method: 'POST',
        data: {},
        params: {
          current: that.current,
          pageSize: that.pageSize,
          ...that.queryObj
        }
      }
      if (params.params.date?.length > 0) {
        params.params.date[0] = dayjs(params.params.date[0]).format('YYYY-MM-DD')
        params.params.date[1] = dayjs(params.params.date[1]).format('YYYY-MM-DD')
      }
      for (const key in params.params) {
        if (params.params[key] === '') {
          delete params.params[key]
        }
      }
      const result = Requests(params)
      result.then(res => {
        if (res.errcode === 0) {
          that.listData = res.result
          if (res.result?.queryList?.length > 0 && Object.keys(that.queryObj).length == 1) {
            res.result.queryList.forEach(val => {
              that.queryObj[val.dataIndex] = ''
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toggleList(arg) {
      const that = this
      that.listType = arg
      if (arg == 'table') {
        that.getTableData()
      }
    },
    showRule() {
      const that = this
      that.ruleVisible = true
    },
    generateQRCode(arg) {
      const that = this
      const opts = {
        errorCorrectionLevel: 'H', // 容错级别
        type: 'image/png', // 生成的二维码类型
        quality: 0.3, // 二维码质量
        margin: 1, // 二维码留白边距
        width: 210, // 宽
        height: 210, // 高
        text: arg, // 二维码内容
        color: {
          dark: '#333333', // 前景色
          light: '#fff'// 背景色
        }
      }
      const msg = document.getElementById('qrcode_canvas')
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, arg, opts, function (error) {
        console.log(error)
      })
    },
    downloadQRCode() {
      const that = this
      const canvas = document.getElementById('qrcode_canvas')
      if (canvas) {
        const url = canvas.toDataURL()
        const aEle = document.createElement('a')
        aEle.download = `${that.userInfo.userName || ''}推广拉新二维码.png`
        aEle.href = url
        document.body.appendChild(aEle)
        aEle.click()
        document.body.removeChild(aEle)
      }
    },
    downloadImage() {
      const that = this
      const imgsrc = that.settingData.posterDownLoadUrl || ''
      const name = that.userInfo.userName || ''
      const image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        const url = canvas.toDataURL('image/png', 0.7) // 得到图片的base64编码数据
        const a = document.createElement('a') // 生成一个a元素
        const event = new MouseEvent('click') // 创建一个单击事件
        a.download = name ? name + '.png' : 'photo.png' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
    showAdd() {
      const that = this
      const tempData = { userPhone: that.userInfo.userPhone || '', userName: that.userInfo.userName || '', serviceName: '', company: '', customerName: '', customerPhone: '', remark: '' }
      that.addVisible = true
      that.formValue = tempData
    },
    onSave() {
      const that = this
      window.axiosHeader = 'application/json'
      const params = {
        url: that.apiBefore + '/api/Promotion/AddCustomer',
        method: 'POST',
        data: { ac: 'save', msgid: -new Date().getTime() % 100000000, opType: 'add' },
        params: {
          values: { ...that.formValue }
        }
      }
      if (!(params.params.values.userPhone && /^1[123456789][0-9]\d{8}$/.test(params.params.values.userPhone))) {
        ElMessage.error('请输入正确的联系方式')
        return false
      }
      if (!params.params.values.userName) {
        ElMessage.error('请输入正确的姓名')
        return false
      }
      if (!params.params.values.company) {
        ElMessage.error('请输入正确的公司名称')
        return false
      }
      if (!params.params.values.customerName) {
        ElMessage.error('请输入正确的联系人姓名')
        return false
      }
      if (!(params.params.values.customerPhone && /^1[123456789][0-9]\d{8}$/.test(params.params.values.customerPhone))) {
        ElMessage.error('请输入正确的联系方式')
        return false
      }
      const result = Requests(params)
      result.then(res => {
        if (res.errcode === 0) {
          ElMessage.success('保存成功')
          that.addVisible = false
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getUserData() {
      const that = this
      const params = {
        url: that.apiBefore + '/api/Promotion/CurrentAgent',
        method: 'GET',
        data: {},
        params: {}
      }
      const result = Requests(params)
      result.then(res => {
        if (res.errcode === 0) {
          that.userInfo = res.result
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleSizeChange(arg) {
      console.log(arg)
      const that = this
    },
    handleCurrentChange(arg) {
      console.log(arg)
      const that = this
    },
    showDesc(aInd, aItem) {
      const that = this
      if (aItem?.description?.length > 0) {
        that.modalDesc = aItem.description
        that.descVisible = true
      }
    },
    jzCopy(domId, textType = 'text') {
      const copyDom = document.getElementById(domId)
      if (copyDom?.[textType == 'html' ? 'innerHTML' : 'innerText']) {
        const range = document.createRange()
        window.getSelection().removeAllRanges()
        range.selectNode(copyDom)
        window.getSelection().addRange(range)
        const successful = document.execCommand('copy')
        if (successful) {
          ElMessage.success('复制成功')
        } else {
          ElMessage.warning('复制失败，请手动复制！')
        }
      }
    },
    onSearch() {
      const that = this
      that.current = 1
      that.getTableData()
    }
  },
  mounted() {
    const that = this
    that.getData()
  },
  computed: {
    computedDataList() {
      const that = this
      let result = this.listData?.dataList || []
      result = result.filter(val => {
        const searchField = (this.listData?.headList || []).filter(hVal => hVal.canSearch)
        if (that.keyword) {
          return searchField.find(sVal => String(val[sVal.dataIndex]).indexOf(that.keyword) > -1)
        }
        return true
      })
      result = result.splice((that.current - 1) * that.pageSize, that.pageSize)

      return result
    },
    computedDataListCount() {
      const that = this
      let result = this.listData?.dataList || []
      result = result.filter(val => {
        const searchField = (this.listData?.headList || []).filter(hVal => hVal.canSearch)
        if (that.keyword) {
          return searchField.find(sVal => String(val[sVal.dataIndex]).indexOf(that.keyword) > -1)
        }
        return true
      })

      return result.length
    }
  }
}
</script>

<style scoped lang="scss">
.promotion {
  width: 100%;
  background-color: #f7f9fc;
  height: 100vh;
  min-width: 1100px;

  &-head {
    width: 100%;
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;

    &-logo {
      width: 203px;
      height: 100%;
    }

    &-tab {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 200px;
      height: 100%;

      &-item {
        height: calc(100% - 10px);
        border-bottom: solid 3px #fff;
        color: #666;
      }

      &-item-act {
        font-weight: bold;
        border-color: #035dff;
      }

    }

    &-info {
      width: calc(100% - 403px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      padding-right: 16px;
    }
  }

  &-setting {
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 10px;
    background-color: #fff;
    min-width: 1100px;
    overflow: auto;
    padding: 10px;
  }

  &-table {
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 10px;
    background-color: #fff;
    min-width: 1100px;
    overflow: auto;
    padding: 10px;

    &-stat {
      width: 100%;
      background-color: #fff;
      padding: 10px;
    }

    &-query {
      width: 100%;
      flex-wrap: wrap;
      gap: 12px;
      padding: 10px;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

// 统计数据

.pBgSmall {
  width: 338px;
  height: 246px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 6px;
}

.pBgMiddle {
  width: 375px;
  height: 567px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 6px;
}

.pStatDataSettled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(33.33% - 7px);
  height: 184px;
  padding: 12px;
  background-color: rgb(255, 245, 235);
  border: solid 1px rgb(255, 200, 148);
  border-radius: 6px;
  transition: all 0.2s;
}

.pStatDataSettled:hover {
  background-color: rgb(255, 200, 148);
}

.pStatDataUnSettled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(33.33% - 7px);
  height: 184px;
  padding: 12px;
  background-color: rgb(207, 222, 254);
  border: solid 1px rgb(151, 182, 255);
  border-radius: 6px;
  transition: all 0.2s;
}

.pStatDataUnSettled:hover {
  background-color: rgb(151, 182, 255);
}

.pStatDataNotSettled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(33.33% - 7px);
  height: 184px;
  padding: 12px;
  background-color: rgb(234, 234, 234);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  transition: all 0.2s;
}

.pStatDataNotSettled:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

// 统计数据结束
</style>
