<!--
 * @Description:页脚
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 09:48:01
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 09:42:53
-->
<template>
  <div v-if="isShow" :class="nameTyle == 'Trial' ? 'hidden-xs-only' : ''">
    <div class="footer container-fluid">
      <div class="footer-content container">
        <JzIcon class="footer-content-logo jz-hv hidden-xs-only" :icon="logo" :iStyle="{}" style="width:155px;height:36px">
        </JzIcon>
        <div class="footer-content-menu" v-for="item in footerInfo" :key="item.title">
          <span class="footer-content-menu-title">
            {{ item.title }}
          </span>
          <div class="footer-content-menu-dataList">
            <span class="footer-content-menu-dataList-item jz-hv" v-for="it in item.children" :key="it.title" @click="foorerClick(it)">
              {{ it.title }}
            </span>
          </div>
        </div>
        <div class="footer-content-upload hidden-xs-only">
          <span class="footer-content-upload-title">应用下载</span>
          <div class="footer-content-upload-img">
            <el-image :lazy="true" src="/image/upload.png"></el-image>
          </div>
          <div style="width: 90px;font-size: 12px;margin-top: 8px;text-align: center;margin-left: 5px;color: #666666;">扫码下载安卓版金智CRM App</div>
        </div>
      </div>
    </div>
    <div class="end  container-fluid">
      <div class="end-box  container">
        <span>Copyright © 2002-2021 © Jzsoft.Cn All Rights Reserved. 青岛金智电子科技有限公司</span>
        <span class="end-box-all">版权所有</span>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37020202000473" target="blank">鲁ICP备10018704号-8 公安局备案:备37020202000473号</a>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref, watch, getCurrentInstance } from 'vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { useRouter } from 'vue-router'
export default {
  components: { JzIcon },
  name: 'JzFooter',
  props: {
    onCallBack: {
      type: Function,
      default: () => { }
    }
  },
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const $router = useRouter()
    const $store = useStore()
    const hindArr = ref(['View'])
    const nameTyle = ref('')
    // 左侧logo
    const logo = computed(() => {
      return $store.state.logoIcon.colorIcon
    })
    const footerInfo = computed(() => {
      return $store.state.footer
    })
    const isShow = computed(() => {
      nameTyle.value = $router.currentRoute.value.name
      return !hindArr.value.includes($router.currentRoute.value.name)
    })
    // 点击跳转
    const foorerClick = (item) => {
      if (item.extra) {
        const routerInfo = $router.currentRoute.value.query
        if (item.query && item.query.info && routerInfo && routerInfo.info == item.query.info) {
        } else {
          $router.push({
            path: `${item.path}`,
            query: item.query
          })
        }
      } else {
        $router.push({
          path: `${item.path}`,
          query: {
            ...item.query
          }
        })
      }
    }
    return {
      logo,
      footerInfo,
      foorerClick,
      isShow,
      nameTyle
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  background-color: rgba(247, 250, 255, 1);
  border-top: 1px solid #e8edf4;
  border-bottom: 1px solid #e8edf4;
  // background-color: rgba($color: #000000, $alpha: 1.0);

  &-content {
    height: 445px;
    display: flex;
    justify-content: space-between;
    padding: 50px 24px 40px 24px;

    &-logo {
      width: 100%;
      height: 100%;
    }

    &-menu {
      display: flex;
      flex-direction: column;

      &-title {
        padding: 0px 0px 8px 0px;
        font-size: 20px;
      }

      &-dataList {
        display: flex;
        flex-direction: column;

        &-item {
          padding: 8px 0px;
          font-size: 14px;
          color: rgba(31, 34, 40, 0.9);
        }

        &-item:hover {
          color: var(--el-color-primary);
        }
      }
    }

    //
    &-upload {
      &-title {
        font-size: 20px;
        color: #1f2228fe;
      }

      &-img {
        margin-top: 12px;
        width: 98px;
        height: 98px;
        padding: 4px;
        border-radius: 6px;
        background-color: #ffffff;
        box-shadow: 0px 5px 5px rgba(95, 123, 178, 0.2);
      }
    }
  }
}

.end {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #1f2228fe;
  background-color: rgba(247, 250, 255, 1);
  text-align: center;

  &-box {
    line-height: 24px;
    padding: 20px 0px;
    // width: 90%;
    // margin-left: 5%;

    &-all {
      margin: 0px 8px;
    }

    a {
      color: #1f2228fe;
    }
  }
}

@media (max-width: 767px) {
  .footer {
    &-content {
      padding: 8px 0px 0px 24px;
      height: 100%;
      flex-direction: column;

      &-menu {
        &-dataList {
          flex-direction: row;
          flex-wrap: wrap;

          &-item {
            min-width: 25%;
          }
        }
      }
    }
  }

  .footer-content-menu{
    margin-top: 16px;
  }
}
</style>
