/*
 * @Description:封装
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-10 15:25:20
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-07-28 16:08:33
 */

import axios from 'axios'
import { ElMessage } from 'element-plus'
axios.defaults.withCredentials = true
const PATHS = window.PATHS || ''
const requests = axios.create({
  baseURL: `${PATHS}`,
  timeout: 5000
})
requests.interceptors.request.use((config) => {
  config.headers['Content-Type'] = window.axiosHeader || 'application/x-www-form-urlencoded';//'application/json'// 'application/x-www-form-urlencoded';
  return config
})
// 响应拦截器
requests.interceptors.response.use((res) => {
  window.axiosHeader = ''
  if (res.data.errcode == 0) {
    return res.data
  } else if (res.data.errcode && res.data.errcode != 0) {
    ElMessage(res.data.errmsg)
    return res.data
  } else {
    return res.data
  }
}, (error) => {
  return Promise.reject(new Error('faile'))
})
// 对外暴露
export default requests
