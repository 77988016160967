<!--
 * @Description:Vertical组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 15:44:52
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-07 18:32:19
-->
<template>
  <div class="vertical" :style="{ ...itemSet.style }">
    <div class="vertical-label">{{ itemSet.label }}</div>
    <div class="vertical-value">{{ itemSet.value }}</div>
  </div>
</template>

<script>
export default {
  name: 'Vertical',
  props: {
    itemSet: {
      type: [Array, Object]
    }
  },
  setup (props, context) {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-label {
    font-size: 40px;
    padding: 8px 16px;
  }

  &-value {
    font-size: 20px;
    padding: 8px 16px;
  }
}
</style>
