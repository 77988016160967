<!--
 * @Description:企微
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2023-06-16 08:37:12
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-06-17 09:28:49
-->
<template>
  <div class="info">
    <!-- 顶部展示数据 -->
    <div class="container-fluid headBox">
      <div class="backgroundImg" style="min-height: 300px;max-height: 480px;overflow: hidden;">
        <img src="/image/other/otherBack.png" style="width: 100%;height:100%;object-fit: fill;min-height: 300px;" fit="cover" />
        <div class="container-fluid content">
          <div class="container">
            <div class="contentInfo">
              <div class="left">
                <div class="headMainImg">
                  <el-image :src="totalData.mainImg"></el-image>
                </div>
                <div class="title">
                  全流程一站式解决方案
                </div>
                <div class="subTitle">
                  多业务流程一站式解决方案，业务全周期信息化赋能，提升企业盈利能力
                </div>
                <div class="mainBtn hidden-sm-and-down">
                  <!-- <div v-for="item in totalData.btnList" class="mainBtn-item" @click="handClick(item)"> -->
                  <!-- <el-image :src="item.lg" class="hidden-sm-and-down"></el-image> -->
                  <!-- <el-image :src="item.sm"></el-image> -->
                  <img :src="item.sm" v-for="item in totalData.btnList" class="mainBtn-item" @click="handClick(item)">
                  <!-- </div> -->
                </div>
              </div>
              <div class="right">
                <el-image src="/image/other/backRight.png"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid info-head" :style="{ 'background-color': index % 2 === 0 ? 'rgba(247,250,255,1)' : '' }" v-for="(item, index) in itemInfo.dataList" :key="item.value" v-if="tabActive != 116 && msgid != 116">
      <div class="container info-head-content">
        <JzLabel :itemSet="{ ...item, index: index }"></JzLabel>
        <div class="footerNext" v-if="(index + 1 != itemInfo.dataList.length) && (tabActive != '116' && msgid != 116)">
          <JzNext></JzNext>
        </div>
      </div>
    </div>
    <div class="container-fluid info-head api" :style="{ 'background-color': index % 2 === 1 ? 'rgba(247,250,255,1)' : '' }" v-for="(item, index) in itemInfo.dataList" :key="item.value" v-if="tabActive == 116 || msgid == 116">
      <JzLabel :itemSet="{ ...item, index: index }"></JzLabel>
      <div class="footerNext hidden-sm-and-up" v-if="(index + 1 != itemInfo.dataList.length) ">
        <JzNext></JzNext>
      </div>
      <div class=" hidden-sm-and-up" style="height:30px"></div>
    </div>
  </div>
</template>

<script>
import { Requests } from '@/api/index.js'
import { onMounted, ref, watch, computed, reactive, h } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import JzLabel from '@/components/JzLabel/JzLabel.vue'
import JzNext from '@/components/JzNext/JzNext.vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { ElDivider } from 'element-plus'
export default {
  components: { JzLabel, JzNext, JzIcon },
  setup(props, content) {
    const $router = useRouter()
    const $store = useStore()
    const type = ref('');
    const totalData = ref('');
    const loading = ref(false);
    const getData = async () => {
      let params = {
        url: 'https://crm.jzsoft.cn/pro/api/user/RegisterCompany',
        method: 'GET',
        data: {
          company: forms.formValue.enterprise,
          linkman: forms.formValue.name,
          tel: forms.formValue.number,
          code: forms.formValue.code,
          codeType: 'register',
        },
        params: {},
      }
      const res = await Requests(params)
    }

    const itemInfo = computed(() => {
      let info = {}
      $store.state.info.filter((val, index) => {
        if (index == 0) {
          info = val;
        }
      })
      return info
    })
    const getInfo = async (aParams, aData) => {
      if (loading.value) {
        return;
      }
      let params = {
        // url: 'https://crm.jzsoft.cn/pro/api/DingDingApi/GetPreAuthCode',
        url: '',
        method: 'get',
        data: {
          ...aParams,
        },
        params: {
          ...aData,
        },
      }
      if (params.data.isvType == 'wechat') {
        params['url'] = 'https://weixin.jzsoft.cn/pro/api/DingDingApi/getprovidecode';
      }
      if (params.data.type == 'install') {
        params.data.type = 'pre_auth';
      }
      loading.value = true;
      const res = await Requests(params);
      loading.value = false;
      if (res.errcode == 0) {
        if (params.data.isvType == 'wechat') {
          if (params.data.type == 'register') {
            window.open(`https://open.work.weixin.qq.com/3rdservice/wework/register?register_code=${res.result.code}`);
          } else if (params.data.type == 'pre_auth') {
            window.open(`https://open.work.weixin.qq.com/3rdapp/install?suite_id=${$store.state.appConfig.SUITE_ID}&pre_auth_code=${res.result.code}&redirect_uri=${encodeURIComponent($store.state.appConfig.login[params.data.isvType])}&state=''`);
          }
        }
      }
    }
    const handClick = (e) => {
      if (e.type == 'login') {
        window.open(`${$store.state.appConfig.login[type.value]}`);
      } else if (e.type == "install") {
        // 安装
        getInfo({ isvType: type.value, type: e.type })
      } else if (e.type == 'register') {
        // 注册
        if (type.value == 'wechat') {
          getInfo({ isvType: type.value, type: e.type })
        } else if (type.value == 'ding') {
        } else if (type.value == 'feishu') {
        }
      }
    }
    onMounted(() => {
      type.value = $router.currentRoute.value.query.type || '';
      totalData.value = $store.state.other[type.value];
    })
    return {
      itemInfo,
      type,
      totalData,
      handClick,
    }
  },
}
</script>


<style scoped lang="scss">
.tabActive::after {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 0px;
  width: calc(100% - 48px);
  background-color: var(--el-color-primary);
}

.info {
  padding-top: 68px;
  ::v-deep(.el-scrollbar) {
    overflow: inherit;
  }
  ::v-deep(.el-scrollbar__bar) {
    bottom: -8px;
  }

  &-tabList {
    position: sticky;
    top: 43px;
    z-index: 999;
    background: #ffffff;
    box-shadow: rgba(95, 123, 178, 0.3) 0px 6px 6px 0px;

    &-box {
      display: flex;
      flex-wrap: nowrap;
      height: 50px;

      &-item {
        // width: 100%;
        position: relative;
        padding: 0px 24px;
        flex-shrink: 0;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-head {
    &-content {
      padding: 100px 0px;
      position: relative;
    }

    &-box {
      height: 642px;
      padding: 80px 0px;
      position: relative;

      &-title {
        padding: 8px 16px;
        font-size: 40px;
        text-align: center;
        color: #1f2228e5;
        width: 80%;
        margin-left: 10%;
        font-weight: 700;
      }

      &-content {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        &-item {
          width: 209px;
          text-align: center;

          &-image {
            width: 70px;
            height: 100px;
            margin-left: calc(50% - 35px);
          }

          &-title {
            margin-top: 10px;
            // text-align: left;
            color: #1f2228d8;
            font-size: 20px;
            padding: 8px 16px;
          }

          &-subtitle {
            margin-top: 4px;
            font-size: 14px;
            color: #1f2228a5;
            text-align: left;
            padding: 8px 16px;
          }
        }
      }
    }
  }
}

.footerNext {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

.tabList {
  position: sticky;
  top: 43px;
  z-index: 999;
  background-color: #ffffff;
}
.api {
  position: relative;
  padding: 60px 0px;
}
// 顶部数据
.headBox {
  position: relative;
  padding: 0px;
  .backgroundImg {
    position: relative;
    width: 100%;
  }
  .content {
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    height: 100%;
    .contentInfo {
      // padding: 0px 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .left {
        flex: 2;
        font-size: 44px;
        color: #ffffff;
        font-weight: 600;
        .headMainImg {
          max-width: 600px;
        }
        .title {
          font-size: 44px;
          color: #000000;
          font-weight: 600;
          margin-top: 24px;
        }
        .subTitle {
          font-size: 22px;
          color: #8c8c8c;
          margin-top: 8px;
          font-weight: normal;
        }
        // 按钮
        .mainBtn {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 36px;
          padding-right: 48px;
          &-item {
            cursor: pointer;
            // height: 60px;
            border-radius: 4px;
            overflow: hidden;
          }
          &-item:hover {
            box-shadow: 0px 0px 2px 4px #035dff30;
          }
        }
      }
      .right {
        flex: 1;
        width: 100%;
        text-align: right;
        margin-left: 86px;
      }
    }
  }
}
// 底部按钮
.endStyle {
  padding: 100px 0px;
  .headMainImg {
  }
  .title {
    font-size: 36px;
    width: 100%;
    text-align: center;
    color: #8c8c8c;
  }
}

// 媒体查询
@media (max-width: 767px) {
  ::v-deep(.isScroll) {
    box-shadow: none;
  }

  .info {
    padding-top: 43px;
    position: relative;

    ::v-deep(.el-tabs__header) {
      margin: 0px;
    }

    &-head {
      &-box {
        height: 100%;
        padding: 16px 0px 80px 0px;

        &-title {
          font-size: 24px;
          width: 100%;
          margin: 0px;
          text-align: left;
        }

        &-content {
          margin-top: 30px;
          flex-direction: column;

          &-item {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &-image {
              width: 100px;
              // padding: 0px 24px;
              flex-shrink: 0;
              padding-top: 20px;
              margin-left: 0%;

              ::v-deep(.el-image) {
                // width: 50px !important;
                height: 50px !important;
              }
            }

            &-right {
              margin-top: 0px !important;
            }

            &-title {
              text-align: left;
            }

            &-subtitle {
              padding-right: 24px;
              padding-top: 0px;
            }
          }
        }
      }

      &-content {
        padding: 24px 0px 60px 0px;
      }
    }
  }

  // 顶部数据

  .headBox {
    .backgroundImg {
    }
    .content {
      .contentInfo {
        .left {
          .headMainImg {
            height: 46px;
            width: 100%;
            overflow: hidden;
            margin-top: 24px;
          }
          .title {
            margin-top: 24px;
            font-size: 22px;
            width: 100%;
            text-align: center;
          }
          .subTitle {
            font-size: 16px;
            width: 100%;
            text-align: center;
          }
          // 按钮
          .mainBtn {
            padding-right: 0px;
            &-item {
            }
            &-item:hover {
            }
          }
        }
        .right {
          display: none;
        }
      }
    }
  }
  // 底部按钮
  .endStyle {
    padding: 40px 0px 60px 0px;
    .title {
      font-size: 20px;
    }
    .mainBtn {
      &-item {
        // height: 40px;
      }
      &-item:hover {
        box-shadow: none;
      }
    }
  }
  .api {
    position: relative;
    padding: 30px 0px;
  }
}
@media (min-width: 767px) and (max-width: 992px) {
  .headBox {
    .backgroundImg {
    }
    .content {
      .contentInfo {
        .left {
          .headMainImg {
          }
          .title {
            font-size: 38px;
          }
          .subTitle {
          }
          // 按钮
          .mainBtn {
            &-item {
            }
            &-item:hover {
            }
          }
        }
        .right {
        }
      }
    }
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .headBox {
    .backgroundImg {
    }
    .content {
      .contentInfo {
        .left {
          .headMainImg {
          }
          .title {
          }
          .subTitle {
          }
          // 按钮
          .mainBtn {
            &-item {
            }
            &-item:hover {
            }
          }
        }
        .right {
          margin-left: 12px;
        }
      }
    }
  }
}
@media (min-width: 1201px) and (max-width: 1400px) {
  // 顶部数据

  .headBox {
    .backgroundImg {
    }
    .content {
      .contentInfo {
        .left {
          .headMainImg {
          }
          .title {
          }
          .subTitle {
            font-size: 20px;
          }
          // 按钮
          .mainBtn {
            &-item {
            }
            &-item:hover {
            }
          }
        }
        .right {
        }
      }
    }
  }
}
</style>

