<!--
 * @Description:方案页面
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 20:35:49
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 19:09:15
-->
<template>
  <div v-if="(msgid == 0)">
    <div class="container-fluid programme">
      <div class="container programme-box">
        <div class="programme-box-left">
          <div class="programme-box-left-title">
            金智CRM多行业解决方案
          </div>
          <div class="programme-box-left-subtitle">
            金智CRM针对各行业务特性，打造以人为本、数据驱动的专业行业解决方案，为业务特性提供新工作方式。
          </div>
        </div>
        <div class="programme-box-right">
          <el-image :lazy="true" src="/image/programme/banner.svg"></el-image>
        </div>
      </div>
    </div>
    <div class="container-fluid list">
      <div class="container list-box">
        <!-- <el-tabs v-model="tabActive">
          <el-tab-pane :label="item.label" :name="(index + 1)" v-for="(item, index) in programmeInfo.tabList"
            :key="item.value"></el-tab-pane>
        </el-tabs> -->
        <el-scrollbar style="min-weight:100%;border-bottom: 1px solid #e5e5e5;">
          <div class="list-box-scrollbar">
            <div class="list-box-scrollbar-item" @click="tabChange(index)" :class="{ tabActive: tabActive == index }" v-for="(item, index) in programmeInfo.tabList">{{
                  item.label
              }}</div>
          </div>
        </el-scrollbar>
        <div class="list-box-list">
          <JzList :dataList="programmeInfo.list.filter((val) => {
            if (val.tag.value == -1 || tabActive == val.tag.value || tabActive == 0) {
              return val;
            } else {
              return false;
            }
          })"></JzList>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="container-fluid info">
      <el-image :lazy="true" class="info-image" src="/image/programme/itemBack.png"></el-image>
      <div class="container info-box">
        <div class="info-box-title">
          业务全流程CRM {{ itemInfo.title }}
        </div>
        <div class="info-box-subtitle">
          {{ itemInfo.subtitle }}
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container items">
        <div class="items-title">
          {{ itemInfo.soft.name }}
        </div>
        <div class="items-content">
          {{ itemInfo.soft.content }}
        </div>
      </div>
      <div class="container items hexin" v-if="(itemInfo.tagList)">
        <div class="items-title">
          核心功能
        </div>
        <div class="items-tagList">
          <div v-for="item in itemInfo.tagList" :key="item.label" style="width:25%;display:flex;justify-content: center;margin-bottom:24px">
            <div class="items-tagList-item hidden-sm-and-up">
              <el-image :lazy="true" style="width: 24px;height:24px;" :src="item.value"></el-image>
              <span class="items-tagList-item-label" style="margin-top:18px">{{ item.label }}</span>
            </div>
            <div class="items-tagList-item hidden-xs-only" >
              <el-image :lazy="true" style="width: 40px;height:40px;" :src="item.value"></el-image>
              <span class="items-tagList-item-label">{{ item.label }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container items jiejue" v-if="(itemInfo.other)">
        <div class="items-title">
          {{itemInfo.other.title}}
        </div>
        <div class="items-tagList">
          <div class="items-tagList-item jiejue-item" v-for="(item, index) in itemInfo.other.data" :key="item.label">
            <el-card shadow="hover" class="card" :body-style="{ padding: '0px' }">
              <div class="card-num">0{{ (index + 1) }}</div>
              <div class="card-titles">{{ item.title }}</div>
              <div class="card-content">{{ item.content }}</div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="container-fluid footer">
      <div class="container footer-box">
        <div class="footer-box-left">
          <div class="footer-box-left-title">
            CRM + 进销存 + 生产
          </div>
          <div class="footer-box-left-subtitle">
            全面整合企业信息化资源!
          </div>
        </div>
        <div class="footer-box-fight">
          <el-image :lazy="true" src="/image/programme/banner.svg"></el-image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JzList from '@/components/JzList/JzList.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: { JzList },
  name: 'programme',
  props: {},
  setup(props, context) {
    const $router = useRouter()
    const $store = useStore()
    const tabActive = ref(0)
    const msgid = ref(0)
    const itemInfo = ref({})
    //
    const tabChange = (val) => {
      tabActive.value = val
    }
    const programmeInfo = computed(() => {
      return $store.state.programme
    })
    onMounted(() => {
      const info = $router.currentRoute.value
      msgid.value = 0
      if (info.query.msgid) {
        msgid.value = info.msgid
        $store.state.programme.list.filter((val) => {
          if (val.key == info.query.msgid) {
            itemInfo.value = val
          }
        })
      } else if (info.query.tabid) {
        tabActive.value = parseInt(info.query.tabid)
      }
    })
    return {
      programmeInfo,
      tabActive,
      tabChange,
      msgid,
      itemInfo
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.programme {
  background-color: rgba(247, 250, 255, 0.9);

  &-box {
    height: 470px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-left {
      &-title {
        padding: 8px 16px;
        font-size: 36px;
        font-weight: 700;

        height: 70px;
        overflow: hidden;
        // 显示动画
        animation-name: showTitle;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-direction: none;
      }

      &-subtitle {
        width: 614px;
        font-size: 18px;
        color: rgba(31, 34, 40, 0.9);
        padding: 8px 16px;

        height: 70px;
        overflow: hidden;
        // 显示动画
        animation-name: showTitle;
        animation-duration: 0.35s;
        animation-timing-function: ease;
        animation-direction: none;
      }
    }
  }
}

.list {
  &-box {
    padding: 40px 0px;

    ::v-deep(.el-scrollbar) {
      overflow: inherit;
    }
    ::v-deep(.el-scrollbar__bar){
      bottom: -8px;
    }

    &-scrollbar {
      display: flex;
      width: 100%;
      min-width: 100%;

      &-item {
        position: relative;
        font-size: 14px;
        color: #1f2228d8;
        width: 88px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin: 0px 10px;
        cursor: pointer;
        flex-shrink: 0;
      }

      .tabActive::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background-color: var(--el-color-primary);
      }
    }
  }
}

.info {
  position: relative;
  height: 400px;

  &-image {
    position: absolute;
    width: 100%;
    height: 400px;
    top: 0px;
    left: 0px;
  }

  &-box {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    &-title {
      padding: 8px 16px;
      font-size: 36px;
    }

    &-subtitle {
      padding: 8px 16px;
    }
  }
}

.items {
  height: 100%;
  position: relative;
  width: 100%;
  padding: 40px 0px 20px 0px;

  &-title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    color: #1f2228d8;
    padding: 8px 16px;
  }

  &-content {
    margin: 24px 0px;
    font-size: 16px;
    color: #1f2228d8;
    line-height: 32px;
    text-align: center;
  }

  // 核心功能
  &-tagList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
    // margin-left: 10%;
    margin: 24px 0px 24px 10%;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      background-color: rgba(247, 250, 255, 1);

      &-label {
        font-size: 14px;
        padding: 8px 16px;
        color: #1f2228d8;
      }
    }
  }

  // 解决方案
  .jiejue {
    margin-top: 48px;

    &-item {
      height: 100%;
    }
  }

  .card {
    border: none;
    border-radius: 8px;
    width: 208px;
    height: 237px;

    &-num {
      font-size: 20px;
      color: #1f2228d8;
      padding: 4px 16px;
    }

    &-titles {
      font-size: 20px;
      color: #1f2228d8;
      padding: 4px 16px;
    }

    &-content {
      padding: 4px 16px;
      font-size: 14px;
      color: #1f2228a5;
      line-height: 28px;
    }
  }
}

// 底部
.footer {
  background-color: rgba(239, 243, 255, 1);

  &-box {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-left {
      color: #333333;
      font-size: 28px;

      &-title {
        font-weight: 560;
      }

      &-subtitle {
      }
    }

    &-fight {
      padding: 8px;
      max-width: 220px;
    }
  }
}

// 动画
@keyframes showTitle {
  0% {
    padding-top: 70px;
  }

  100% {
  }
}

//媒体查询
@media (max-width: 767px) {
  .programme {
    background-color: transparent;
    margin-top: 60px;

    &-box {
      height: 100%;
      padding: 8px;
      background-color: rgba(247, 250, 255, 0.9);

      &-left {
        &-title {
          font-size: 24px;
          height: 74px;
        }

        &-subtitle {
          width: 100%;
          font-size: 12px;
        }
      }

      &-right {
        width: 100%;
      }
    }
  }
  .items-tagList-item-label{
    padding: 0px 8px !important;
  }

  ::v-deep(.list) {
    padding: 0px;
  }

  .info {
    height: 184px;
    margin-top: 60px;
    width: 95%;
    margin-left: 2.5%;
    border-radius: 16px;
    overflow: hidden;

    &-box {
      align-items: flex-start;
      padding: 0px;

      &-title {
        width: 60%;
        font-size: 24px;
      }

      &-subtitle {
        font-size: 12px;
      }
    }

    &-image {
      height: 184px;
    }
  }

  .hexin {
    padding-top: 0px;

    .items-tagList {
      width: 100%;
      flex-wrap: wrap;
      margin: 0px;
      margin-top: 24px;

      &-item {
        width: 90%;
        margin-bottom: 24px;
      }
    }
  }

  .jiejue {
    padding-top: 0px;

    .items-tagList {
      width: 100%;
      flex-wrap: wrap;
      margin: 0px;
      margin-top: 24px;

      &-item {
        width: 100%;
        margin-bottom: 24px;
        background-color: transparent;
        box-shadow: 0px 4px 23px rgba(95, 123, 178, 0.08);

        .card {
          width: 100%;
          height: 100%;
          padding-bottom: 24px;
          border-radius: 16px;
        }
      }
    }
  }

  .footer {
    &-box {
      height: 225px;

      &-left {
        &-title {
          font-size: 20px;
        }

        &-subtitle {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
