<!--
 * @Description:IconHtml 组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 18:18:53
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-06-16 14:51:16
-->
<template>
  <div class="iconHtml" :style="{cursor:itemSet.type?'pointer':''}" @click="showInfo(itemSet)">
    <div class="iconHtml-icon">
      <JzIcon :icon="itemSet.icon" :iStyle="{ 'font-size': '80px' }" v-if="itemSet.icon"></JzIcon>
      <el-image :lazy="true" :src="itemSet.image" v-else-if="itemSet.image"></el-image>
    </div>
    <div class="iconHtml-title">{{ itemSet.title }}</div>
    <div class="iconHtml-subtitle" v-html="itemSet.subtitle"></div>
  </div>
</template>

<script>
import JzIcon from '@/components/JzIcon/JzIcon.vue'
export default {
  components: { JzIcon },
  name: 'IconHtml',
  props: {
    itemSet: {
      type: [Array, Object]
    }
  },
  methods: {
    showInfo(e) {
      if (e.type) {
        this.$router.push({
          path: '/other',
          query: {
            type: e.type
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.iconHtml {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-icon {
    width: 80px;
    height: 80px;
  }

  &-title {
    padding: 8px 16px;
    font-size: 20px;
    color: #1f2228e5;
    margin-top: 16px;
  }

  &-subtitle {
    color: #1f222899;
  }
}
</style>
