<!--
 * @Description:Tag
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 19:58:18
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-12 20:06:42
-->
<template>
  <el-tag style="border:none" :effect="itemSet.effect" :type="itemSet.type" :color="itemSet.color" :hit="false">{{
      itemSet.label
  }}</el-tag>
</template>

<script>
export default {
  name: 'tag',
  props: {
    itemSet: {
      type: [Array, Object]
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.tag {
  // width: 100%;
}
</style>
