<!--
 * @Description:Level组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 15:16:58
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-12 14:25:10
-->
<template>
  <div class="level">
    <div class="level-left">
      <el-image :lazy="true" style="width:100%;height:100%" fit="contain" :src="itemSet.image"></el-image>
    </div>
    <div class="level-right">
      <div class="level-right-item" v-for="(item, index) in itemSet.dataList" :key="item.title">
        <div class="level-right-item-left hidden-sm-and-up">
          <JzIcon icon="icon-text" :iStyle="{ 'font-size': '22px', 'color': colorArr[index % colorArr.length] }">
          </JzIcon>
        </div>
        <div class="level-right-item-right">
          <div class="level-right-item-right-title">{{ item.title }}</div>
          <div class="level-right-item-right-subtitle">{{ item.subtitle }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { ref } from 'vue'
export default {
  components: {
    JzIcon
  },
  name: 'Level',
  props: {
    itemSet: {
      type: [Array, Object]
    }
  },
  setup (props, context) {
    const colorArr = ref(['#31c493', '#ffca5a', '#035dff'])
    return {
      colorArr
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.level {
  display: flex;
  justify-content: center;

  &-left {
    width: 344px;
    height: 279px;
    flex-shrink: 0;
  }

  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-item {
      margin-left: 120px;
      display: flex;

      &-left {
        margin-top: 10px;
      }

      &-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-title {
          padding: 8px 16px;
          font-size: 20px;
          color: #1f2228d8;
        }

        &-subtitle {
          padding: 8px 16px;
          font-size: 16px;
          color: #1f3d78d8;
        }
      }
    }
  }
}

//媒体查询
@media(max-width:767px) {
  .level {
    flex-direction: column;

    &-left {
      width: 100%;
      padding: 0px 40px;
    }

    &-right {
      &-item {
        margin-bottom: 24px;
        padding: 16px;
        margin-left: 0px;
        border-radius: 8px;
        box-shadow: 0px 8px 20px rgba(95, 123, 178, 0.1);
      }
    }
  }
}

@media(min-width:768px) and (max-width:992px) {}

@media(min-width:993px) and (max-width:1200px) {}

@media(min-width:1201px) {}
</style>
