<!--
 * @Description:info组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 15:05:57
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-06-16 09:08:51
-->
<template>
  <div class="infos container-fluid" :class="type" :style="info.style" v-if="type == 'partner'">
    <div class="infos-info container" :style="info.childStyle">
      <!-- <div class="infos-info-title" :style="info.titleStyle" v-html="info.title"></div>
      <div class="infos-info-subtitle hidden-xs-only" v-if="info.subtitle">{{ info.subtitle }}</div> -->
      <div class="infos-info-box" :style="info.itemSetStyle">
        <JzLabel :itemSet="info.itemSet" v-if="info.itemSet"></JzLabel>
        <JzLabel :itemSet="item" v-else-if="info.dataList" v-for="item, index in info.dataList.map((val, ind) => {
          let tempVal = {...val};
          tempVal.title= ['钉钉', '企微', '飞书'][ind] + '生态ISV伙伴'
          return tempVal
        })" :key="index"></JzLabel>
      </div>
    </div>
  </div>
  <div class="infos container-fluid" :class="type" :style="info.style" v-else-if="type == 'soft'">
    <div class="infos-info container" :style="info.childStyle">
      <div class="infos-info-title" :style="info.titleStyle" style="font-weight: 700;"><span style="color: #035dff;">20万</span>企业的选择</div>
      <div class="infos-info-subtitle hidden-xs-only" v-if="info.subtitle" style="margin-bottom: 30px;">因为选择了我们，直接提升了盈利
      </div>
      <div class="infos-info-box" :style="info.itemSetStyle" style="margin-top: 20px;">
        <JzLabel :itemSet="info.itemSet" v-if="info.itemSet"></JzLabel>
        <JzLabel :itemSet="item" v-else-if="info.dataList" v-for="item, index in info.dataList" :key="index"></JzLabel>
      </div>
    </div>
  </div>
  <div class="infos container-fluid" :class="type" :style="info.style" v-else-if="type != 'thanks'">
    <div class="infos-info container" :style="info.childStyle">
      <div class="infos-info-title" :style="info.titleStyle" v-html="info.title"></div>
      <div class="infos-info-subtitle hidden-xs-only" v-if="info.subtitle">{{ info.subtitle }}</div>
      <div class="infos-info-box" :style="info.itemSetStyle">
        <JzLabel :itemSet="info.itemSet" v-if="info.itemSet"></JzLabel>
        <JzLabel :itemSet="item" v-else-if="info.dataList" v-for="item, index in info.dataList" :key="index"></JzLabel>
      </div>
    </div>
  </div>
  <div class="infos container-fluid" :class="type" :style="info.style" v-else>
    <div class="infos-info container" :style="info.childStyle">
      <div class="infos-info-title" :style="info.titleStyle" v-html="info.title"></div>
      <div class="infos-info-subtitle hidden-xs-only" v-if="info.subtitle">{{ info.subtitle }}</div>
    </div>
    <div style="padding:0px 8%" class="hidden-xs-only">
      <el-carousel height="630px" :autoplay="true" :interval="10000" ref="bannerRef">
        <div class="infos container-fluid" :class="type" :style="info.style" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in info.itemSet" :key="index">
            <div class="infos-info container ">
              <JzLabel :itemSet="{ ...item, allImgArr: temArrimg }"></JzLabel>
            </div>
          </el-carousel-item>
        </div>
      </el-carousel>
    </div>
    <div class="hidden-sm-and-up">
      <el-carousel class="thanksRef" :autoplay="true" :interval="4000" ref="bannerRef">
        <div class="infos container-fluid" :class="type" :style="info.style" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in mobileThanks(info.itemSet)" :key="index">
            <div class="infos-info container ">
              <JzLabel :itemSet="{ ...item, allImgArr: temArrimg }"></JzLabel>
            </div>
          </el-carousel-item>
        </div>
      </el-carousel>
    </div>
    <!-- <el-carousel-item v-for="item, index in info.itemSet" :key="index">
      <JzLabel :itemSet="item"></JzLabel>
    </el-carousel-item>
    </el-carousel> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { defineComponent, onMounted, watch, ref, reactive, toRefs, getCurrentInstance, computed, } from "vue";
import JzLabel from '@/components/JzLabel/JzLabel.vue'
export default {
  components: {
    JzLabel
  },
  props: {
    info: {
      type: [Array, Object]
    },
    type: {
      type: String
    }
  },
  setup(props, context) {
    console.log(props.info, props.type)
    const bannerRef = ref(null);
    const mobileThanks = (list) => {
      let arrList = [];
      let temArr = [];
      for (let item of list) {
        arrList = arrList.concat(item.dataList);
      }
      arrList.filter((vals, index) => {
        if (index % 2 === 0) {
          temArr.push({
            viewType: "image",
            style: {
              "width": "200px",
              "height": "286px"
            },
            dataList: [vals],
          })
        } else {
          temArr[temArr.length - 1].dataList.push(vals)
        }
      })
      return temArr;
    }
    const slideBanner = () => {
      //选中的轮播图
      var box = document.querySelector('.thanksRef');
      var startPointX = 0
      var stopPointX = 0
      //重置坐标
      var resetPoint = function () {
        startPointX = 0
        stopPointX = 0
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint()
          return
        }
        if (startPointX - stopPointX > 0) {
          resetPoint()
          bannerRef.value?.next?.()
          return
        }
        if (startPointX - stopPointX < 0) {
          resetPoint()
          bannerRef.value?.prev?.()
          return
        }
      });
    }
    const temArrimg = computed(() => {
      let arr = [];
      props.info.itemSet.filter((val) => {
        arr = arr.concat(val.dataList);
      })
      return arr
    })
    onMounted(() => {
      slideBanner();
    })
    return {
      mobileThanks,
      bannerRef,
      temArrimg,
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.infos {
  margin: 46px 0px 120px 0px;
  &-info {
    // padding: 24px 48px;
    &-title {
      width: 100%;
      text-align: center;
      font-size: 32px;
      // font-weight: 700;
    }
    &-subtitle {
      width: 100%;
      margin-top: 8px;
      text-align: center;
      font-size: 20px;
      padding: 8px 16px;
    }
    &-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 46px;
    }
  }
}
.info {
  .infos-info-title {
    font-weight: 500px;
  }
}
.assist {
  margin: 0px !important;
  padding: 140px 0px 132px 0px !important;
}
.good {
  margin: 0px !important;
  padding: 100px 0px 0px 0px;
}
.thanks {
  margin: 0px !important;
  padding: 100px 0px 80px 0px;
  .infos-info {
    padding: 0px 40px;
  }
  .infos-info-box {
    margin-top: 46px;
  }
}
.soft {
  margin: 0px !important;
  padding: 0 0 20px 0px !important;
  .infos-info {
    padding: 0px 40px;
  }
  .infos-info-box {
    margin: 0px;
  }
}
.partner {
  padding: 0 0 30px 0px;
  margin: 0px !important;
  .infos-info-box {
    margin-top: 80px !important;
  }
}
// 媒体查询
@media (max-width: 767px) {
  .infos-info-title {
    font-size: 24px;
    width: 80%;
    margin-left: 10%;
    line-height: 40px;
  }
  ::v-deep .el-carousel__arrow {
    display: none;
  }
  // 圆点样式
  ::v-deep
    .home-banner
    .el-carousel__indicator--horizontal
    .el-carousel__button {
    width: 6px;
    height: 6px;
    background: transparent;
    background: #e5e5e5;
    border-radius: 50%;
    opacity: 1;
  }
  ::v-deep
    .home-banner
    .el-carousel__indicator--horizontal.is-active
    .el-carousel__button {
    width: 6px;
    height: 6px;
    background: #235fb0;
    border-radius: 50%;
    opacity: 1;
  }
  .info {
    .infos-info {
      padding: 0px;
      &-box {
        padding-bottom: 0px;
        margin-top: 24px;
      }
    }
  }
  .assist {
    padding: 48px 0px 24px 0px !important;
    .infos-info {
      padding: 0px;
      &-box {
        flex-wrap: wrap;
        padding: 0px;
        margin-top: 20px;
        .label {
          width: 50%;
          margin-bottom: 20px;
        }
      }
    }
  }
  .good {
    padding-top: 70px;
    .infos-info {
      padding: 0px;
      &-box {
        padding: 0px;
        margin-top: 0px;
      }
    }
  }
  .thanks {
    padding-top: 70px;
    padding-bottom: 24px;
    .infos-info {
      padding: 0px;
      &-box {
        padding: 12px;
        margin-top: 0px;
        width: 95%;
        margin-left: 2.5%;
      }
      &-title {
        width: 80%;
        margin-left: 10%;
      }
    }
  }
  .soft {
    padding: 24px 0px !important;
    .infos {
      &-info-box {
        padding: 0px;
      }
    }
  }
  .infos {
    margin: 24px 0px;
  }
  .partner {
    padding: 70px 0px 0px 0px;
    .infos-info-box {
      flex-direction: column;
      ::v-deep(.iconHtml) {
        margin: 24px 0px;
      }
    }
  }
}
</style>
