<!--
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 08:44:40
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-07-26 11:43:17
-->
<template>
  <div v-if="isRouter">
    <JzNav v-if="!isPromotion"></JzNav>
    <router-view />
    <JzFooter v-if="!isPromotion"></JzFooter>
    <div id="jz-soft-footer-space" class="hidden-sm-and-up" style="height:80px;width:100%"></div>
  </div>
</template>
<script>
import JzNav from '@/components/JzNav/JzNav.vue'
import JzFooter from '@/components/JzFooter/JzFooter.vue'
import { onMounted, ref, watch, reactive, toRefs } from 'vue-demi'
import { useRouter } from 'vue-router'
export default {
  components: { JzNav, JzFooter },
  name: 'App',
  setup(props, context) {
    const $router = useRouter()
    const isRouter = ref(true)
    const totalData = reactive({ isPromotion: false })
    onMounted(() => {
      const nowHref = window.location.href
      // 线上需要
      if (nowHref.indexOf('http://') != '-1' && nowHref.indexOf('10.10.8.') == -1) {
        window.location.replace(nowHref.replace('http://', 'https://'))
      }

      if (window.location.href.indexOf('service=show') != -1) {
        setTimeout(() => {
          if (typeof openZoosUrl !== 'undefined') {
            openZoosUrl('chatwin')
          } else {
            setTimeout(() => {
              try {
                openZoosUrl('chatwin')
              } catch (error) { }
            }, 5000)
          }
        }, 1000)
      }
    })
    watch(() => $router.currentRoute.value, (newVal, oldVal) => {
      // 推广拉新页面不需要头和底部
      totalData.isPromotion = newVal.name == 'PromotionLogin' || newVal.name == 'Promotion'
      isRouter.value = false
      const times = setTimeout((val) => {
        clearTimeout(times)
        isRouter.value = true
      }, 10)
    }, { immediate: true })
    return {
      isRouter,
      ...toRefs(totalData)
    }
  }
}
</script>
<style lang="scss">
@import url("./common/reset.css");
@import url("./common/global.scss");
@import url("./common/global.css");
</style>
