<template>
  <div class="about">
    <!-- 展示内容 -->
    <div class="about-banner container-fluid banner" v-if="aboutInfo.banner">
      <div class="about-banner-background container">
        <div class="about-banner-background-left">
          <span class="about-banner-background-left-title">{{ aboutInfo.banner.title }}</span>
          <span class="about-banner-background-left-subtitle hidden-xs-only">{{ aboutInfo.banner.subtitle }}</span>
        </div>
        <div class="about-banner-background-right">
          <el-image :lazy="true" style="width:100%;height:100%" :src="aboutInfo.banner.image"></el-image>
        </div>
      </div>
      <!-- 公司简介需要滚动到的位置 -->
      <div class="weizhi" v-if="aboutInfo.synopsis" :id="aboutInfo.synopsis.title"></div>
    </div>
    <!-- 公司简介 -->
    <div class="about-synopsis container-fluid synopsis" v-if="aboutInfo.synopsis">
      <div class="about-synopsis-box container">
        <div class="about-synopsis-box-title">{{ aboutInfo.synopsis.title }}</div>
        <div class="about-synopsis-box-content">
          <JzLabel :itemSet="aboutInfo.synopsis.itemSet"></JzLabel>
        </div>
      </div>
      <!-- 发展历程 需要滚动到的位置 -->
      <div class="weizhi" v-if="aboutInfo.course" :id="aboutInfo.course.title"></div>
    </div>
    <!-- 发展历程 -->
    <div class="about-synopsis container-fluid course" v-if="aboutInfo.course">
      <div class="about-synopsis-box container">
        <div class="about-synopsis-box-title">{{ aboutInfo.course.title }}</div>
        <div class="about-synopsis-box-content">
          <JzLabel :itemSet="aboutInfo.course.itemSet"></JzLabel>
        </div>
      </div>
      <!-- 品牌故事 需要滚动到的位置 -->
      <div class="weizhi" v-if="aboutInfo.story" :id="aboutInfo.story.title"></div>
    </div>
    <!-- 品牌故事 -->
    <div class="about-synopsis container-fluid story" v-if="aboutInfo.story">
      <div class="about-synopsis-box container">
        <div class="about-synopsis-box-title">{{ aboutInfo.story.title }}</div>
        <div class="about-synopsis-box-content">
          <JzLabel :itemSet="aboutInfo.story.itemSet"></JzLabel>
        </div>
      </div>
    </div>
    <!-- 战略定位 -->
    <div class="about-cards container-fluid cards" v-if="aboutInfo.card" :id="aboutInfo.card.title">
      <div class="about-cards-box container">
        <JzLabel class="about-cards-box-item" :itemSet="item.itemSet" v-for="item in aboutInfo.card" :key="item.title">
        </JzLabel>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted, watch, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import JzLabel from '@/components/JzLabel/JzLabel.vue'
export default {
  components: { JzLabel },
  name: 'about',
  props: {},
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const $store = useStore()
    const $router = useRouter()
    const aboutInfo = computed(() => {
      return $store.state.about
    })
    const scrollToDom = (argId) => {
      setTimeout(() => {
        const divDOm = document.getElementById(`${argId}`)
        if (divDOm) {
          divDOm.scrollIntoView({ behavior: 'smooth' })
        }
        // window.scrollBy(90, 0)
      }, 100)
    }
    onMounted(() => {
      const routerInfo = $router.currentRoute.value.query
      if (routerInfo && routerInfo.info) {
        scrollToDom(routerInfo.info)
      }
    })
    onUnmounted(() => {
    })
    return {
      aboutInfo
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.about {
  width: 100%;
  // min-height: 100vh;
  padding-bottom: 80px;
  // background-color: orange;

  &-banner {
    width: 100%;
    height: 470px;
    background-color: var(--el-color-primary);
    position: relative;

    &-background {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-left {
        color: #fffffffe;
        display: flex;
        flex-direction: column;
        margin-top: 80px;

        &-title {
          font-size: 36px;
          font-weight: 700;
          padding: 8px 16px;

          height: 70px;
          overflow: hidden;
          // 显示动画
          animation-name: showTitle;
          animation-duration: 0.3s;
          animation-timing-function: ease;
          animation-direction: none;
        }

        &-subtitle {
          padding: 8px 16px;
          font-size: 18px;

          height: 70px;
          overflow: hidden;
          // 显示动画
          animation-name: showTitle;
          animation-duration: 0.4s;
          animation-timing-function: ease;
          animation-direction: none;
        }
      }

      &-right {
        width: 640px;
        margin-top: 68px;
        height: calc(100% - 68px);
      }
    }
  }

  // 样式
  &-synopsis {
    width: 100%;
    height: 100%;
    padding-bottom: 24px;
    position: relative;

    &-box {
      padding: 24px 0px;

      &-title {
        width: 100%;
        text-align: center;
        padding: 8px 16px;
        font-size: 32px;
        font-weight: 700;
      }

      &-content {
        margin-top: 24px;
      }
    }
  }

  &-cards {
    padding-bottom: 40px;

    &-box {
      display: flex;
      justify-content: space-between;

      &-item {
        width: 30%;
      }
    }
  }
}

// 动画
@keyframes showTitle {
  0% {
    padding-top: 70px;
  }

  100% {
  }
}

//媒体查询
@media (max-width: 767px) {
  .about {
    margin-top: 24px;
    padding-bottom: 0px;

    &-banner {
      background: transparent;
      height: 100%;
      padding-top: 44px;

      &-background {
        background-color: var(--el-color-primary);
        position: relative;
        border-radius: 16px;

        &-left {
          margin-top: 0px;

          &-title {
            padding: 0px;
            font-size: 24px;
            position: relative;
            color: #ffffff;
            z-index: 4;
            width: 110%;
          }
        }

        &-right {
          position: relative;
          margin-top: 0px;
          width: 100%;
        }
      }
    }

    &-synopsis {
      position: relative;
      &-box {
        padding: 0px;
        &-title {
          font-size: 24px;
        }

        &-content {
        }
      }
    }
  }
  .course {
    padding-bottom: 0px;
  }
  .cards {
    border: none;
    padding-bottom: 0px;

    .about-cards-box {
      flex-direction: column;

      &-item {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
}
.weizhi {
  position: absolute;
  bottom: 60px;
  width: 1px;
  height: 1px;
  background-color: red;
  opacity: 0;
}
</style>
