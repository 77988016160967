<!--
 * @Description:CardInfo组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 11:04:49
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 16:30:50
-->
<template>
  <div class="cardInfo" :style="{ ...itemSet.istyle }">
    <div class="cardInfo-title hidden-sm-and-up" style="text-align:center">{{ itemSet.title }}</div>
    <div class="cardInfo-title hidden-xs-only">{{ itemSet.title }}</div>
    <div class="cardInfo-subtitle line2 hidden-sm-and-up" style="text-align:center">{{ itemSet.subtitle }}</div>
    <div class="cardInfo-subtitle line2 hidden-xs-only">{{ itemSet.subtitle }}</div>
    <div class="cardInfo-content line2">{{ itemSet.content }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    itemSet: {
      type: Object
    }
  },
  setup (props, context) {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.cardInfo {
  padding: 48px 36px;
  border-radius: 16px;
  box-shadow: 0px 4px 6px rgb(234, 238, 244);

  &-title {
    padding: 8px 16px;
    color: #1F2228D8;
    font-size: 28px;
    font-weight: 700;
  }

  &-subtitle {
    padding: 8px 16px;
    color: #1f2228A5;
    font-size: 20px;
    height: 72px;
  }

  &-content {
    width: 100%;
    padding: 9px 16px;
    font-size: 14px;
    color: #1f2228A5;
    height: 54px;
  }
}

// 显示两行 多行显示省略号
.line2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
