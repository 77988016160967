<!--
 * @Description:Info组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-09 09:35:10
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 16:03:48
-->
<template>
  <div class="info" :style="itemSet.index % 2 === 0 ? {} : { 'flex-direction': 'row-reverse' }">
    <div class="info-item" :class="(itemSet.type && itemSet.type == 'img' ? 'itemInfo' : '')">
      <div class="info-item-title">{{ itemSet.title }}</div>
      <div class="info-item-content" v-for="item in itemSet.dataList" :key="item.title">
        <div class="info-item-content-title">{{ item.title }}</div>
        <div class="info-item-content-subtitle">{{ item.subtitle }}</div>
      </div>
    </div>
    <div class="info-item" :class="(itemSet.type && itemSet.type == 'img' ? 'itemImg' : '')">
      <el-image :lazy="true" style="width:100%;height:100%" :src="itemSet.image" :preview-src-list="[itemSet.image]"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemSet: {
      type: [Array, Object]
    }
  },
  setup (props, context) {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &-item {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &-title {
      font-size: 40px;
      padding: 8px 16px 8px 0px;
      font-weight: 700;
      color: #1f2228e5;
    }

    &-content {
      padding-top: 24px;

      &-title {
        padding: 8px 16px;
        position: relative;
        font-size: 20px;
        color: #1f2228e5;
        font-weight: 700;
        line-height: 26px;
      }

      &-title::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 20px;
        background-color: var(--el-color-primary);
        left: 0px;
        top: 10px;
      }

      &-subtitle {
        padding: 8px 16px;
        color: #1f222899;
        font-size: 14px;
      }
    }
  }
}
.itemInfo{
  width: 40%;
}
.itemImg{
  width: 58%;
}

//媒体查询
@media(max-width:767px) {
  .info {
    flex-direction: column !important;

    &-item {
      width: 100%;

      &-title {
        font-size: 24px;
      }
    }

  }
}
</style>
