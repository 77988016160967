<!--
 * @Description:html
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-10 10:09:01
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-10 10:36:00
-->
<template>
  <div v-html="viewInfo">
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  props: {},
  setup (props, context) {
    const $router = useRouter()
    const $store = useStore()
    const type = ref()
    const viewInfo = computed(() => {
      console.log(' $store.state.view--', $store.state.view)
      return $store.state.view[type.value]
    })
    onMounted(() => {
      const info = $router.currentRoute.value.query
      type.value = info.type
    })
    return {
      viewInfo
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">

</style>
