<!--
 * @Description:icon 组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 14:09:59
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-05 14:40:42
-->
<template>
  <svg aria-hidden="true" style="fill: currentColor;" class="svgIcon" :style="iStyle" v-if="icon">
    <use :xlink:href="`#${icon}`"></use>
  </svg>
</template>

<script>
import { ref, toRefs, computed, defineComponent } from 'vue'
export default defineComponent({
  name: 'JzIcon',
  props: ['icon', 'iStyle', 'symbolIcon'],
  setup (props, context) {
    const test = () => {
    }
    return {
      test
    }
  }
})
</script>

<style scoped lang="scss">
.svgIcon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
}
</style>
