<!--
 * @Description:list组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 19:47:24
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 09:41:53
-->
<template>
  <el-row class="list hidden-xs-only" :gutter="70">
    <el-col :lg="8" v-for="item in dataList" :key="item.value" v-if="(dataList.length > 0)">
      <div class="list-item jz-hv" @click="showItem(item)">
        <JzLabel :itemSet="item.tag" v-if="item.tag"></JzLabel>
        <JzLabel class="marginB16" :itemSet="item.image" v-if="item.image" :otherProps="{ 'type': 'list' }"></JzLabel>
        <div class="list-item-title marginB16">{{ item.title }}</div>
        <div class="list-item-sub marginB8">
          <span>{{ item.sub.ind }}</span>
          <div class="list-item-sub-line"></div>
          <span>{{ item.sub.softName }}</span>
        </div>
        <!-- <div class="list-item-subtitle marginB8">{{ item.subtitle }}</div> -->
        <div class="list-item-tagList marginB8" v-if="item.showTag">
          <el-space>
            <Tag :itemSet="item" v-for="item in item.showTag" :key="item.label"></Tag>
          </el-space>
        </div>
      </div>
    </el-col>
    <el-empty :image-size="200" v-else style="width:100%;"></el-empty>
  </el-row>
  <el-row class="list hidden-sm-and-up" :gutter="10">
    <el-col :lg="8" v-for="item in dataList" :key="item.value" v-if="(dataList.length > 0)">
      <div class="list-item jz-hv" @click="showItem(item)" style="margin:16px 0px 0px 0px">
        <JzLabel :itemSet="item.tag" v-if="item.tag"></JzLabel>
        <JzLabel class="marginB16" :itemSet="item.image" v-if="item.image" :otherProps="{ 'type': 'list' }"></JzLabel>
        <div class="list-item-title marginB16">{{ item.title }}</div>
        <div class="list-item-sub marginB8">
          <span>{{ item.sub.ind }}</span>
          <div class="list-item-sub-line"></div>
          <span>{{ item.sub.softName }}</span>
        </div>
        <div class="list-item-subtitle marginB8">{{ item.subtitle }}</div>
        <div class="list-item-tagList marginB8" v-if="item.showTag">
          <el-space>
            <Tag :itemSet="item" v-for="item in item.showTag" :key="item.label"></Tag>
          </el-space>
        </div>
      </div>
    </el-col>
    <el-empty :image-size="200" v-else style="width:100%;"></el-empty>
  </el-row>
</template>

<script>
import JzLabel from '@/components/JzLabel/JzLabel.vue'
import Tag from '@/components/JzLabel/components/Tag.vue'
import { useRouter } from 'vue-router'
export default {
  components: { JzLabel, Tag },
  props: {
    dataList: {
      type: [Array, Object]
    }
  },
  setup (props, context) {
    const $router = useRouter()
    const showItem = (item) => {
      $router.push({
        path: '/programme',
        query: {
          msgid: item.key
        }
      })
    }
    return {
      showItem
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.list {
  padding: 40px 0px 80px 0px;

  &-item {
    position: relative;
    margin: 0px 0px 36px 0px;
    padding: 24px 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(95, 123, 178, 0.12);
    transition: all 0.5s ease 0s;
    // transition-timing-function: ease-in;
    // 过度动画
    // transition-property: all;
    // transition-duration: 25s;
    // transition-delay: 0s;
    // transition-timing-function: ease-in;

    &-title {
      font-size: 20px;
      color: rgba(31, 34, 40, 0.9);
      font-weight: 700;
    }

    &-sub {
      display: flex;
      align-items: center;
      color: #1f2228A5;
      font-size: 14px;

      &-line {
        width: 1px;
        height: 14px;
        background-color: #1f2228A5;
        margin: 0px 8px;
      }
    }

    &-subtitle {
      font-size: 14px;
      color: #1f2228d8;
    }

    &-tagList {
      display: flex;
    }
  }

  &-item:hover {
    margin-top: -12px;
    margin-bottom:48px;

    ::v-deep(.images-mask) {
      display: flex;
    }
  }
}

.marginB16 {
  margin-top: 16px;
}

.marginB8 {
  margin-top: 8px;
}

//媒体查询
@media(max-width:767px) {
  .list {
    padding: 0px;
    margin: 0px !important;

    &-item {
      margin-bottom: 12px;
      margin-top: 0px;
    }
  }
}
</style>
