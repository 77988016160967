/*
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2023-02-25 10:15:17
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-07-25 09:29:25
 */
/*
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2023-02-25 10:15:17
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-02 16:15:31
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/index/index.vue'
import Info from '../views/info/index.vue'
import Trial from '../views/trial/index.vue'
import About from '../views/about/index.vue'
import Service from '../views/service/index.vue'
import Programme from '../views/programme/index.vue'
import View from '../views/view/index.vue'
import Other from '../views/other/index.vue'
import Promotion from '../views/promotion/index.vue'
import PromotionLogin from '../views/promotion/promotionLogin.vue'
import BaiduMarket from '../views/baiduMarket/index.vue'
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/info',
    name: 'Info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Info,
    props: function ($route) {
      return $route.query
    }
  },
  {
    path: '/programme',
    name: 'Programme',
    component: Programme,
    props: function ($route) {
      return $route.query
    }
  },
  {
    path: '/trial',
    name: 'Trial',
    component: Trial
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/other',
    name: 'Other',
    component: Other
  },
  {
    path: '/view',
    name: 'View',
    component: View,
    props: function ($route) {
      return $route.query
    }
  },
  {
    path: '/promotion',
    name: 'Promotion',
    component: Promotion
  },
  {
    path: '/promotionLogin',
    name: 'PromotionLogin',
    component: PromotionLogin
  },
  {
    path: '/baiduMarket',
    name: 'baiduMarket',
    component: BaiduMarket
  },
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
