<!--
 * @Description: 视图样式
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 11:25:10
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-21 14:13:33
-->
<!-- icon -->
<template>
  <div class="label">
    <Icon v-if="itemSet.viewType == 'icon'" :itemSet="itemSet"></Icon>
    <Htmls v-else-if="itemSet.viewType == 'html'" :itemSet="itemSet"></Htmls>
    <CardInfo v-else-if="itemSet.viewType == 'cardInfo'" :itemSet="itemSet"></CardInfo>
    <TimeLine v-else-if="itemSet.viewType == 'timeLine'" :itemSet="itemSet"></TimeLine>
    <Level v-else-if="itemSet.viewType == 'Level'" :itemSet="itemSet" :index="index"></Level>
    <Vertical v-else-if="itemSet.viewType == 'vertical'" :itemSet="itemSet"></Vertical>
    <Banner v-else-if="itemSet.viewType == 'banner'" :itemSet="itemSet"></Banner>
    <Images v-else-if="itemSet.viewType == 'image'" :itemSet="itemSet" :otherProps="otherProps" :lazy="false"></Images>
    <IconHtml v-else-if="itemSet.viewType == 'iconHtml'" :itemSet="itemSet"></IconHtml>
    <Tag v-else-if="itemSet.viewType == 'tag'" :itemSet="itemSet"></Tag>
    <Info v-else-if="itemSet.viewType == 'info'" :itemSet="itemSet"></Info>
    <Apia v-else-if="itemSet.viewType == 'api'" :itemSet="itemSet"></Apia>
  </div>
</template>

<script>
import Icon from './components/Icon.vue'
import Htmls from './components/Htmls.vue'
import CardInfo from './components/CardInfo.vue'
import TimeLine from './components/TimeLine.vue'
import Level from './components/Level.vue'
import Vertical from './components/Vertical.vue'
import Banner from './components/Banner.vue'
import Images from './components/Images.vue'
import IconHtml from './components/IconHtml.vue'
import Tag from './components/Tag.vue'
import Info from './components/Info.vue'
import Apia from './components/Apia.vue'
export default {
  components: { Icon, Htmls, CardInfo, TimeLine, Level, Vertical, Banner, Images, IconHtml, Tag, Info, Apia },
  name: 'JzLabel',
  props: {
    itemSet: {
      type: Object
    },
    otherProps: {
      type: Object
    },
    index: {
      type: [String, Number]
    }
  },
  setup (props, context) {
    return {}
  }
}
</script>

<style scoped lang="scss">
.label {
  width: 100%;
  height: 100%;
}
</style>
