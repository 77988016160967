<!--
 * @Description:Banner
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 16:06:39
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-12 17:43:22
-->
<template>
  <el-row class="banner hidden-xs-only" :gutter="26">
    <el-col :lg="12">
      <el-carousel class="banner-box" height="255px" direction="vertical" :autoplay="true" v-if="itemSet.left"
        :interval="3000" indicator-position="none">
        <el-carousel-item v-for="(item, index) in itemSet.left" :key="index">
          <div class="banner-box-card">
            <div class="banner-box-card-title">
              {{ item.title }}
            </div>
            <div class="banner-box-card-start" v-if="(item.start == 5)">
              <el-space>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
              </el-space>
            </div>
            <div class="banner-box-card-footer">
              <span>{{ item.name }}</span>
              <span style="margin-left:24px">{{ item.time }}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-col>
    <el-col :lg="12">
      <el-carousel class="banner-box" height="255px" direction="vertical" :autoplay="true" v-if="itemSet.right"
        :interval="3000" indicator-position="none">
        <el-carousel-item v-for="(item, index) in itemSet.right" :key="index">
          <div class="banner-box-card">
            <div class="banner-box-card-title">
              {{ item.title }}
            </div>
            <div class="banner-box-card-start" v-if="(item.start == 5)">
              <el-space>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
              </el-space>
            </div>
            <div class="banner-box-card-footer">
              <span>{{ item.name }}</span>
              <span style="margin-left:24px">{{ item.time }}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-col>
  </el-row>
  <el-row class="hidden-sm-and-up">
    <el-col :lg="24">
      <el-carousel class="banner-box" height="300px" :autoplay="true" v-if="itemSet.left" :interval="3000"
        indicator-position="none">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <div class="banner-box-card">
            <div class="banner-box-card-title">
              {{ item.title }}
            </div>
            <div class="banner-box-card-start" v-if="(item.start == 5)">
              <el-space>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
                <JzIcon icon="icon-star" :iStyle="{ 'color': '#FFD400', 'font-size': '22px' }"></JzIcon>
              </el-space>
            </div>
            <div class="banner-box-card-footer">
              <span>{{ item.name }}</span>
              <span style="margin-left:24px">{{ item.time }}</span>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-col>
  </el-row>

</template>

<script>
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { computed } from 'vue-demi'
export default {
  components: {
    JzIcon
  },
  props: {
    itemSet: {
      type: [Array, Object]
    }
  },
  setup (props, context) {
    const bannerList = computed(() => {
      return [...props.itemSet.left, ...props.itemSet.right]
    })
    return {
      bannerList
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  padding: 0px 40px;

  &-box {
    box-shadow: 0px 8px 20px rgba(95, 123, 178, 0.1);
    border-radius: 8px;

    &-card {
      height: 100%;
      padding: 24px;
      position: relative;

      &-start {
        margin-top: 10px;
      }

      &-title {
        line-height: 32px;
        color: #333333;
        font-size: 16px;
      }

      &-footer {
        position: absolute;
        bottom: 24px;
        right: 24px;
        color: #33333399;
        font-size: 14px;
      }

    }
  }
}

//媒体查询
@media(max-width:767px) {
  .banner-box-card-title {

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
  }
}
</style>
