<!--
 * @Description:时间线
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 11:29:43
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-10 09:49:19
-->
<template>
  <div class="timeLine">
    <el-timeline>
      <el-timeline-item v-for="(item, index) in itemSet.value" :hollow="true" size="large" placement="top">
        <div class="timeLine-box" :class="{ leftBox: index % 2 == 0 }">
          <span class="timeLine-box-title">{{ item.time }}</span>
          <div class="timeLine-box-content">{{ item.value }}</div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  name: 'TimeLine',
  props: {
    itemSet: {
      type: Object
    }
  },
  setup (props, context) {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.timeLine {
  position: relative;
  width: calc(50% + 32px);
  margin-left: calc(50% - 32px);

  &-box {
    width: 448px;
    position: relative;
    top: -25px;

    &-title {
      padding: 16px;
      font-size: 28px;
      font-weight: 400;
      color: #13bc83;
    }

    &-content {
      box-shadow: 2px 2px 4px rgb(234, 238, 244);
      padding: 16px;
      font-size: 18px;
      color: #1f2228d8;
    }
  }

  ::v-deep(.el-timeline-item__tail) {
    border-color: #13bc83;
  }

  ::v-deep(.el-timeline-item__node) {
    // background-color: #13bc83;
    border-color: #13bc83;
  }

  ::v-deep(.el-timeline-item) {
    padding-bottom: 0px;
  }
}

.companydel {
  position: absolute;
  left: -200px;
  top: 1px;
  font-size: 20px;
}

.leftBox {
  position: relative;
  left: calc(-448px - 48px);
  text-align: right;
}

//媒体查询
@media(max-width:767px) {
  .timeLine{
    width: 100%;
    margin: 0px;
    &-box{
      width: 100%;
      padding-bottom: 24px;
      &-content{
        width: 260px;
      }
    }
    .leftBox{
      position: relative;
      left: 0px;
      text-align: left;
    }
  }
}
</style>
