<!--
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-07 17:17:36
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-09 11:34:25
-->
<template>
  <!-- <el-row :gutter="0">
    <el-col :lg="4" :offset="1"  v-for="(item, index) in itemSet.dataList" :key="index">
      <el-image :lazy="true" :src="item"></el-image>
    </el-col>
  </el-row> -->
  <div class="images" :class="otherProps && otherProps.type && otherProps.type == 'list' ? 'listImg' : ''">
    <div class="images-item" v-for="(item, index) in itemSet.dataList" :key="index" v-if="itemSet.dataList">
      <el-image :lazy="lazy" style="width:100%;height:100%" :src="item" :previewSrcList="tempPreArr" :initial-index="tempIndex(item,index)" :preview-teleported="true"></el-image>
    </div>
    <el-image :lazy="lazy" :src="itemSet.value" v-else style="width:100%;height:100%"></el-image>
    <div class="images-mask">
      <JzIcon icon="icon-yanjing" :iStyle="{ 'font-size': '30px', 'color': '#ffffff' }">
      </JzIcon>
    </div>
  </div>
</template>

<script>
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { computed } from 'vue'
export default {
  components: {
    JzIcon
  },
  name: 'Images',
  props: {
    itemSet: {
      type: [Array, Object]
    },
    otherProps: {
      type: Object
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  setup(props, context) {
    const tempPreArr = computed(() => {
      let arr = [];
      if (props.itemSet.allImgArr) {
        arr = props.itemSet.allImgArr;
      } else {
        arr = props.itemSet.dataList;
      }
      return arr;
    })
    const tempIndex = (item, indexs) => {
      let index = indexs;
      if (props.itemSet.allImgArr) {
        index = props.itemSet.allImgArr.findIndex((val) => val == item);
      }
      return index;
    }
    return {
      tempPreArr,
      tempIndex,
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.images {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  position: relative;
  // z-index: 1;

  &-item {
    width: 19%;
    min-height: 300px;
  }

  &-mask {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 4;
    // display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(3, 93, 255, 0.5);
  }
}
@media (max-width: 767px) {
  .images {
    &-item {
      width: 50%;
      min-height: 300px;
    }
  }
}
</style>
