<!--
 * @Description:视图 信息页面
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 11:26:05
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-21 17:31:24
-->
<template>
  <div class="info">
    <el-scrollbar class="info-tabList hidden-sm-and-up">
      <div class="info-tabList-box">
        <div class="info-tabList-box-item" @click="tabChange(item.key)" :class="{ tabActive: tabActive == item.key }" v-for="(item, index) in tabList" :key="item.key">{{
              item.title
          }}</div>
      </div>
    </el-scrollbar>
    <div class="container-fluid info-head" v-if="itemInfo.header">
      <div class="container info-head-box">
        <div class="info-head-box-title">
          {{ itemInfo.header.title }}
        </div>
        <div class="info-head-box-content">
          <div class="info-head-box-content-item" v-for="item in itemInfo.header.dataList" :key="item.title">
            <div class="info-head-box-content-item-image">
              <el-image :lazy="true" fit="contain" class="image" :src="item.image"></el-image>
            </div>
            <div class="info-head-box-content-item-right" style="margin-top:20px">
              <div class="info-head-box-content-item-title">{{ item.title }}</div>
              <div class="info-head-box-content-item-subtitle">{{ item.subtitle }}</div>
            </div>
          </div>
        </div>
        <div class="footerNext">
          <JzNext></JzNext>
        </div>
      </div>
    </div>
    <div class="container-fluid info-head" :style="{ 'background-color': index % 2 === 0 ? 'rgba(247,250,255,1)' : '' }" v-for="(item, index) in itemInfo.dataList" :key="item.value" v-if="tabActive != 116 && msgid != 116">
      <div class="container info-head-content">
        <JzLabel :itemSet="{ ...item, index: index }"></JzLabel>
        <div class="footerNext" v-if="(index + 1 != itemInfo.dataList.length) && (tabActive != '116' && msgid != 116)">
          <JzNext></JzNext>
        </div>
      </div>
    </div>
    <div class="container-fluid info-head api" :style="{ 'background-color': index % 2 === 1 ? 'rgba(247,250,255,1)' : '' }" v-for="(item, index) in itemInfo.dataList" :key="item.value" v-if="tabActive == 116 || msgid == 116">
      <JzLabel :itemSet="{ ...item, index: index }"></JzLabel>
      <div class="footerNext hidden-sm-and-up" v-if="(index + 1 != itemInfo.dataList.length) ">
        <JzNext></JzNext>
      </div>
      <div class=" hidden-sm-and-up" style="height:30px"></div>
    </div>
  </div>
</template>

<script>
import JzLabel from '@/components/JzLabel/JzLabel.vue'
import JzNext from '@/components/JzNext/JzNext.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: { JzLabel, JzNext },
  name: 'programme',
  props: {},
  setup(props, context) {
    const $router = useRouter()
    const $store = useStore()
    const msgid = ref(0)
    const tabActive = ref(0)
    const itemInfo = computed(() => {
      let info = {}
      $store.state.info.filter((val) => {
        if (val.key == msgid.value || val.key == tabActive.value) {
          info = val
        }
      })
      return info
    })
    const tabList = computed(() => {
      return $store.state.nav[1].children
    })
    const tabChange = (keys) => {
      tabActive.value = keys
    }
    onMounted(() => {
      const info = $router.currentRoute.value.query
      msgid.value = 0
      if (info.msgid) {
        msgid.value = info.msgid
      } else {
        tabActive.value = tabList.value[0].key
      }
    })
    return {
      msgid,
      itemInfo,
      tabList,
      tabActive,
      tabChange
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.tabActive::after {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 0px;
  width: calc(100% - 48px);
  background-color: var(--el-color-primary);
}

.info {
  padding-top: 68px;
  ::v-deep(.el-scrollbar) {
    overflow: inherit;
  }
  ::v-deep(.el-scrollbar__bar) {
    bottom: -8px;
  }

  &-tabList {
    position: sticky;
    top: 43px;
    z-index: 999;
    background: #ffffff;
    box-shadow: rgba(95, 123, 178, 0.3) 0px 6px 6px 0px;

    &-box {
      display: flex;
      flex-wrap: nowrap;
      height: 50px;

      &-item {
        // width: 100%;
        position: relative;
        padding: 0px 24px;
        flex-shrink: 0;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &-head {
    &-content {
      padding: 100px 0px;
      position: relative;
    }

    &-box {
      height: 642px;
      padding: 80px 0px;
      position: relative;

      &-title {
        padding: 8px 16px;
        font-size: 40px;
        text-align: center;
        color: #1f2228e5;
        width: 80%;
        margin-left: 10%;
        font-weight: 700;
      }

      &-content {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;

        &-item {
          width: 209px;
          text-align: center;

          &-image {
            width: 70px;
            height: 100px;
            margin-left: calc(50% - 35px);
          }

          &-title {
            margin-top: 10px;
            // text-align: left;
            color: #1f2228d8;
            font-size: 20px;
            padding: 8px 16px;
          }

          &-subtitle {
            margin-top: 4px;
            font-size: 14px;
            color: #1f2228a5;
            text-align: left;
            padding: 8px 16px;
          }
        }
      }
    }
  }
}

.footerNext {
  position: absolute;
  width: 100%;
  bottom: 20px;
}

.tabList {
  position: sticky;
  top: 43px;
  z-index: 999;
  background-color: #ffffff;
}
.api {
  position: relative;
  padding: 60px 0px;
}

// 媒体查询
@media (max-width: 767px) {
  ::v-deep(.isScroll) {
    box-shadow: none;
  }

  .info {
    padding-top: 43px;
    position: relative;

    ::v-deep(.el-tabs__header) {
      margin: 0px;
    }

    &-head {
      &-box {
        height: 100%;
        padding: 16px 0px 80px 0px;

        &-title {
          font-size: 24px;
          width: 100%;
          margin: 0px;
          text-align: left;
        }

        &-content {
          margin-top: 32px;
          flex-direction: column;

          &-item {
            display: flex;
            justify-content: space-between;
            width: 100%;

            &-image {
              width: 100px;
              // padding: 0px 24px;
              flex-shrink: 0;
              padding-top: 20px;
              margin-left: 0%;

              ::v-deep(.el-image) {
                // width: 50px !important;
                height: 50px !important;
              }
            }

            &-right {
              margin-top: 0px !important;
            }

            &-title {
              text-align: left;
            }

            &-subtitle {
              padding-right: 24px;
              padding-top: 0px;
            }
          }
        }
      }

      &-content {
        padding: 24px 0px 60px 0px;
      }
    }
  }
  .api {
    position: relative;
    padding: 30px 0px;
  }
}
</style>
