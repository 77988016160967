/*
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 08:44:40
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-07-26 09:18:34
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 全局事件总线
import mitt from 'mitt'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'element-plus/theme-chalk/display.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import './styles/element/index.scss'
// 引入element icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import './styles/element/index.scss'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 引用 element v.3
// 定义全局的图片缩略图样式
app.config.globalProperties.$fit = 'cover'
app.config.globalProperties.$Bus = mitt()
createApp(App).use(store).use(router).use(ElementPlus, {
  locale: zhCn
}).mount('#app')
