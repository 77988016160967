<!--
 * @Description:
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-09 08:34:36
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-03-07 16:05:31
-->
<template>
  <div class="next">
    <JzIcon class="next-1" icon="icon-down" :iStyle="{ 'font-size': '16px', 'color': '#f4f7fc' }"></JzIcon>
    <JzIcon class="next-2" icon="icon-down" :iStyle="{ 'font-size': '16px', 'color': '#dae3f5' }"></JzIcon>
    <JzIcon class="next-3" icon="icon-down" :iStyle="{ 'font-size': '16px', 'color': '#bbcbec' }"></JzIcon>
  </div>
</template>

<script>
import JzIcon from '@/components/JzIcon/JzIcon.vue'
export default {
  components: { JzIcon },
  name: 'JzNext',
  props: {},
  setup (props, context) {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.next {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  display: none;

  &-2 {
    position: relative;
    top: -6px;
  }

  &-3 {
    position: relative;
    top: -12px;
  }
}
</style>
