<!--
 * @Description:包含图标样式组件
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 16:06:04
 * @LastEditors: 郭灿
 * @LastEditTime: 2022-12-12 17:40:17
-->
<template>
  <div class="icons" :class="itemSet.viewClass" @click="handClick">
    <div class="icon">
      <JzIcon :icon="itemSet.icon" :iStyle="{ ...itemSet.iconStyle }"></JzIcon>
    </div>
    <div class="info">
      <div class="info-title">{{ itemSet.title }}</div>
      <div class="info-subtitle hidden-xs-only">{{ itemSet.subtitle }}</div>
    </div>
  </div>
</template>

<script>
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { useRouter } from 'vue-router'
export default {
  components: { JzIcon },
  name: 'Icon',
  props: {
    itemSet: {
      type: Object
    }
  },
  setup (props, context) {
    const $router = useRouter()
    const handClick = (e) => {
      if (props.itemSet.path) {
        $router[props.itemSet.linkType]({
          path: props.itemSet.path,
          query: {
            msgid: props.itemSet.key
          }
        })
      }
    }
    return {
      handClick
    }
  }
}
</script>

<style scoped lang="scss">
// 导航样式
.navShow {
  display: flex;

  .icon {
    width: 20px;
    height: 20px;
    color: var(--el-color-primary);
    flex-shrink: 0;
    margin-top: 10px;
  }

  .info {
    &-title {
      font-size: 16px;
      padding: 8px 16px;
      color: #1F2228FE;
    }

    &-subtitle {
      font-size: 14px;
      padding: 8px 16px;
      color: #1F2228A5;
    }
  }
}

// 客户生命周期样式
.benefitClsaa {
  display: flex;

  .icon {
    width: 40px !important;
  }

  .info {
    &-title {
      padding: 0px 16px;
      font-size: 16px;
      font-size: 500;
      color: #1f2228d8;
    }

    &-subtitle {
      padding: 4px 16px 0px 16px;
      font-size: 14px;
      color: #1f222899;
      line-height: 21px;
    }
  }
}

.icons {
  .icon {
    width: 20px;
    height: 40px;
  }
}

// 媒体查询
@media(max-width:767px) {
  .benefitClsaa {
    display: flex;
    flex-direction: column;

    .icon {
      margin-left: 16px;
    }

    .info-title {
      padding-top: 8px;
    }
  }

  .benefit-info-box {
    &-item:nth-child(1) .benefitClsaa .icon {
      background-color: #f3f7ff;
    }

    &-item:nth-child(3) .benefitClsaa .icon {
      background-color: #fffbef;
    }

    &-item:nth-child(5) .benefitClsaa .icon {
      background-color: #e4f3f4;
    }

    &-item:nth-child(7) .benefitClsaa .icon {
      background-color: #fceffb;
    }
  }

  .icons {
    .icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      .svgIcon {
        font-size: 20px !important;
      }
    }
  }
}

@media(min-width:768px) and (max-width:992px) {}

@media(min-width:993px) and (max-width:1200px) {}

@media(min-width:1201px) {}
</style>
