<template>
  <div class="b-page">
    <!-- 顶部导航栏 -->
    <div class="b-nav b-pc">

      <JzIcon class="jz-hv " icon="icon-logo-color" :iStyle="{ 'color': '', }" style="width:155px;height:36px" @click="handLogo">
      </JzIcon>
      <div class="b-nav-op">
        <el-button type="text" @click="toLogin">直接登录</el-button>
        <el-button type="primary" @click="handLogo">前往官网</el-button>
      </div>

    </div>
    <!-- 顶部banner加注册表单 -->
    <div class="b-banner b-pc">
      <div class="b-form">
        <!-- 表单区域 -->
        <div class="b-form-container">
          <div class="b-form-container-title">领取15天免费试用</div>
          <div class="b-form-container-input">
            <el-input :prefix-icon="OfficeBuilding" style="width:100%;height: 44px;" v-model="forms.formValue.enterprise" placeholder="请输入完整企业名称"></el-input>
          </div>
          <div class="b-form-container-input">
            <el-input :prefix-icon="User" style="width:100%;height: 44px;" v-model="forms.formValue.name" placeholder="怎么称呼您" maxlength="11"></el-input>
          </div>
          <div class="b-form-container-input">
            <el-input controls-position="right" :controls="false" :prefix-icon="Iphone" type="number" style="width:100%;height: 44px;" v-model="forms.formValue.number" placeholder="请输入手机号" maxlength="11"></el-input>
          </div>
          <div class="b-form-container-input b-form-container-input-code">
            <el-input :prefix-icon="Key" style="width:100%;height: 44px;" v-model="forms.formValue.code" placeholder="请输入验证码"></el-input>
            <el-button class="b-form-container-code-button" type="primary" :disabled="showInfo.disabled" @click="getCode">{{
                showInfo.btnTitle
            }}</el-button>
          </div>
          <div class="b-form-container-check">
            <el-checkbox v-model="forms.read" />
            <div class="b-form-container-check-title">我已阅读并同意 <span class="jz-hv" style="color:var(--el-color-primary)" @click="goView('protocols')">《服务协议》</span>及<span class="jz-hv" style="color:var(--el-color-primary)" @click="goView('secure')">《隐私政策》</span></div>
          </div>
          <div class="b-form-container-save">
            <el-button style="width:100%;height:44px;" type="primary" :loading="loading" @click="submit">立即领取</el-button>
          </div>

          <!-- 提交成功的弹窗 -->

          <el-dialog v-model="showVisible" width="350px" :modal="true" :append-to-body="true" :close-on-click-modal="false" :before-close="handleClose">
            <template #header>
              <div style="font-weight:600">
                感谢使用金智CRM
              </div>
            </template>
            <div class="box">
              <div class="box-content">
                <div style="display:flex;margin-bottom:16px">
                  <div style="font-size:14px;font-weight:600;width:50%;text-align:right;">登录名：</div>
                  <div style="width:50%;text-align:left;">{{ showLoginInfo.entno }}</div>
                </div>
                <div style="display:flex;margin-bottom:16px">
                  <div style="font-size:14px;font-weight:600;width:50%;text-align:right;">用户名：</div>
                  <div style="width:50%;text-align:left;">{{ showLoginInfo.user }}</div>
                </div>
                <div style="display:flex;margin-bottom:16px">
                  <div style="font-size:14px;font-weight:600;width:50%;text-align:right;display: flex;justify-content: flex-end;">密 <div style="width: 14px;"></div>码：</div>
                  <div style="width:50%;text-align:left;">{{ showLoginInfo.password }}</div>
                </div>
              </div>
            </div>
            <template #footer>
              <div class="">
                <el-button @click="handleClose">关闭</el-button>
                <el-button type="primary" @click="goLogin">去登录</el-button>
              </div>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>
    <!-- 介绍 -->
    <div class="b-container" data-component="baiduMarket">

      <!-- 伙伴 -->
      <div style="width: 100%;" class="b-pc">
        <Info v-if="indexInfo.partner" :info="indexInfo.partner" :type="'partner'"></Info>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image style="width:100%;" src="/image/baiduMarket/view1.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image src="/image/baiduMarket/view2.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image src="/image/baiduMarket/view3.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image src="/image/baiduMarket/view4.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image src="/image/baiduMarket/view5.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image src="/image/baiduMarket/view6.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-pc">
        <el-image src="/image/baiduMarket/view7.png"></el-image>
      </div>
      <!-- 手机端 -->

      <div class="b-nav b-mobile" style="padding:0 24px 0 12px; left: 0;">
        <JzIcon class="jz-hv " icon="icon-logo-color" :iStyle="{ 'color': '', }" style="width:155px;height:36px" @click="handLogo">
        </JzIcon>
        <div class="b-nav-op">
          <el-button type="text" @click="toLogin('mobile')">直接登录</el-button>
          <el-button type="primary" @click="handLogo">前往官网</el-button>
        </div>
      </div>

      <div style="width:100%; margin-top: 0;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view1.png"></el-image>
      </div>
      <div style="width:100%; padding: 0 24px; margin-top: 20px;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view2.png"></el-image>
      </div>
      <div style="width:100%; padding: 0 24px; margin-top: 30px;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view3.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view4.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view5.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view6.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view7.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view8.png"></el-image>
      </div>
      <div style="width:100%;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view9.png"></el-image>
      </div>
      <!-- 客户全生命周期数字化管理 -->
      <!-- <Benefit v-if="indexInfo.benefit" :info="indexInfo.benefit"></Benefit> -->
      <!-- 快速定制个性化专属应用 -->
      <!-- <Info v-if="indexInfo.info" :info="indexInfo.info" :type="'info'"></Info> -->
      <!-- 助力 -->
      <!-- <Info v-if="indexInfo.assist" :info="indexInfo.assist" :type="'assist'"></Info> -->
      <!-- 好评 -->
      <!-- <Info v-if="indexInfo.good" :info="indexInfo.good" :type="'good'"></Info> -->
      <!-- 感谢信 -->
      <Info v-if="indexInfo.thanks" :info="indexInfo.thanks" :type="'thanks'"></Info>
      <!-- 数百家 -->
      <div style="width: 100%;">
        <Info v-if="indexInfo.soft" :info="indexInfo.soft" :type="'soft'" :pageFrom="'baiduMarket'"></Info>
      </div>
      <!-- <div style="width:100%;" class="jz-flex b-pc">
        <el-image style="width: 540px;" src="/image/baiduMarket/view8.png"></el-image>
      </div> -->
      <div class="footerBox" v-if="0>1">
        <div class="jiejue">
          <div class="container-fluid jiejue-box">
            <div class="container  jiejue-box-head">
              <div class="jiejue-box-head-title hidden-xs-only" style="">立即扫码咨询，领取您的专属解决方案</div>
              <div class="jiejue-box-head-title hidden-sm-and-up" style="text-align: center;">
                <div>立即扫码咨询</div>
                <div>领取您的专属解决方案</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid usBox">
          <div class="container usBox-content">
            <el-image :lazy="true" src="/image/index/us.svg"></el-image>
          </div>
          <div class="usBox-title">
            钉钉生态合作伙伴 <span class="hidden-xs-only">，助力企业提升业绩</span>
          </div>
        </div>
      </div>
      <div style="width:100%; margin-top: 0;" class="jz-flex b-container-image b-mobile">
        <el-image src="/image/baiduMarket/mobile/view10.png"></el-image>
      </div>
      <div style="width:100%;height:40px;"></div>
    </div>
  </div>
  <!-- 手机端底部 -->
  <div class="b-mobile">
    <div class="b-mobile-footer">
      <div class="b-mobile-footer-btn1" @click="onShowContact()">
        <JzIcon icon="icon-hujiao" :iStyle="{ 'color': '', }" style="width:14px;height:14px">
        </JzIcon>联系我们
      </div>
      <div class="b-mobile-footer-btn2" @click="onShowMobileForm()">立即申请15天免费试用</div>
    </div>
  </div>
  <!-- 联系我们弹窗 -->
  <el-dialog v-model="contactVisible" width="300px" :modal="true" :append-to-body="true" :close-on-click-modal="false" :before-close="onCloseContact">
    <template #header>
      <div style="font-weight:600">
        联系方式
      </div>
    </template>
    <div class="b-mobile-contact">
      <a class="b-mobile-contact-item" href="tel:0532-85971028">0532-85971028</a>
      <a class="b-mobile-contact-item" href="tel:0532-85971038">0532-85971038</a>
    </div>
    <template #footer>
      <div class="">
        <el-button @click="onCloseContact()">关闭</el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 手机端表单弹窗 -->
  <el-dialog v-model="mobileFormVisible" width="330px" :modal="true" :append-to-body="true" :close-on-click-modal="false" :before-close="onCloseMobileForm">
    <template #header>
      <div style="font-weight:600">
        申请试用
      </div>
    </template>
    <div style="width:100%;">
      <div class="b-form-container-input">
        <el-input :prefix-icon="OfficeBuilding" style="width:100%;height: 44px;" v-model="forms.formValue.enterprise" placeholder="请输入完整企业名称"></el-input>
      </div>
      <div class="b-form-container-input">
        <el-input :prefix-icon="User" style="width:100%;height: 44px;" v-model="forms.formValue.name" placeholder="怎么称呼您" maxlength="11"></el-input>
      </div>
      <div class="b-form-container-input">
        <el-input controls-position="right" :controls="false" :prefix-icon="Iphone" type="number" style="width:100%;height: 44px;" v-model="forms.formValue.number" placeholder="请输入手机号" maxlength="11"></el-input>
      </div>
      <div class="b-form-container-input b-form-container-input-code">
        <el-input :prefix-icon="Key" style="width:100%;height: 44px;" v-model="forms.formValue.code" placeholder="请输入验证码"></el-input>
        <el-button class="b-form-container-code-button" type="primary" :disabled="showInfo.disabled" @click="getCode">{{
                showInfo.btnTitle
            }}</el-button>
      </div>
      <div class="b-form-container-check">
        <el-checkbox v-model="forms.read" />
        <div class="b-form-container-check-title">我已阅读并同意 <span class="jz-hv" style="color:var(--el-color-primary)" @click="goView('protocols')">《服务协议》</span>及<span class="jz-hv" style="color:var(--el-color-primary)" @click="goView('secure')">《隐私政策》</span></div>
      </div>
      <div class="b-form-container-save">
        <el-button style="width:100%;height:44px;" type="primary" :loading="loading" @click="submit">立即领取</el-button>
      </div>
    </div>
    <template #footer>
    </template>
  </el-dialog>
</template>

<script>
import Banner from './components/banner.vue'
import Benefit from './components/benefit.vue'
import Info from './components/info.vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import { Iphone, OfficeBuilding, Key, User } from '@element-plus/icons-vue'
import { computed, onMounted, reactive, ref, onUnmounted, toRefs, } from 'vue'
import { ElMessage } from 'element-plus'
import { Requests } from '@/api/index.js'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {
    Banner,
    Benefit,
    Info,
    JzIcon
  },
  setup(props, context) {

    const $store = useStore()
    const indexInfo = computed(() => {
      return $store.state.index
    })

    const $router = useRouter()
    const forms = reactive({
      formValue: {
        enterprise: '',
        number: null,
        code: '',
        name: ''
      },
      read: true
    })
    const showVisible = ref(false)
    const showLoginInfo = ref({
      entno: '*****',
      password: '*****',
      user: '*****'
    })
    // 定时器
    const Times = ref(-1)
    // 按钮加载中
    const loading = ref(false)
    const totalData = reactive({
      contactVisible: false,
      mobileFormVisible: false,
    })

    const showInfo = computed(() => {
      return Times.value > 0 ? {
        btnTitle: `${Times.value}s`,
        disabled: true
      } : {
        btnTitle: '获取验证码',
        disabled: false
      }
    })
    const goView = (type) => {
      $router.push({
        path: '/view',
        query: {
          type: type
        }
      })
    }

    // 立即领取按钮
    const submit = async () => {
      if (forms.read) {
        if (forms.formValue.enterprise) {
          if (forms.formValue.name) {
            if (forms.formValue.number && /^1[3456789][0-9]\d{8}$/.test(forms.formValue.number)) {
              if (forms.formValue.code) {
                loading.value = true
                const params = {
                  url: 'https://crm.jzsoft.cn/pro/api/user/RegisterCompany',
                  method: 'GET',
                  data: {
                    company: forms.formValue.enterprise,
                    linkman: forms.formValue.name,
                    tel: forms.formValue.number,
                    code: forms.formValue.code,
                    codeType: 'register',
                    registerFrom: 'baidu',
                  },
                  params: {}
                }

                const res = await Requests(params)
                loading.value = false
                if (res.errcode === 0) {
                  totalData.mobileFormVisible = false;
                  ElMessage.success('感谢使用金智CRM，账户密码下发到您的手机，如果您有需要，请与我们联系')
                  if (typeof (res.result) === 'object') {
                    showLoginInfo.value = res.result
                    showVisible.value = true
                  } else {
                    setTimeout(() => {
                      window.open('https://crm.jzsoft.cn/pro')
                    }, 1000)
                  }
                }
              } else {
                ElMessage.error('请输入验证码')
              }
            } else {
              ElMessage.error('请输入正确手机号')
            }
          } else {
            ElMessage.error('请输入您的姓名')
          }
        } else {
          ElMessage.error('请输入完整企业名称')
        }
      } else {
        ElMessage.error('请勾选"我已阅读并同意 《服务协议》及《隐私政策》"')
      }
    }
    // 获取验证码
    const getCode = async () => {
      if (forms.formValue.number && forms.formValue.number.length == 11) {
        const params = {
          url: 'https://crm.jzsoft.cn/pro/api/MobileSms/GetCode',
          method: 'get',
          data: {
            phone: forms.formValue.number,
            codeType: 'register'
          }
        }
        const res = await Requests(params)
        if (res.errcode === 0) {
          ElMessage.success('发送成功')
          Times.value = 60
          const times = setInterval(() => {
            if (Times.value == 0) {
              clearInterval(times)
            }
            Times.value -= 1
          }, 1000)
        } else {
        }
      } else {
        ElMessage.error('请输入正确手机号')
      }
    }
    //
    const goLogin = (arg) => {
      if (arg == 'mobile') {
        window.open('https://crm.jzsoft.cn/pro/mobile/index.html#/');
        return false;
      }
      window.open('https://crm.jzsoft.cn/pro')
    }
    // 弹出框关闭之前
    const handleClose = () => {
      showVisible.value = false
    }

    const pageOnScroll = (arg) => {
      if (window.location.href.indexOf('baiduMarket') == -1) {
        return false;
      }
      let topVal = document.documentElement.scrollTop
      let formEle = document.getElementById('jz-soft-footer-reg');
      if (formEle) {
        if (topVal > 630) {
          formEle.style.display = 'flex'
        } else {
          formEle.style.display = 'none'
        }
      }
      if (window.baiduMarketDebugger) {
        console.log(topVal)
      }
    }

    const handLogo = () => {
      window.open('https://jzsoft.cn/#/')
    }
    const checkFormVisible = () => {
      let findDom = setInterval(() => {
        let formEle = document.getElementById('jz-soft-footer-reg');
        if (formEle) {
          formEle.style.display = 'none'
          clearInterval(findDom)
        }
      }, 100);
    }
    const toLogin = (arg) => {
      if (arg == 'mobile') {
        window.open('https://crm.jzsoft.cn/pro/mobile/index.html#/');
        return false;
      }
      window.open('https://crm.jzsoft.cn/pro/user/login?pageFrom=website');
    }

    const hiddenFooter = (aVal) => {
      let menuEle = document.getElementById('menu-parent')
      let menuSpace = document.getElementById('jz-soft-footer-space')
      let screenWidth = window.innerWidth
      if (menuEle) {
        menuEle.style.display = screenWidth <= 767 ? aVal : 'block';
      }
      if (menuSpace) {
        // menuSpace.style.display = screenWidth <= 767 ? aVal : 'block';
        menuSpace.style.backgroundColor = screenWidth <= 767 ? '#f7faff' : '';
      }
    }
    const onShowContact = () => {
      totalData.contactVisible = true;
    }
    const onCloseContact = () => {
      totalData.contactVisible = false;
    }
    const onShowMobileForm = () => {
      totalData.mobileFormVisible = true;
    }
    const onCloseMobileForm = () => {
      totalData.mobileFormVisible = false;
    }

    onMounted(() => {
      console.log('初始化')
      checkFormVisible();
      window.onscroll = (...arg) => {
        pageOnScroll(arg);
      }
      window.onresize = () => {
        hiddenFooter('none')
      }
      hiddenFooter('none')
    });
    onUnmounted(() => {
      console.log('卸载了')
      let formEle = document.getElementById('jz-soft-footer-reg');
      if (formEle) {
        formEle.style.display = 'flex'
      }
      hiddenFooter('block')
    });

    return {
      indexInfo,
      forms,
      Iphone,
      OfficeBuilding,
      Key,
      User,
      goView,
      showInfo,
      loading,
      submit,
      getCode,
      showVisible,
      showLoginInfo,
      goLogin,
      handleClose,
      handLogo,
      JzIcon,
      toLogin,
      onShowContact,
      onCloseContact,
      ...toRefs(totalData),
      onShowMobileForm,
      onCloseMobileForm,
    }
  },
}
</script>

<style scoped lang="scss">
.jz-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.b-page {
  width: 100%;
  background-color: rgb(247, 250, 255);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 68px;
}
.b-nav {
  width: 100%;
  height: 68px;
  position: fixed;
  z-index: 1999;
  top: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fff;
  padding: 0 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-op {
    display: flex;
    gap: 8px;
  }
}
.b-banner {
  width: 100%;
  height: 834px;
  background-image: url("/image/baiduMarket/banner0.png");
  background-size: auto 834px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
}
.b-form {
  width: 1200px;
}
.b-form-container {
  width: 400px;
  background-color: rgb(247, 250, 255);
  border-radius: 10px;
  padding: 24px;
  margin: 160px 0 0 100px;
}
.b-form-container-title {
  font-size: 32px;
  font-weight: 400;
  width: 100%;
  margin: 0px 0px 6px 0px;
}
.b-form-container-input {
  margin: 16px 0 0 0;
  width: 100%;
}
.b-form-container-input-code {
  display: flex;
  width: 100%;
}
.b-form-container-code-button {
  height: 44px;
}
.b-form-container-check {
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
  margin-top: 8px;
  height: 32px;
}
.b-form-container-check-title {
  font-size: 12px;
}
.b-form-container-save {
  margin-top: 16px;
}
.b-container {
  width: 1200px;
  &-image {
    margin-top: 40px;
  }

  .box {
    padding-top: 60px;

    .titles {
      font-size: 32px;
      color: #ffffff;
      padding: 8px 16px;
      font-weight: 500;
      text-align: center;
    }
  }
}

.footerBox {
  position: relative;
  padding-bottom: 260px;
  width: 100%;
  overflow: hidden;

  .jiejue {
    position: relative;
    background-color: var(--el-color-primary);
    height: 367px;

    &-box {
      position: relative;
      z-index: 4;
      top: 100px;
      text-align: center;
      width: 80%;
      margin-left: 10%;

      &-head {
        &-title {
          color: #ffffff;
          font-size: 32px;
          font-weight: 500;
        }
      }
    }
  }

  .usBox {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 170px;

    &-title {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 66%;
      font-size: 16px;
    }
  }
}

// 媒体查询
@media (max-width: 767px) {
  .footerBox {
    padding-bottom: 130px;
    max-width: 100vw;
    overflow: hidden;

    .jiejue {
      height: 235px !important;

      &-box {
        top: 44px;
        width: 80%;

        &-head {
          &-title {
            font-size: 24px;
          }
        }
      }
    }

    .usBox {
      top: 140px;
      &-title {
        width: 97%;
        font-size: 12px;
      }
    }
  }
}
// ::v-deep(.jz-soft-nav) {
//   display: none !important;
// }
.b-mobile {
  display: none;
}
@media (max-width: 767px) {
  .b-pc {
    display: none;
  }
  .b-mobile {
    display: flex;
  }
  .b-container {
    width: 100%;
  }
}
.b-mobile-footer {
  position: fixed;
  width: 100%;
  z-index: 120;
  left: 0;
  bottom: 0;
  height: 50px;
  align-items: center;
  background-color: #f7faff;
  display: flex;
}

.b-mobile-footer-btn1 {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3e8fe;
  color: #035dff;
  height: 100%;
  gap: 4px;
}
.b-mobile-footer-btn2 {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #035dff;
  color: #fff;
  height: 100%;
}
.b-mobile-contact {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
}
.b-mobile-contact-item {
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: #035dff;
}
</style>
