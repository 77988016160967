<!--
 * @Description: 推广拉新注册
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 17:42:37
 * @LastEditors: sunweipeng sunweipengEmail
 * @LastEditTime: 2023-08-07 09:22:01
-->
<template>
  <div class="main">

    <div class="trial">
      <div class="trial-content">
        <div class="trial-content-box">
          <div class="trial-content-box-left hidden-xs-only">
            <el-image :lazy="true" style="height:100%" src="/image/promotion_reg_left.png"></el-image>
          </div>
          <div class="trial-content-box-right">

            <div class="jz-flex" style="height: 108px;">
              <el-image :lazy="true" style="height:100%" src="/image/promotion_reg_logo.png"></el-image>
            </div>

            <el-tabs v-model="actTab" style="">

              <el-tab-pane label="登录">
                <div class="trial-content-box-right-input" style="margin-top: 32px;">
                  <el-input controls-position="right" :controls="false" :prefix-icon="Iphone" type="number"
                    style="width:calc(100% - 0px);height: 44px;" v-model="userPhone" placeholder="请输入账号（手机号）"
                    maxlength="11"></el-input>
                </div>
                <div class="trial-content-box-right-input" v-if="loginType == 'password'">
                  <el-input controls-position="right" :controls="false" :prefix-icon="View"
                    style="width:calc(100% - 0px);height: 44px;" v-model="password" placeholder="请输入密码" show-password>
                  </el-input>
                </div>
                <div class="trial-content-box-right-input code" v-else-if="loginType == 'code'">
                  <el-input :prefix-icon="Key" style="width:calc(100% - 0px);height: 44px;" v-model="code"
                    placeholder="请输入验证码" maxlength="8"></el-input>
                  <el-button class="code-button" type="primary" :disabled="loginBtnInfo.disabled" link
                    @click="getLoginCode">{{
                      loginBtnInfo.btnTitle
                    }}</el-button>
                </div>
                <div class="trial-content-box-right-footer">
                  <el-button style="width:100%;height:44px;" type="primary" :loading="loadingVal == 'login'"
                    @click="onLogin">确定</el-button>
                </div>
                <div class="trial-content-box-right-footer" style="margin-top: 12px;">
                  <el-button :disabled="loadingVal !== ''" style="width:100%;height:44px;" type="primary" link
                    @click="toggleLoginType">
                    {{ loginType == 'password' ? '短信验证码登录' : '密码登录' }}
                  </el-button>
                </div>
              </el-tab-pane>

              <el-tab-pane label="注册">
                <div class="trial-content-box-right-input">
                  <div style="width: 90px; display: inline-block;">公司名称：</div>
                  <el-input :prefix-icon="OfficeBuilding" style="width:calc(100% - 90px);height: 44px;"
                    v-model="forms.formValue.company" placeholder="请输入完整企业名称"></el-input>
                </div>
                <div class="trial-content-box-right-input">
                  <div style="width: 90px; display: inline-block;">联系人：</div>
                  <el-input :prefix-icon="User" style="width:calc(100% - 90px);height: 44px;"
                    v-model="forms.formValue.userName" placeholder="怎么称呼" maxlength="11"></el-input>
                </div>
                <div class="trial-content-box-right-input">
                  <div style="width: 90px; display: inline-block;">联系方式：</div>
                  <el-input controls-position="right" :controls="false" :prefix-icon="Iphone" type="number"
                    style="width:calc(100% - 90px);height: 44px;" v-model="forms.formValue.userPhone" placeholder="请输入手机号"
                    maxlength="11"></el-input>
                </div>
                <div class="trial-content-box-right-input">
                  <div style="width: 90px; display: inline-block;">登录密码：</div>
                  <el-input controls-position="right" :controls="false" :prefix-icon="View"
                    style="width:calc(100% - 90px);height: 44px;" v-model="forms.formValue.password" placeholder="请输入密码"
                    show-password>
                  </el-input>
                </div>
                <div class="trial-content-box-right-input code">
                  <div style="width: 90px; display: inline-block;">验证码：</div>
                  <el-input :prefix-icon="Key" style="width:calc(100% - 90px);height: 44px;"
                    v-model="forms.formValue.code" placeholder="请输入验证码" maxlength="8"></el-input>
                  <el-button class="code-button" type="primary" :disabled="showInfo.disabled" link @click="getCode">{{
                    showInfo.btnTitle
                  }}</el-button>
                </div>
                <div class="trial-content-box-right-footer">
                  <el-button style="width:100%;height:44px;" type="primary" :loading="loading"
                    @click="onReg">注册后自动登录</el-button>
                </div>
              </el-tab-pane>

            </el-tabs>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive, ref, computed, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Iphone, OfficeBuilding, Key, User, View } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { Requests } from '@/api/index.js'
export default {
  name: 'promotionLogin',
  props: {},
  setup(props, context) {
    const apiBefore = window.location.href.indexOf('10.10.8.37') > -1 ? '' : 'https://crm.jzsoft.cn/pro'
    const $router = useRouter()
    const forms = reactive({
      formValue: {
        company: '',
        userPhone: null,
        code: '',
        userName: '',
        password: ''
      },
      read: true
    })
    const showVisible = ref(false)
    const showLoginInfo = ref({
      entno: '*****',
      password: '*****',
      user: '*****'
    })
    // 定时器
    const Times = ref(-1)
    // 按钮加载中
    const loading = ref(false)
    const totalData = reactive({
      actTab: '0',
      loginType: 'password', // password || code
      userPhone: null,
      code: '',
      password: '',
      codeTime: -1,
      loadingVal: ''
    })
    const showInfo = computed(() => {
      return Times.value > 0 ? {
        btnTitle: `${Times.value}s`,
        disabled: true
      } : {
        btnTitle: '获取验证码',
        disabled: false
      }
    })
    const loginBtnInfo = computed(() => {
      return totalData.codeTime > 0 ? {
        btnTitle: `${totalData.codeTime}s`,
        disabled: true
      } : {
        btnTitle: '获取验证码',
        disabled: false
      }
    })
    const goView = (type) => {
      $router.push({
        path: '/view',
        query: {
          type: type
        }
      })
    }

    // 注册成为推广大使
    const onReg = async () => {
      if (forms.formValue.company) {
        if (forms.formValue.userName) {
          if (forms.formValue.userPhone && /^1[123456789][0-9]\d{8}$/.test(forms.formValue.userPhone)) {
            if (forms.formValue.password) {
              if (forms.formValue.code) {
                loading.value = true
                window.axiosHeader = 'application/json'
                const params = {
                  url: apiBefore + '/api/User/AddAgent',
                  method: 'POST',
                  data: {
                  },
                  params: {
                    values: {
                      company: forms.formValue.company,
                      userName: forms.formValue.userName,
                      userPhone: forms.formValue.userPhone,
                      code: forms.formValue.code,
                      password: forms.formValue.password
                    }
                  }
                }
                // 来源于推广拉新注册
                if (window.location.href.indexOf('para') > -1) {
                  const tempUrl = window.location.href.substring(window.location.href.indexOf('?'))
                  const theRequest = {}
                  if (tempUrl.indexOf('?') != -1) {
                    const str = tempUrl.substring(tempUrl.indexOf('?') + 1)
                    const strs = str.split('&')
                    for (let i = 0; i < strs.length; i++) {
                      theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1])
                    }
                  }
                  params.data = { ...params.data, ...theRequest }
                }

                const res = await Requests(params)
                loading.value = false
                if (res.errcode === 0) {
                  onLogin({}, { userPhone: forms.formValue.userPhone, password: forms.formValue.password })
                }
              } else {
                ElMessage.error('请输入验证码')
              }
            } else {
              ElMessage.error('请输入密码')
            }
          } else {
            ElMessage.error('请输入正确手机号')
          }
        } else {
          ElMessage.error('请输入联系人名称')
        }
      } else {
        ElMessage.error('请输入完整企业名称')
      }
    }
    // 获取验证码
    const getCode = async () => {
      if (forms.formValue.userPhone && /^1[123456789][0-9]\d{8}$/.test(forms.formValue.userPhone)) {
        const params = {
          url: apiBefore + '/api/MobileSms/GetCode',
          method: 'get',
          data: {
            phone: forms.formValue.userPhone,
            // 直接用register就行，服务端直接取的这个
            codeType: 'register'
          }
        }
        const res = await Requests(params)
        if (res.errcode === 0) {
          ElMessage.success('发送成功')
          Times.value = 60
          const times = setInterval(() => {
            if (Times.value == 0) {
              clearInterval(times)
            }
            Times.value -= 1
          }, 1000)
        } else {
        }
      } else {
        ElMessage.error('请输入正确手机号')
      }
    }
    // 获取验证码
    const getLoginCode = async () => {
      if (totalData.userPhone && /^1[123456789][0-9]\d{8}$/.test(totalData.userPhone)) {
        const params = {
          url: apiBefore + '/api/MobileSms/GetCode',
          method: 'get',
          data: {
            phone: totalData.userPhone,
            // 直接用register就行，服务端直接取的这个
            codeType: 'register'
          }
        }
        const res = await Requests(params)
        if (res.errcode === 0) {
          ElMessage.success('发送成功')
          totalData.codeTime = 60
          const times = setInterval(() => {
            if (totalData.codeTime == 0) {
              clearInterval(times)
            }
            totalData.codeTime -= 1
          }, 1000)
        }
      } else {
        ElMessage.error('请输入正确手机号')
      }
    }
    // 登录
    const onLogin = async (aParams, aPost) => {
      window.axiosHeader = 'application/json'
      const params = {
        url: apiBefore + '/api/User/LoginAgent',
        method: 'POST',
        data: {
          ...aParams
        },
        params: {
          values: {
            userPhone: totalData.userPhone,
            ...aPost
          }
        }
      }
      if (!aPost) {
        if (totalData.loginType == 'code') {
          params.params.values.code = totalData.code
        } else {
          params.params.values.password = totalData.password
        }
      }
      console.log(params)
      if (params.params.values.userPhone && /^1[123456789][0-9]\d{8}$/.test(params.params.values.userPhone)) {
        totalData.loadingVal = 'login'
        const res = await Requests(params)
        if (res.errcode === 0) {
          $router.push({
            path: '/promotion',
            query: {}
          })
        } else {
          ElMessage.success(res.message || '登录失败')
        }
        totalData.loadingVal = ''
      } else {
        ElMessage.error('请输入正确手机号')
      }
    }
    // 登录页面获取验证码

    // 弹出框关闭之前
    const handleClose = () => {
      showVisible.value = false
    }
    const toggleLoginType = () => {
      totalData.loginType = totalData.loginType == 'password' ? 'code' : 'password'
    }
    return {
      forms,
      Iphone,
      OfficeBuilding,
      Key,
      User,
      goView,
      showInfo,
      loading,
      onReg,
      getCode,
      showVisible,
      showLoginInfo,
      handleClose,
      ...toRefs(totalData),
      View,
      onLogin,
      getLoginCode,
      toggleLoginType,
      loginBtnInfo
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.main {
  background-image: url('../../../public/image/bg-main.png');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 1100px;
}

.trial {
  width: 1100px;
  height: 720px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;

  ::v-deep .el-input__inner {
    text-align: left;
  }

  &-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    &-box {
      width: 100%;
      height: 100%;
      display: flex;
      overflow: hidden;
      background-color: rgba(247, 250, 255, 0.6);

      &-left {
        width: 441px;
        height: 100%;
      }

      &-right {
        width: calc(100% - 441px);
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        padding: 48px 0px 30px 0px;
        background-color: #fff;

        ::v-deep(.el-tabs__header) {
          width: 390px !important;
          margin: 20px auto !important;
        }

        ::v-deep(.el-tabs__nav) {
          width: 100% !important;
        }

        ::v-deep(.el-tabs__item) {
          width: 50% !important;
          font-size: 16px !important;
        }

        ::v-deep(.el-tabs) {
          width: 100% !important;
        }

        ::v-deep(.el-tab-pane) {
          width: 400px !important;
          margin: 0 auto;
        }

        &-title {
          font-size: 32px;
          font-weight: 400;
          width: 386px;
          margin: 0px 0px 6px 0px;
        }

        &-subtitle {
          width: 386px;
          font-size: 14px;
          color: #333333bf;
          margin: 0px 0px 16px 0px;
        }

        &-input {
          width: 386px;
          margin: 20px 0px 0 0;
          position: relative;
        }

        &-check {
          width: 386px;
          display: flex;
          align-items: center;

          &-title {
            margin-left: 8px;
            font-size: 12px;
            color: #333333bf;
          }
        }

        &-footer {
          width: 386px;
          margin-top: 32px;
        }

        .code {
          ::v-deep(.el-input__inner) {
            padding-right: 96px;
            overflow: hidden;
          }

          &-button {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 94px;
            height: 44px;
          }
        }
      }
    }
  }
}

//媒体查询
@media (max-width: 767px) {
  .trial {
    &-content {
      padding-top: 50px;

      &-box {
        width: 100%;
        margin: 0px;
        background-color: transparent;

        &-right {
          padding: 0px;

          &-check {
            width: fit-content;
          }

          &-title {
            width: 100%;
            text-align: center;
          }

          &-subtitle {
            width: 100%;
            text-align: center;
          }

          &-input {
            width: 100%;

            ::v-deep(.el-input__wrapper) {
              background-color: rgba(247, 250, 255, 0.6);
            }
          }
        }
      }
    }
  }
}

::v-deep(.footer) {
  display: none;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  line-height: 1;
  -moz-appearance: textfield !important;
}

.box {
  &-content {
    width: 100%;
  }
}
</style>
