<!--
 * @Description:导航栏
 * @Version: 1.0
 * @Autor: 郭灿
 * @Date: 2022-12-05 11:25:10
 * @LastEditors: 郭灿
 * @LastEditTime: 2023-06-17 09:25:32
-->
<template>
  <div v-if="isShow" id="menu-parent">
    <div class="container-fluid nav jz-soft-nav" :class="{ isScroll: isScroll || isHover }" @mouseleave="changeHover('out')" @mouseover="changeHover('in')">
      <div class="container nav-info">
        <JzIcon class="nav-info-logo jz-hv hidden-xs-only" :icon="logo" :iStyle="{ 'color': isScroll ? '' : '#ffffff' }" style="width:155px;height:36px" @click="handLogo">
        </JzIcon>
        <JzIcon class="nav-info-logo jz-hv hidden-sm-and-up" icon="icon-logo-color" :iStyle="{ 'color': isScroll ? '' : '#ffffff' }" style="width:155px;height:36px" @click="handLogo">
        </JzIcon>
        <div style="" class="otherLogo" @click="goOther(isUrl)" v-if="isUrl">
          <el-image src="/image/other/wechatlogo.png"></el-image>
        </div>
        <div class="nav-info-nav hidden-xs-only">
          <div v-for="(item, index) in navData" :key="item.path">
            <div class="jz-hv nav-info-nav-item" :class="{ 'navActive': navActive == index }" v-if="!Array.isArray(item.children)" @click="menuClick(item)">
              {{ item.title }}
            </div>
            <el-popover popper-class="poperClass" placement="bottom" width="99.5vw" trigger="hover" v-else>
              <template #reference>
                <div class="jz-hv nav-info-nav-item" :class="{ 'navActive': navActive == index }">
                  <span class="jz-hv">{{ item.title }}</span>
                </div>
              </template>
              <div class="container-fluid" style="margin-top:-20px" @mouseleave="changeHover('out')" @mouseover="changeHover('in')">
                <div class="container popoverContent">
                  <div class="popoverContent-item jz-hv" v-for="it in item.children" :key="it.title">
                    <JzLabel :itemSet="it"></JzLabel>
                  </div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
        <div class="nav-info-right">
          <el-space class="hidden-xs-only">
            <JzButton class="hidden-xs-only" :itemSet="{ 'title': '免费试用' }" style="font-size:16px;" :btnProps="{ 'type': 'primary', 'link': true }" :onCallBack="{ 'handClick': freeTrial }" :style="{ 'color': (!isScroll && !isHover) ? '#ffffff' : '' }"></JzButton>
            <JzButton class="hidden-xs-only" :itemSet="{ 'title': '登录' }" style="font-size:16px;" :btnProps="{ 'type': 'primary', 'link': true }" :onCallBack="{ 'handClick': login }" :style="{ 'color': (!isScroll && !isHover) ? '#ffffff' : '' }"></JzButton>
          </el-space>
          <el-space class="hidden-sm-and-up">
            <JzIcon icon="icon-mian" :iStyle="{ 'font-size': '25px' }" @click="freeTrial"></JzIcon>
            <!-- <JzButton class="hidden-xs-only" :loginUrl="login" style="height:68px;border-radius: 0px;" :itemSet="{ 'title': '登录' }" :btnProps="{ 'type': 'primary' }" :style="{ 'background-color': (!isScroll && !isHover) ? 'transparent' : '', 'border': (!isScroll && !isHover) ? 'none' : '' }">
            </JzButton> -->
            <!-- <a class="navGoOut hidden-xs-only" href="https://crm.jzsoft.cn/pro" target="_blank"  :style="{ 'background-color': (!isScroll && !isHover) ? 'transparent' : '', 'border': (!isScroll && !isHover) ? 'none' : '' }">登录</a> -->
            <!-- <JzIcon style="display: none;" icon="icon-yonghu" :iStyle="{ 'font-size': '25px' }" @click="login"></JzIcon> -->
          </el-space>
        </div>
      </div>
    </div>
    <JzShowModel :navActive="navActive"></JzShowModel>
    <div class="mobileMenu hidden-sm-and-up">
      <div class="mobileMenu-box" :class="{ 'navActive': navActive == index }" v-for="(item, index) in navData" :key="item.path" @click="menuClick(item)">
        <div class="mobileMenu-box-icon">
          <JzIcon :icon="item.icon" :iStyle="{ 'font-size': '26px' }">
          </JzIcon>
        </div>
        <span class="mobileMenu-box-title">{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import JzShowModel from '@/components/JzShowModel/JzShowModel.vue'
import JzButton from '@/components/JzButton/JzButton.vue'
import { computed, onMounted, ref, watch } from 'vue'
import JzIcon from '@/components/JzIcon/JzIcon.vue'
import JzLabel from '@/components/JzLabel/JzLabel.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: { JzIcon, JzLabel, JzButton, JzShowModel },
  name: 'JzNav',
  props: {},
  setup(props, context) {
    const hindArr = ref(['View'])
    const $store = useStore()
    const $router = useRouter()
    const navActive = ref(0)
    // 判断是不是滚动
    const isScroll = ref(false)
    // 显示滚动导航样式 的index
    const isShowScroll = ref([0, 4])
    // 是否鼠标移入
    const isHover = ref(false)
    // 左侧logo
    const logo = computed(() => {
      //  需要判断有没有颜色
      if (isScroll.value || isHover.value) {
        return $store.state.logoIcon.colorIcon
      } else {
        return $store.state.logoIcon.icon
      }
    })
    // 导航数据
    const navData = computed(() => {
      return $store.state.nav
    })
    // 监听是否滚动
    const handleScrollx = () => {
      if (window.pageYOffset == 0 && isShowScroll.value.includes(navActive.value)) {
        isScroll.value = false
      } else {
        isScroll.value = true
      }
    }
    const isShow = computed(() => {
      return !hindArr.value.includes($router.currentRoute.value.name)
    })
    const changeHover = (type) => {
      if (type == 'in') {
        isHover.value = true
      } else {
        isHover.value = false
      }
    }

    // 登录
    const login = () => {
      window.open('https://crm.jzsoft.cn/pro/user/login?pageFrom=website');
      // return $store.state.loginUrl
    }
    //
    const isUrl = computed(() => {
      let str = '';
      if ($router.currentRoute.value.name == 'Other') {
        let type = $router.currentRoute.value.query.type || ''
        if (type) {
          str = $store.state.appConfig.openOther[type]
        }
      }
      return str;
    })
    const goOther = (e) => {
      window.open(e);
    }
    onMounted(() => {
      window.addEventListener('scroll', handleScrollx, true)
    })
    watch(() => $router.currentRoute.value, (newVal, oldVal) => {
      navActive.value = -1
      $store.state.nav.filter((val, index) => {
        if (val.path == newVal.path) {
          navActive.value = index
          if (!isShowScroll.value.includes(index)) {
            isScroll.value = true
          } else {
            isScroll.value = false
          }
        }
      })
      if (navActive.value == -1) {
        isScroll.value = true
      }
    }, { immediate: true })
    watch
    return {
      logo,
      isScroll,
      navData,
      navActive,
      isShow,
      isHover,
      changeHover,
      login,
      isUrl,
      goOther,
    }
  },
  methods: {
    // 免费试用
    freeTrial() {
      this.$router.push({
        path: '/trial'
      })
    },
    // 菜单点击
    menuClick(item) {
      this.$router.replace({ path: item.path })
    },
    handLogo() {
      this.$router.replace({
        path: '/'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.nav {
  width: 100%;
  height: 68px;
  position: fixed;
  z-index: 999;
  top: 0px;
  border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);

  &-info {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    // 导航
    &-nav {
      display: flex;
      // margin-left: 50px;
      color: #ffffff;

      &-item {
        padding: 8px 16px;
        margin: 0px 18px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
      }

      // 鼠标经过样式
      &-item:hover {
        color: var(--el-color-primary);
      }

      &-item:hover::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--el-color-primary);
        left: 0px;
        bottom: -14px;
      }

      .navActive::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #ffffff;
        left: 0px;
        bottom: -14px;
      }
    }

    // 右侧按钮
    &-right {
      position: absolute;
      right: 12px;
    }
    // 其他供应商logo
    .otherLogo {
      width: 120px;
      margin-top: 6px;
      cursor: pointer;
    }
  }
}

.navActive {
  color: #ffffff;
}

.isScroll {
  .navActive {
    color: var(--el-color-primary);
  }

  .navActive::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--el-color-primary);
    left: 0px;
    bottom: -14px;
  }
}

.poperClass {
  margin-left: 1vw !important;
}

// 产品弹出框
.popoverContent {
  padding: 36px 12% 16px 48px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-item {
    width: 269px;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 6px;
  }

  &-item:hover {
    background: rgba(247, 250, 255, 1);
  }
}

.isScroll {
  background-color: #ffffff;
  box-shadow: rgba(95, 123, 178, 0.3) 0px 6px 6px 0px;

  .nav-info-nav {
    color: #1f2228fe;
  }
}

// 手机导航
.mobileMenu {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.08);

  &-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;

    &-icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-size: 12px;
      margin-top: 8px;
      // color: #000000;
    }
  }
}

// 媒体查询
@media (max-width: 767px) {
  .navActive {
    color: var(--el-color-primary);
  }

  .navActive::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--el-color-primary);
    left: 0px;
    bottom: -14px;
  }

  .nav {
    height: 44px;
    background-color: #ffffff;

    &-info {
      &-logo {
        width: 100px !important;
        height: 28px !important;
      }
      .otherLogo {
        margin-top: 6px;
        margin-left: 12px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .popoverContent {
    padding: 8px 12px;

    &-item {
      width: 220px;
    }
  }

  .nav-info-nav-item {
    padding: 8px 0px;
  }
}
@media (min-width: 0px) and (max-width: 993px) {
  .navGoOut {
    display: none;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .popoverContent {
    padding: 16px 48px;
  }
}

@media (min-width: 1201px) {
}
.navGoOut {
  width: 60px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #035dff;
  // background-color: #035dff;
  text-decoration: none;
}
.navGoOut:hover {
  // color: #035dff;
  // background: #035dff55;
}
</style>
